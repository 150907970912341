import {
    getAuth,
    GithubAuthProvider,
    GoogleAuthProvider,
    linkWithPopup,
    signInWithPopup,
    TwitterAuthProvider,
    unlink,
    browserLocalPersistence,
    setPersistence,
} from "firebase/auth";
import toast from "react-hot-toast";

import app from "./firebase";
import { apiUrl } from "../components/Util";

const auth = getAuth(app);

const googleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    // Add specific config for Workspace accounts
    provider.setCustomParameters({
        // Request offline access for refresh tokens
        access_type: "offline",
        // Force consent screen to ensure refresh token is returned
        prompt: "consent",
    });
    try {
        // Set persistence to LOCAL before sign-in
        await setPersistence(auth, browserLocalPersistence);
        const result = await signInWithPopup(auth, provider);
    } catch (exception) {
        toast.error("Sign in error: " + exception);
    }
};

const linkWithTwitter = () => {
    const provider = new TwitterAuthProvider();
    const user = auth.currentUser;
    if (user) {
        linkWithPopup(user, provider)
            .then((result) => {
                const credential = TwitterAuthProvider.credentialFromResult(result);
                const token = credential?.accessToken;
                const secret = credential?.secret;
                const body = {
                    ACCESS_TOKEN: token,
                    ACCESS_SECRET: secret,
                };

                fetch(`${apiUrl}/secret`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        secret_type: "twitter_api_key",
                        user_id: result.user.uid,
                        value: JSON.stringify(body),
                    }),
                });
            })
            .catch((error) => {
                if (error.code === "auth/credential-already-in-use") {
                    toast.error("Sorry, this account is in use.");
                } else {
                    toast.error("Something went wrong.");
                }
                console.error("Link with Twitter failed, error: ", error.message);
            });
    } else {
        console.log("No current user");
    }
};

const linkWithGithub = async () => {
    const provider = new GithubAuthProvider();
    provider.addScope("repo");
    const user = auth.currentUser;
    if (user) {
        await linkWithPopup(user, provider)
            .then((result) => {
                const credential = GithubAuthProvider.credentialFromResult(result);
                const token = credential?.accessToken;

                console.log(credential);

                fetch(`${apiUrl}/secret`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        secret_type: "github_access_token",
                        user_id: result.user.uid,
                        value: token,
                    }),
                });
            })
            .catch((error) => {
                if (error.code === "auth/credential-already-in-use") {
                    toast.error("Sorry, this account is in use.");
                } else {
                    toast.error("Something went wrong.");
                }
                console.error("Link with GitHub failed, error: ", error.message);
            });
    } else {
        console.log("No current user");
    }
};

const unlinkProvider = (providerId) => {
    const user = auth.currentUser;
    if (user && providerId) {
        unlink(user, providerId).catch((error) => {
            console.error(`Unlinking with ${providerId} failed, error: `, error.message);
        });
    } else {
        console.log("No current user");
    }
};

export { googleSignIn, linkWithTwitter, linkWithGithub, unlinkProvider };
