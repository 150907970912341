import React from "react";

import { DialogContent, DialogContentText, DialogActions, Button, TextField } from "@mui/material";

interface ObjectiveInputProps {
    objective: string;
    setObjective: (objective: string) => void;
    onNext: () => void;
    onBack: () => void;
}

const ObjectiveInput: React.FC<ObjectiveInputProps> = ({
    objective,
    setObjective,
    onNext,
    onBack,
}) => {
    return (
        <>
            <DialogContent>
                <DialogContentText>Describe in a few words your sales objective</DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="objective"
                    label="Your objective"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={objective}
                    onChange={(e) => setObjective(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onBack}>Back</Button>
                <Button
                    onClick={onNext}
                    variant="contained"
                    color="primary"
                    disabled={!objective.trim()}>
                    Next
                </Button>
            </DialogActions>
        </>
    );
};

export default ObjectiveInput;
