import React from "react";

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from "@mui/material";

interface AuthDialogProps {
    open: boolean;
    onClose: () => void;
    handleSignIn: () => Promise<void>;
}

const AuthDialog: React.FC<AuthDialogProps> = ({ open, onClose, handleSignIn }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="auth-dialog-title"
            aria-describedby="auth-dialog-description">
            <DialogTitle id="auth-dialog-title">{"Authentication Required"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="auth-dialog-description">
                    No signup is necessary, we just need to make sure nobody else has access to your
                    data. Please sign in with your Google account.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSignIn} color="primary" variant="contained">
                    Sign in with Google
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AuthDialog;
