import React, { useRef, useEffect, useState, useImperativeHandle } from "react";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SavingsIcon from "@mui/icons-material/Savings";
import SendIcon from "@mui/icons-material/Send";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Button, TextField, Typography, Menu, MenuItem, IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import ReactJson, { InteractionProps } from "@microlink/react-json-view";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";

import { FnRun } from "../types/FnRun";
import { UserProfile } from "../types/UserProfile";
import { fetch_with_auth } from "./Util";
import InsufficientCreditDialog from "./m10n/InsufficientCreditDialog";
import { ChatMessage } from "../types/ChatMessage";

interface SpaceChatProps {
    userProfile: UserProfile;
    selectedSpaceId: string;
    selectedRows: any[];
    selectedTable: string;
    onSpaceDataChanged?: () => void;
}

// Method signature for referencing from the parent:
export interface SpaceChatRef {
    startFnRunsForRows?: (
        spaceId: string,
        tableName: string,
        fnId: string,
        fnName: string,
        rows: any[],
    ) => void;
}

const SpaceChat = React.forwardRef<SpaceChatRef, SpaceChatProps>((props, ref) => {
    // Set of run ids for which we are actively polling backend.
    const [activePolling, setActivePolling] = useState<Set<string>>(new Set());

    const [showInsufficientCreditsDialog, setShowInsufficientCreditsDialog] =
        useState<boolean>(false);

    const [showScrollButton, setShowScrollButton] = useState<boolean>(false);
    const chatContainerRef = useRef<HTMLDivElement>(null);

    // This ref tracks whether we need to scroll to the bottom after the next render
    const shouldScrollToBottomRef = useRef<boolean>(true);

    const { selectedSpaceId, userProfile, selectedRows, selectedTable, onSpaceDataChanged } = props;

    // State for message menu
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedMessageId, setSelectedMessageId] = useState<string | null>(null);
    const openMenu = Boolean(menuAnchorEl);

    // Track which messages have debug mode enabled
    const [debugEnabledMessages, setDebugEnabledMessages] = useState<Set<string>>(new Set());

    // Cache of messages by space ID
    const [messagesCache, setMessagesCache] = useState<Record<string, ChatMessage[]>>({});

    const [input, setInput] = useState<string>("");
    const [messages, setMessages] = useState<ChatMessage[]>([]);
    const [isLoadingMessages, setIsLoadingMessages] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);

    // This effect handles scrolling to the bottom whenever messages change
    // and the shouldScrollToBottomRef is true
    useEffect(() => {
        if (shouldScrollToBottomRef.current && chatContainerRef.current) {
            // Use requestAnimationFrame to ensure DOM has been updated
            requestAnimationFrame(() => {
                if (chatContainerRef.current) {
                    chatContainerRef.current.scrollTo({
                        top: chatContainerRef.current.scrollHeight,
                        behavior: "auto",
                    });
                }
            });
        }
    }, [messages]);

    useEffect(() => {
        const container = chatContainerRef.current;
        if (!container) return;
        const handleScroll = () => {
            // If not scrolled near the bottom (50px margin), show the button.
            if (container.scrollHeight - container.scrollTop - container.clientHeight > 50) {
                setShowScrollButton(true);
            } else {
                setShowScrollButton(false);
            }
        };
        container.addEventListener("scroll", handleScroll);
        // Run an initial check.
        handleScroll();
        return () => container.removeEventListener("scroll", handleScroll);
    }, [messages]);

    // Load messages when selected space changes
    useEffect(() => {
        if (!selectedSpaceId || !userProfile.id_token) return;

        // Set the flag to scroll to bottom after messages are loaded
        shouldScrollToBottomRef.current = true;

        // First set current messages from cache if available for instant display
        if (messagesCache[selectedSpaceId]) {
            setMessages(messagesCache[selectedSpaceId]);

            // Check for running functions that might have completed
            checkRunningFunctions(messagesCache[selectedSpaceId]);
        } else {
            setMessages([]);
        }

        // Then fetch from backend to ensure we have the latest
        loadMessagesForSpace(selectedSpaceId);
    }, [selectedSpaceId, userProfile.id_token]);

    const handleDeleteMessage = async () => {
        handleMenuClose();
        if (!selectedMessageId) return;

        try {
            await fetch_with_auth(
                `chat_message?id=${selectedMessageId}`,
                userProfile.id_token || "",
                "DELETE",
            );

            // Remove message from state
            setMessages((prev) => prev.filter((msg) => msg.id !== selectedMessageId));

            // Remove from cache too
            setMessagesCache((prev) => {
                const spaceMessages = [...(prev[selectedSpaceId] || [])];
                const filteredMessages = spaceMessages.filter(
                    (msg) => msg.id !== selectedMessageId,
                );
                return {
                    ...prev,
                    [selectedSpaceId]: filteredMessages,
                };
            });
        } catch (error: any) {
            toast.error(`Error deleting message: ${error.message}`);
        }
    };

    const checkRunningFunctions = (messageList: ChatMessage[]) => {
        const runningMessages = messageList.filter(
            (msg) => msg.state === "fn_running" && msg.run_id,
        );

        if (runningMessages.length === 0) return;

        const now = new Date();

        runningMessages.forEach((message) => {
            if (!message.run_id) return;

            // Check if function has been running for more than 5 minutes
            const messageDate = message.created_ts ? new Date(message.created_ts) : null;
            const isLongRunning =
                messageDate && now.getTime() - messageDate.getTime() > 5 * 60 * 1000; // 5 minutes in ms

            // First, check the current status of the function run
            FnRun.loadRun(message.run_id)
                .then((fnRun) => {
                    if (!fnRun.isRunning()) {
                        // Function is complete - update the message
                        const chatResponse = fnRun.run_outputs?.chat_response || "";

                        // Create updated message with function results
                        const updatedMsg = new ChatMessage({
                            ...message,
                            state: "fn_done",
                            text: chatResponse, // Store chat response in text
                            credit_cost: fnRun.credit_cost || 0,
                        });

                        // Update UI
                        setMessages((prev) =>
                            prev.map((msg) => (msg.id === message.id ? updatedMsg : msg)),
                        );

                        // Save to backend
                        saveMessageToBackend(updatedMsg);

                        // Also update cache
                        setMessagesCache((prev) => {
                            const spaceMessages = [...(prev[message.space_id] || [])];
                            const existingIndex = spaceMessages.findIndex(
                                (msg) => msg.id === message.id,
                            );

                            if (existingIndex >= 0) {
                                spaceMessages[existingIndex] = updatedMsg;
                            }

                            return {
                                ...prev,
                                [message.space_id]: spaceMessages,
                            };
                        });
                    } else {
                        // Function is still running

                        // Add warning for long-running functions if needed
                        if (
                            isLongRunning &&
                            message.text.indexOf("Function takes longer than expected") === -1
                        ) {
                            const warningMsg = new ChatMessage({
                                ...message,
                                text: `Function takes longer than expected to run, reach out for support in our Slack (link: https://join.slack.com/t/speshtalk/shared_invite/zt-30xumvo5w-Dn0_FJYijQzyur9gcSf0RA)`,
                            });

                            // Update UI
                            setMessages((prev) =>
                                prev.map((msg) => (msg.id === message.id ? warningMsg : msg)),
                            );

                            // Save to backend
                            saveMessageToBackend(warningMsg);
                        }

                        // Start polling for this function if it's still running
                        pollFnRunStatus(message.run_id || "", message.id);
                    }
                })
                .catch((error) => {
                    console.error(`Error checking function status: ${error}`);
                });
        });
    };

    const loadMessagesForSpace = async (spaceId: string) => {
        if (isLoadingMessages) return;

        setIsLoadingMessages(true);
        // Set the flag to scroll to bottom after messages are loaded
        shouldScrollToBottomRef.current = true;

        try {
            const loadedMessages = await ChatMessage.loadMessagesForSpace(
                spaceId,
                userProfile.id_token || "",
            );

            // Process messages to ensure text content is properly set
            const processedMessages = loadedMessages.map((msg) => {
                // If message is a completed function run but has no text content,
                // add a placeholder text to make it more user-friendly
                if (msg.state === "fn_done" && !msg.text && msg.run_id) {
                    return new ChatMessage({
                        ...msg,
                        text: "Function completed. Load debug info to see details.",
                    });
                }
                return msg;
            });

            // Update both current messages and cache
            setMessages(processedMessages);
            setMessagesCache((prev) => ({
                ...prev,
                [spaceId]: processedMessages,
            }));

            // Check for running functions that might have completed
            checkRunningFunctions(processedMessages);
        } catch (error: any) {
            toast.error(`Error loading messages: ${error.message}`);
        } finally {
            setIsLoadingMessages(false);
        }
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, messageId: string) => {
        event.stopPropagation();
        setMenuAnchorEl(event.currentTarget);
        setSelectedMessageId(messageId);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const toggleDebugMode = async () => {
        handleMenuClose();
        if (!selectedMessageId) return;

        // Check if debug is already enabled for this message
        const isDebugEnabled = debugEnabledMessages.has(selectedMessageId);

        if (isDebugEnabled) {
            // Remove from debug enabled set
            const newDebugEnabled = new Set(debugEnabledMessages);
            newDebugEnabled.delete(selectedMessageId);
            setDebugEnabledMessages(newDebugEnabled);
        } else {
            // Add to debug enabled set and load FnRun if needed
            await loadFnRunForMessage(selectedMessageId);
            const newDebugEnabled = new Set(debugEnabledMessages);
            newDebugEnabled.add(selectedMessageId);
            setDebugEnabledMessages(newDebugEnabled);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInput(e.target.value);
    };

    const createNewFnRunMessage = (
        fnRun: FnRun,
        fnName?: string,
        messageId?: string,
    ): ChatMessage => {
        const newMessage = new ChatMessage({
            id: messageId || uuidv4(),
            author: "spesh",
            text: "",
            state: "fn_running",
            fn_name: fnName,
            run_id: fnRun.run_id,
            space_id: selectedSpaceId,
            user_id: userProfile.user_id,
            credit_cost: fnRun.credit_cost || 0,
        });

        // Store FnRun object in non-persisted property
        newMessage._fnRun = fnRun;

        // Asynchronously save to backend
        saveMessageToBackend(newMessage);

        return newMessage;
    };

    const saveMessageToBackend = async (message: ChatMessage) => {
        try {
            // Ensure saving completes by awaiting the promise
            await message.save(userProfile.id_token || "").catch((error: any) => {
                console.error("Error saving message:", error);
            });

            // Update the cache
            setMessagesCache((prev) => {
                const spaceMessages = [...(prev[message.space_id] || [])];
                const existingIndex = spaceMessages.findIndex((msg) => msg.id === message.id);

                if (existingIndex >= 0) {
                    // Update existing message
                    spaceMessages[existingIndex] = message;
                } else {
                    // Add new message
                    spaceMessages.push(message);
                }

                return {
                    ...prev,
                    [message.space_id]: spaceMessages,
                };
            });
        } catch (error: any) {
            console.error("Error preparing message save:", error);
        }
    };

    const handleSpaceChatSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!input.trim()) return;

        // Set flag to scroll to bottom after messages are updated
        shouldScrollToBottomRef.current = true;

        // Append the user message with a unique id.
        const userMsgId = uuidv4();
        const userMessage = new ChatMessage({
            id: userMsgId,
            author: "user",
            text: input,
            state: "simple_text",
            space_id: selectedSpaceId,
            user_id: userProfile.user_id,
        });

        setMessages((prev) => [...prev, userMessage]);

        // Save the user message to backend and wait for it to complete
        await saveMessageToBackend(userMessage);

        // Save the submitted input in a temporary variable.
        const submittedInput = input;

        // Clear the input field immediately.
        setInput("");

        // Generate a unique id for the placeholder bot message.
        const botMsgId = uuidv4();
        const placeholderMessage = new ChatMessage({
            id: botMsgId,
            author: "spesh",
            text: "",
            state: "space_chat_running",
            space_id: selectedSpaceId,
            user_id: userProfile.user_id,
        });

        setMessages((prev) => [...prev, placeholderMessage]);

        // Save placeholder message to backend and wait for it to complete
        await saveMessageToBackend(placeholderMessage);

        try {
            const data = await fetch_with_auth("space_chat", userProfile.id_token || "", "POST", {
                space_id: selectedSpaceId,
                user_input: submittedInput,
                selected_rows: selectedRows,
                current_table: selectedTable,
            });

            const updatedMessage = new ChatMessage({
                ...placeholderMessage,
                flow: data,
                state: "space_chat_done",
            });

            // Update the message in UI
            setMessages((prev) => prev.map((msg) => (msg.id === botMsgId ? updatedMessage : msg)));

            // Update the message in the backend
            await saveMessageToBackend(updatedMessage);

            // Auto-run the flow
            runFlowForMessage([...messages, updatedMessage], botMsgId);

            onSpaceDataChanged?.();
        } catch (error: any) {
            toast.error(`Error: ${error.message}`);

            // Update the placeholder message to show the error
            const errorMessage = new ChatMessage({
                ...placeholderMessage,
                text: `Error: ${error.message}`,
                state: "simple_text",
            });

            setMessages((prev) => prev.map((msg) => (msg.id === botMsgId ? errorMessage : msg)));

            // Save error message to backend
            await saveMessageToBackend(errorMessage);
        }
    };

    const loadFnRunForMessage = async (messageId: string) => {
        const message = messages.find((msg) => msg.id === messageId);
        if (!message || !message.run_id) return;

        // If already loaded, no need to reload
        if (message._fnRun) {
            return;
        }

        try {
            const fnRun = await FnRun.loadRun(message.run_id);

            // Update the message with the loaded FnRun
            setMessages((prev) =>
                prev.map((msg) => {
                    if (msg.id === messageId) {
                        return new ChatMessage({
                            ...msg,
                            _fnRun: fnRun,
                        });
                    }
                    return msg;
                }),
            );

            // Update cache too
            setMessagesCache((prev) => {
                const spaceMessages = [...(prev[selectedSpaceId] || [])];
                const msgIndex = spaceMessages.findIndex((m) => m.id === messageId);

                if (msgIndex >= 0) {
                    spaceMessages[msgIndex] = new ChatMessage({
                        ...spaceMessages[msgIndex],
                        _fnRun: fnRun,
                    });
                }

                return {
                    ...prev,
                    [selectedSpaceId]: spaceMessages,
                };
            });

            // If the function is done and has a chat response but the message has no text,
            // update the message text with the chat response
            if (fnRun.state === "DONE" && fnRun.run_outputs?.chat_response && !message.text) {
                const updatedMsg = new ChatMessage({
                    ...message,
                    text: fnRun.run_outputs.chat_response,
                });

                setMessages((prev) => prev.map((msg) => (msg.id === messageId ? updatedMsg : msg)));

                saveMessageToBackend(updatedMsg);
            }
        } catch (error: any) {
            toast.error(`Error loading function details: ${error.message}`);
        }
    };

    const updateFnRun = (messageId: string, fnRun: FnRun) => {
        setMessages((prevMessages) =>
            prevMessages.map((msg) => {
                if (msg.id === messageId) {
                    // Create a new ChatMessage instance with updated properties
                    const updatedMsg = new ChatMessage({
                        ...msg,
                        _fnRun: fnRun,
                        state: fnRun.isRunning() ? "fn_running" : "fn_done",
                        credit_cost: fnRun.credit_cost || 0,
                        // If the function has a chat response and is done, ALWAYS update the text
                        ...(fnRun.run_outputs?.chat_response && !fnRun.isRunning()
                            ? { text: fnRun.run_outputs.chat_response }
                            : {}),
                    });

                    saveMessageToBackend(updatedMsg);
                    return updatedMsg;
                }
                return msg;
            }),
        );
    };

    const runFlowForMessage = async (allMessages: ChatMessage[], messageId: string) => {
        try {
            const message = allMessages.find((msg) => msg.id === messageId);
            if (!message || !message.flow) return;

            const response = await fetch_with_auth(
                "space_start_flow",
                userProfile.id_token || "",
                "POST",
                {
                    space_id: selectedSpaceId,
                    flow: JSON.stringify(message.flow),
                },
            );

            // Check for insufficient credits
            if (response.insufficient_credit) {
                setShowInsufficientCreditsDialog(true);
                return;
            }

            const fnRunInstance = new FnRun(response.fn_run);

            // Create a unified message replacing the placeholder using the same id.
            const updatedMessage = createNewFnRunMessage(fnRunInstance, undefined, messageId);

            // Update both local state and backend
            setMessages((prev) => prev.map((msg) => (msg.id === messageId ? updatedMessage : msg)));

            toast.success("Execution started");
            pollFnRunStatus(fnRunInstance.run_id, messageId);
        } catch (error: any) {
            toast.error(`Error executing flow: ${error.message}`);
        }
    };

    const handleExecuteFlow = (messageId: string) => {
        runFlowForMessage(messages, messageId);
    };

    const pollFnRunStatus = (runId: string, messageId: string) => {
        // Check if already polling for this run
        if (activePolling.has(runId)) {
            return;
        }

        // Mark as polling
        setActivePolling((prev) => {
            const newSet = new Set(prev);
            newSet.add(runId);
            return newSet;
        });

        let interval: ReturnType<typeof setInterval> | null = null;
        const checkStatus = async () => {
            try {
                const newFnRun = await FnRun.loadRun(runId);
                if (newFnRun) {
                    updateFnRun(messageId, newFnRun);
                    if (!newFnRun.isRunning()) {
                        clearInterval(interval as ReturnType<typeof setInterval>);
                        // Remove from active polling
                        setActivePolling((prev) => {
                            const newSet = new Set(prev);
                            newSet.delete(runId);
                            return newSet;
                        });
                        toast.success(`Function run ${newFnRun.state}.`);

                        // ALWAYS get chat response if available
                        const chatResponse = newFnRun.run_outputs?.chat_response || "";

                        setMessages((prev) =>
                            prev.map((msg) => {
                                if (msg.id === messageId) {
                                    // Create a new ChatMessage instance
                                    const updatedMsg = new ChatMessage({
                                        ...msg,
                                        state: "fn_done",
                                        _fnRun: newFnRun,
                                        credit_cost: newFnRun.credit_cost || 0,
                                        text: chatResponse, // ALWAYS store chat response in text field
                                    });
                                    saveMessageToBackend(updatedMsg);

                                    return updatedMsg;
                                }
                                return msg;
                            }),
                        );
                        onSpaceDataChanged?.();
                    }
                }
            } catch (error: any) {
                clearInterval(interval as ReturnType<typeof setInterval>);
                // Remove from active polling on error
                setActivePolling((prev) => {
                    const newSet = new Set(prev);
                    newSet.delete(runId);
                    return newSet;
                });
                toast.error(`Error checking Function Run status: ${error.message}`);
            }
        };
        interval = setInterval(checkStatus, 3000);
    };

    const handleKillFnRun = async (runId: string) => {
        try {
            await fetch_with_auth("try_kill_fn_run", userProfile.id_token || "", "POST", {
                run_id: runId,
            });

            toast.success("Function Run queued for termination.");
        } catch (error: any) {
            toast.error(`Error killing Function Run: ${error.message}`);
        }
    };

    const handleJsonEdit = (messageId: string, edit: any) => {
        setMessages((prev) =>
            prev.map((msg) => {
                if (msg.id === messageId) {
                    const updatedMsg = new ChatMessage({
                        ...msg,
                        flow: edit.updated_src,
                    });
                    saveMessageToBackend(updatedMsg);
                    return updatedMsg;
                }
                return msg;
            }),
        );
    };

    const renderFnDebugBox = (message: ChatMessage): JSX.Element => {
        const fnRun = message._fnRun;
        if (!fnRun) return <Box>Loading function details...</Box>;

        const d = {
            state: fnRun.state,
            run_outputs: fnRun.run_outputs,
            credit_cost: fnRun.credit_cost,
            log: fnRun.log,
        };

        return (
            <Box>
                <ReactJson
                    src={d}
                    enableClipboard={false}
                    theme="bright:inverted"
                    style={{ backgroundColor: "antiquewhite" }}
                />
                <Button
                    variant="contained"
                    color="success"
                    href={`/function_editor?run_id=${message.run_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ mt: 1, ml: 1 }}>
                    Open Function Run Details
                </Button>
                {fnRun.isRunning() && fnRun.state !== "TERMINATING" && (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleKillFnRun(fnRun.run_id)}
                        sx={{ mt: 1, ml: 1 }}>
                        Kill Function Execution
                    </Button>
                )}
            </Box>
        );
    };

    const FnRunMessage = ({ message }: { message: ChatMessage }) => {
        const fnRun = message._fnRun;

        // Check if there's a chat response to display
        const hasChatResponse = fnRun?.run_outputs?.chat_response?.trim() !== "";

        // Also check if the message text already has content (from backend)
        const hasTextContent = message.text && message.text.trim() !== "";

        // Credit cost display - extracted as it's used in both display cases
        const CreditCostDisplay = () => {
            if (message.credit_cost > 0) {
                return (
                    <Box display="flex" alignItems="center" mt={1}>
                        <Tooltip
                            title={`Function run took ${Math.ceil(message.credit_cost)} credits`}>
                            <Box display="flex" alignItems="center">
                                <SavingsIcon fontSize="small" />
                                <Typography variant="body2" ml={0.5}>
                                    {Math.ceil(message.credit_cost)}
                                </Typography>
                            </Box>
                        </Tooltip>
                    </Box>
                );
            }
            return null;
        };

        return (
            <Box>
                {hasChatResponse || hasTextContent ? (
                    // Display chat response or message text
                    <Typography variant="body2">
                        {message.text || (hasChatResponse ? fnRun?.run_outputs?.chat_response : "")}
                        <CreditCostDisplay />
                    </Typography>
                ) : (
                    // Display function run status when no chat response or text
                    <Typography variant="body2">
                        Function run{" "}
                        <a
                            href={`/function_editor?run_id=${message.run_id}`}
                            target="_blank"
                            rel="noopener noreferrer">
                            {message.run_id}
                        </a>{" "}
                        completed with status: {fnRun?.state || "unknown"}.
                        <CreditCostDisplay />
                    </Typography>
                )}
            </Box>
        );
    };

    const renderChatMessage = (message: ChatMessage): JSX.Element => {
        const hasDebugInfo = debugEnabledMessages.has(message.id);

        if (message.author === "user") {
            return <Typography variant="body1">{message.text}</Typography>;
        }
        // message.author === 'spesh' from now on
        if (message.state === "space_chat_running") {
            return (
                <>
                    <CircularProgress size={20} sx={{ ml: 1 }} />
                    <Typography variant="body2">Working</Typography>
                </>
            );
        } else if (message.state === "space_chat_done") {
            // /space_chat returned and we are waiting on user approval or auto-execution
            if (hasDebugInfo) {
                return (
                    <Box>
                        <ReactJson
                            enableClipboard={false}
                            src={message.flow || {}}
                            onEdit={(edit: InteractionProps) => handleJsonEdit(message.id, edit)}
                            onAdd={(edit: InteractionProps) => handleJsonEdit(message.id, edit)}
                            onDelete={(edit: InteractionProps) => handleJsonEdit(message.id, edit)}
                            theme="bright:inverted"
                            style={{ backgroundColor: "antiquewhite" }}
                        />
                        <Button variant="contained" onClick={() => handleExecuteFlow(message.id)}>
                            Execute Flow
                        </Button>
                    </Box>
                );
            } else {
                return (
                    <>
                        <Typography variant="body2">...</Typography>
                    </>
                );
            }
        } else if (message.state === "fn_running") {
            const main_message = (
                <>
                    <CircularProgress size={20} sx={{ ml: 1 }} />
                    <Typography variant="body2">
                        Function {message.fn_name || ""} running{" "}
                        <a
                            href={`/function_editor?run_id=${message.run_id}`}
                            target="_blank"
                            rel="noopener noreferrer">
                            {message.run_id}
                        </a>
                        {message.text &&
                            message.text.indexOf("Function takes longer than expected") !== -1 && (
                                <Box mt={1}>{message.text}</Box>
                            )}
                    </Typography>
                </>
            );

            if (hasDebugInfo) {
                return (
                    <>
                        {main_message}
                        {renderFnDebugBox(message)}
                    </>
                );
            } else {
                return main_message;
            }
        } else if (message.state === "fn_done") {
            const main_message = <FnRunMessage message={message} />;
            if (hasDebugInfo) {
                return (
                    <>
                        {main_message}
                        {renderFnDebugBox(message)}
                    </>
                );
            }
            return main_message;
        }
        // simple_text or any other state
        return <Typography variant="body2">{message.text}</Typography>;
    };

    // Enable the parent to call startFnRunsForRows
    useImperativeHandle(ref, () => ({
        startFnRunsForRows,
    }));

    // Start function runs for the selected rows
    const startFnRunsForRows = async (
        spaceId: string,
        tableName: string,
        fnId: string,
        fnName: string,
        rows: any[],
    ) => {
        if (!rows.length) {
            return;
        }
        if (!userProfile?.id_token) {
            toast.error("Not authorized. Please sign in.");
            return;
        }

        try {
            const requestBody = {
                space_id: spaceId,
                fn_id: fnId,
                table_name: tableName,
                rows,
            };

            const resp = await fetch_with_auth(
                "start_fn_for_rows",
                userProfile.id_token,
                "POST",
                requestBody,
            );

            if (resp.insufficient_credit) {
                setShowInsufficientCreditsDialog(true);
                return;
            }

            if (!resp || !resp.fn_runs) {
                toast.error("No fn_runs returned from start_fn_for_rows");
                return;
            }

            // For each returned FnRun, create a "bot" message in the chat
            const newFnRuns = resp.fn_runs;

            // Set flag to scroll to bottom
            shouldScrollToBottomRef.current = true;

            newFnRuns.forEach((fnRunObj: any) => {
                const run = new FnRun(fnRunObj);
                const newMessage = createNewFnRunMessage(run, fnName);
                setMessages((prev) => [...prev, newMessage]);
                updateFnRun(newMessage.id, run);
                pollFnRunStatus(run.run_id, newMessage.id);
            });

            toast.success(`Started ${newFnRuns.length} function runs for the selected rows.`);
        } catch (error: any) {
            toast.error(`Error starting function runs for rows: ${error.message}`);
        }
    };

    return (
        <Box
            sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                borderRadius: "5px",
                border: "1px solid",
                minWidth: "30rem",
            }}
            className="min-w-full p-4">
            <Box
                ref={chatContainerRef}
                sx={{
                    position: "relative",
                    flexGrow: 1,
                    overflowY: "auto",
                    p: 1,
                    display: "flex",
                    flexDirection: "column",
                }}>
                {messages.map((message, i) => (
                    <Box
                        key={i}
                        sx={{
                            alignSelf: message.author === "spesh" ? "flex-start" : "flex-end",
                            m: 1,
                            p: 1,
                            bgcolor: message.author === "spesh" ? "lightblue" : "lightgreen",
                            borderRadius: "5px",
                            position: "relative",
                        }}>
                        {renderChatMessage(message)}

                        {/* Menu button for spesh messages */}
                        {message.author === "spesh" &&
                            (message.state === "fn_running" ||
                                message.state === "fn_done" ||
                                message.state === "space_chat_done") && (
                                <IconButton
                                    size="small"
                                    sx={{
                                        position: "absolute",
                                        bottom: 2,
                                        right: 2,
                                        padding: "2px",
                                        backgroundColor: "rgba(255, 255, 255, 0.7)",
                                    }}
                                    onClick={(e) => handleMenuOpen(e, message.id)}>
                                    <MoreVertIcon fontSize="small" />
                                </IconButton>
                            )}
                        {/* Always show menu button for user messages */}
                        {message.author === "user" && (
                            <IconButton
                                size="small"
                                sx={{
                                    position: "absolute",
                                    bottom: 2,
                                    right: 2,
                                    padding: "2px",
                                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                                }}
                                onClick={(e) => handleMenuOpen(e, message.id)}>
                                <MoreVertIcon fontSize="small" />
                            </IconButton>
                        )}
                    </Box>
                ))}

                {/* Message menu */}
                <Menu anchorEl={menuAnchorEl} open={openMenu} onClose={handleMenuClose}>
                    {selectedMessageId &&
                        (() => {
                            const selectedMsg = messages.find((m) => m.id === selectedMessageId);
                            // Only show debug toggle for spesh messages with appropriate states
                            return (
                                selectedMsg &&
                                selectedMsg.author === "spesh" &&
                                ["fn_running", "fn_done", "space_chat_done"].includes(
                                    selectedMsg.state,
                                ) && (
                                    <MenuItem onClick={toggleDebugMode}>
                                        {debugEnabledMessages.has(selectedMessageId)
                                            ? "Hide Debug Info"
                                            : "Show Debug Info"}
                                    </MenuItem>
                                )
                            );
                        })()}
                    <MenuItem onClick={handleDeleteMessage}>Delete Message from History</MenuItem>
                </Menu>

                {showScrollButton && (
                    <Button
                        variant="contained"
                        onClick={() => {
                            chatContainerRef.current?.scrollTo({
                                top: chatContainerRef.current.scrollHeight,
                                behavior: "smooth",
                            });
                        }}
                        sx={{
                            position: "sticky",
                            bottom: 16,
                            left: "50%",
                            transform: "translateX(-50%)",
                            width: 30,
                            height: 30,
                            minWidth: 30,
                            minHeight: 30,
                            borderRadius: "50%",
                            padding: 0,
                            flexShrink: 0,
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            color: "white",
                            lineHeight: 1,
                        }}>
                        <ArrowDownwardIcon />
                    </Button>
                )}
            </Box>
            <form className="flex flex-row" onSubmit={handleSpaceChatSubmit}>
                <TextField
                    className="flex"
                    value={input}
                    onChange={handleInputChange}
                    placeholder="Send a message..."
                    sx={{ flexGrow: 1, mr: 2 }}
                    disabled={false}
                    inputRef={inputRef}
                    multiline={true}
                    minRows={1}
                    maxRows={15}
                />
                <Button type="submit" disabled={false}>
                    <SendIcon />
                </Button>
            </form>
            <InsufficientCreditDialog
                open={showInsufficientCreditsDialog}
                onClose={() => setShowInsufficientCreditsDialog(false)}
            />
        </Box>
    );
});

export default SpaceChat;
