import { Dispatch, SetStateAction } from "react";

import { fetch_with_auth } from "../components/Util";
import { Authz } from "./Authz"; // Import the Authz class

interface FunctionsDictionary {
    [key: string]: string;
}

type StringDictionary = {
    [key: string]: string;
};

/*
 * TableMetadata represents metadata for a table in a Space.
 *
 * Fields:
 *  - columns: string[]
 *      An array of column names. This is the only obligatory field.
 *
 *  - column_values?: { [column: string]: [string, number][] }
 *      (Optional) Maps each column name to a list of tuples. Each tuple consists of a string value and a
 *      floating point number representing the frequency of that value.
 *
 *  - column_desc?: { [column: string]: string }
 *      (Optional) Maps each column name to a string description explaining what the column is and how it works.
 *
 *  - last_mutation_ts?: Date
 *      (Optional) Timestamp of the last mutation of the table. This is used to determine if the frontend
 *      has obsolete data and needs to refresh.
 *
 *  - column_width?: { [column: string]: number }
 *      (Optional) Maps each column name to its display width in rem units.
 *
 *  - column_visibility?: { [column: string]: boolean }
 *      (Optional) Maps each column name to a boolean indicating whether the column should be visible.
 *      If a column is not present in this mapping, it is considered visible by default.
 */
export interface TableMetadata {
    columns: string[];
    column_values?: { [column: string]: [string, number][] };
    column_desc?: { [column: string]: string };
    last_mutation_ts?: Date;
    column_width?: { [column: string]: number };
    column_visibility?: { [column: string]: boolean };
}

export class Space {
    space_id: string;
    name: string | null;
    description: string | null;
    authz: Authz;
    author_id: string;
    author_name: string | null;
    created_ts: Date | null;
    functions: FunctionsDictionary;

    // table_md maps a table name to its metadata (TableMetadata). This metadata includes information
    // such as column names (obligatory), frequent column values, column descriptions, last mutation timestamp,
    // column widths, and column visibility.
    table_md: { [key: string]: TableMetadata };

    // table_type maps a table name to a string indicating its type.
    // The value is 'n' for a native table that lives inside the space,
    // or a long space_id string indicating that the table is imported in read-only mode from another space.
    table_type: { [key: string]: string };

    constructor(space: Partial<Space>) {
        this.space_id = space.space_id || "";
        this.name = space.name || null;
        this.description = space.description || null;
        if (typeof space.authz === "string") {
            try {
                const parsed = JSON.parse(space.authz);
                this.authz = new Authz(parsed);
            } catch (error) {
                console.error("Parsing space.authz failed", error);
                this.authz = new Authz({});
            }
        } else {
            this.authz = new Authz(space.authz || {});
        }
        this.author_id = space.author_id || "";
        this.author_name = space.author_name || null;
        this.created_ts = space.created_ts ? new Date(space.created_ts) : null;
        if (typeof space.functions === "string") {
            try {
                this.functions = JSON.parse(space.functions);
            } catch (error) {
                console.error("Parsing space.functions failed", error);
                this.functions = {};
            }
        } else {
            this.functions = space.functions || {};
        }

        if (typeof space.table_md === "string") {
            try {
                this.table_md = JSON.parse(space.table_md);
            } catch (error) {
                console.error("Parsing space.table_md failed", error);
                this.table_md = {};
            }
        } else {
            this.table_md = space.table_md || {};
        }

        if (typeof space.table_type === "string") {
            try {
                this.table_type = JSON.parse(space.table_type);
            } catch (error) {
                console.error("Parsing space.table_type failed", error);
                this.table_type = {};
            }
        } else {
            this.table_type = space.table_type || {};
        }
    }

    update(setState: Dispatch<SetStateAction<Space>>, someValues: Partial<Space>) {
        const newInstance = new Space({
            ...this,
            ...someValues,
        });
        setState(newInstance);
    }

    static async loadSpace(space_id: string, id_token: string): Promise<Space> {
        const data = await fetch_with_auth(`space?space_id=${space_id}`, id_token, "GET");

        if (!data) {
            throw new Error("Invalid response data");
        }

        const space = new Space(data);
        return space;
    }

    static async delete_from_db(space_id: string, id_token: string) {
        await fetch_with_auth(`space?space_id=${space_id}`, id_token, "DELETE");
        return true;
    }

    async save_to_db(id_token: string) {
        return await fetch_with_auth("space", id_token, "POST", this);
    }

    static async get_spaces_for_user(id_token: string): Promise<Space[]> {
        const data = await fetch_with_auth("space", id_token, "GET");

        if (!Array.isArray(data)) {
            throw new Error("Invalid response data");
        }

        return data.map((spaceData: StringDictionary) => new Space(spaceData));
    }
}
