import React, { SetStateAction, useState, Dispatch } from "react";

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Chip,
    Box,
    FormControlLabel,
    Checkbox,
    Tooltip,
    IconButton,
    Typography,
} from "@mui/material";
import { Info as InfoIcon } from "@mui/icons-material";

import { Fn } from "../types/Fn";
import { FnDef } from "../types/FnDef";

interface FnDialogProps {
    fn: Fn;
    fnDef: FnDef;
    onSave: (fn: Fn, fnDef: FnDef) => void;
    setShowFnDialog: Dispatch<SetStateAction<boolean>>;
    fnDialogCreateNew: boolean;
}

const FnDialog: React.FC<FnDialogProps> = ({
    fn,
    fnDef,
    onSave,
    setShowFnDialog,
    fnDialogCreateNew,
}) => {
    const [name, setName] = useState(fn.name);
    const [description, setDescription] = useState(fn.description);
    const [isPublic, setIsPublic] = useState(fn.authz.is_public);
    const [emails, setEmails] = useState(fn.authz.emails);
    const [teams, setTeams] = useState(fn.authz.teams);
    const [newEmail, setNewEmail] = useState("");
    const [newTeam, setNewTeam] = useState("");
    const [creditCostEstimate, setCreditCostEstimate] = useState(fn.credit_cost_estimate || "");

    const handleSave = () => {
        const updatedFn = new Fn({
            ...fn,
            name,
            description,
            credit_cost_estimate: creditCostEstimate,
            authz: {
                ...fn.authz,
                is_public: isPublic,
                emails,
                teams,
            },
        });
        if (fnDialogCreateNew) {
            // Backend is interpreting invalid fn_id as an attempt to create new function and not editing existing one.
            // It is probably a TODO to change API so that to eliminate possiblity of unintentional function creation.
            updatedFn.fn_id = "";
        }
        onSave(updatedFn, fnDef);
    };

    const handleAddEmail = () => {
        if (newEmail && !emails.includes(newEmail)) {
            setEmails([...emails, newEmail]);
            setNewEmail("");
        }
    };

    const handleAddTeam = () => {
        if (newTeam && !teams.includes(newTeam)) {
            setTeams([...teams, newTeam]);
            setNewTeam("");
        }
    };

    const handleDeleteEmail = (emailToDelete: string) => {
        setEmails(emails.filter((email) => email !== emailToDelete));
    };

    const handleDeleteTeam = (teamToDelete: string) => {
        setTeams(teams.filter((team) => team !== teamToDelete));
    };

    return (
        <Dialog open>
            <DialogTitle>{fn.fn_id ? "Edit" : "Create"} Function</DialogTitle>
            <DialogContent>
                <TextField
                    label="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Credit Cost Estimate"
                    value={creditCostEstimate}
                    onChange={(e) => setCreditCostEstimate(e.target.value)}
                    placeholder="E.g., approx. 2 credits per row"
                    fullWidth
                    margin="normal"
                />
                <Box display="flex" alignItems="center" margin="normal">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isPublic}
                                onChange={(e) => setIsPublic(e.target.checked)}
                            />
                        }
                        label="Public"
                    />
                    <Tooltip title="Public function can be used by anyone on the platform, we recommend not making it public unless you know exactly why everybody can benefit from it.">
                        <IconButton>
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Box display="flex" alignItems="center" margin="normal">
                    <Typography variant="h6">Eligible users</Typography>
                    <Tooltip title="Add or remove email addresses of individuals who would be able to see and use this function">
                        <IconButton>
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Box display="flex" flexWrap="wrap" gap={1} margin="normal">
                    {emails.map((email) => (
                        <Chip key={email} label={email} onDelete={() => handleDeleteEmail(email)} />
                    ))}
                </Box>
                <TextField
                    label="Add Email"
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                    onKeyPress={(e) => e.key === "Enter" && handleAddEmail()}
                    fullWidth
                    margin="normal"
                />
                <Box display="flex" alignItems="center" margin="normal">
                    <Typography variant="h6">Eligible teams</Typography>
                    <Tooltip title="Add or remove teams of people who would be able to use this function. To create a team go to spesh.ai/team or click on Team line in the sidebar on the left.">
                        <IconButton>
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Box display="flex" flexWrap="wrap" gap={1} margin="normal">
                    {teams.map((team) => (
                        <Chip key={team} label={team} onDelete={() => handleDeleteTeam(team)} />
                    ))}
                </Box>
                <TextField
                    label="Add Team"
                    value={newTeam}
                    onChange={(e) => setNewTeam(e.target.value)}
                    onKeyPress={(e) => e.key === "Enter" && handleAddTeam()}
                    fullWidth
                    margin="normal"
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => setShowFnDialog(false)}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={handleSave}>
                    {fn.fn_id ? "Save" : "Create"} Function
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FnDialog;
