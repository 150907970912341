import React, { useCallback, useEffect, useState } from "react";

import { Box, Button, Typography } from "@mui/material";
import { getAuth } from "firebase/auth";
import Popup from "reactjs-popup";

import app from "../auth/firebase";
import { linkWithGithub, linkWithTwitter } from "../auth/login";

interface AuthPopupProps {
    authRequired: boolean;
    authProviders: string[];
}

const AuthPopup: React.FC<AuthPopupProps> = ({ authRequired, authProviders }) => {
    const auth = getAuth(app);
    const [open, setOpen] = useState(false);
    const [remainingAuths, setRemainingAuth] = useState(authProviders);
    const closeModal = () => setOpen(false);

    const contentStyle = {
        borderRadius: "20px",
    };

    const handleAuthProvider = useCallback(
        async (provider: string) => {
            if (provider === "Twitter") {
                await handleTwitter();
            }
            if (provider === "GitHub") {
                await handleGithub();
            }
            setRemainingAuth(remainingAuths.filter((auth) => auth !== provider));
        },
        [remainingAuths],
    );

    const handleTwitter = async () => {
        if (auth.currentUser) {
            linkWithTwitter();
        }
    };

    const handleGithub = async () => {
        if (auth.currentUser) {
            linkWithGithub();
        }
    };

    useEffect(() => {
        if (authRequired && authProviders.length > 0) {
            setOpen(true);
        }
    }, [authRequired, authProviders]);

    useEffect(() => {
        setRemainingAuth(authProviders);
    }, [authProviders]);

    useEffect(() => {
        if (remainingAuths.length == 0) {
            setOpen(false);
        }
    }, [remainingAuths]);

    return (
        <>
            <Popup open={open} closeOnDocumentClick onClose={closeModal} {...{ contentStyle }}>
                <Box
                    className="flex w-full flex-col items-center space-y-5"
                    sx={{
                        p: 4,
                        borderRadius: "20px",
                    }}>
                    <Typography variant="h5" gutterBottom className="text-center">
                        Link the following account(s) to continue
                    </Typography>

                    {remainingAuths.map((provider) => (
                        <Button
                            sx={{
                                "&:hover": {
                                    cursor: "pointer",
                                },
                            }}
                            key={provider}
                            onClick={() => handleAuthProvider(provider)}
                            className="min-w-full">
                            {provider}
                        </Button>
                    ))}

                    <Button
                        sx={{
                            color: "white",
                            "&:hover": {
                                cursor: "pointer",
                            },
                        }}
                        onClick={closeModal}
                        className="w-1/2">
                        Close
                    </Button>
                </Box>
            </Popup>
        </>
    );
};

export default AuthPopup;
