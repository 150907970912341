import React from "react";

import { JSONTree } from "react-json-tree";
import { Edge } from "@xyflow/react";

interface EdgeDataPopupProps {
    edge: Edge;
    stepOutputs: Record<string, Record<string, any>>;
}

const EdgeDataPopup: React.FC<EdgeDataPopupProps> = ({ edge, stepOutputs }) => {
    const stepId = typeof edge.source === "string" ? edge.source : "";
    const outputHandle = edge.sourceHandle ?? "defaultHandle";
    const stepOutput = stepOutputs[stepId];
    const outputData = stepOutput ? stepOutput[outputHandle] : null;

    return (
        <div
            style={{
                zIndex: 1000,
                position: "absolute",
                backgroundColor: "white",
                padding: "10px",
                border: "1px solid #ccc",
                boxShadow: "0 2px 10px rgba(0,0,0,0.2)",
                maxHeight: "100vh",
                width: "35%",
                right: 0,
                top: 0,
                overflow: "auto",
                pointerEvents: "all",
            }}
            id="edge-data-popup"
            className="edge-data-popup">
            {outputData && <JSONTree data={outputData} />}
        </div>
    );
};

export default EdgeDataPopup;
