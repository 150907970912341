import React, { Dispatch } from "react";
import { useState } from "react";

import { HelpOutline, HighlightOff, Add, Close } from "@mui/icons-material";
import { Box, Button, FormControlLabel, Input, MenuItem, Select } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import {
    Edge,
    Node,
    Handle,
    Position,
    NodeResizeControl,
    NodeProps,
    Node as ReactFlowNode,
} from "@xyflow/react";

import { OperatorDeclaration } from "../interfaces/OperatorDeclaration";
import { DagStepType, FnDef } from "../types/FnDef";
import OperatorTooltip from "./OperatorTooltip";

interface ParameterType {
    name: string;
    data_type: string;
    placeholder: string;
    value: string;
}

interface InputOutputType {
    name: string;
    data_type: string;
}

type OperatorNodePayload = {
    selected: boolean;

    name: string;
    inputs: InputOutputType[];
    parameters: ParameterType[];
    outputs: InputOutputType[];
    declaration: OperatorDeclaration;
    nodeCommitFunctionsRef: React.MutableRefObject<Map<string, () => void>>;
    batch: boolean;

    nodes: any[];
    setNodes: Dispatch<React.SetStateAction<any[]>>;

    edges: any[];
    setEdges: Dispatch<React.SetStateAction<any[]>>;

    fnDef: FnDef | null;
    setFnDef: Dispatch<React.SetStateAction<FnDef | null>>;
};

export type OperatorNodeDataType = ReactFlowNode<OperatorNodePayload, "operator">;

// Helper functions to check parameter types
const isList = (dataType: string): boolean => {
    return dataType.endsWith("[]");
};

const isDictionary = (dataType: string): boolean => {
    return dataType === "{}";
};

// List parameter component
function ListParameterEditor({
    paramName,
    value,
    updateParameter,
}: {
    paramName: string;
    value: string;
    updateParameter: (name: string, value: string) => void;
}) {
    // Parse the value as JSON or start with an empty array
    let parsedList: string[] = [];
    try {
        if (typeof value === "string" && value) {
            parsedList = JSON.parse(value);
        } else if (Array.isArray(value)) {
            parsedList = value;
        }
    } catch (e) {
        console.error("Failed to parse list value:", e);
    }

    const [newItem, setNewItem] = useState("");

    const handleAddItem = () => {
        if (newItem.trim() === "") return;

        const updatedList = [...parsedList, newItem];
        // Update both local and global state with the same value
        updateParameter(paramName, JSON.stringify(updatedList));
        setNewItem("");
    };

    const handleDeleteItem = (indexToDelete: number) => {
        const updatedList = parsedList.filter((_, index) => index !== indexToDelete);
        // Update both local and global state with the same value
        updateParameter(paramName, JSON.stringify(updatedList));
    };

    return (
        <div>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "8px", marginBottom: "8px" }}>
                {parsedList.map((item, index) => (
                    <Chip
                        key={index}
                        label={item}
                        onDelete={() => handleDeleteItem(index)}
                        color="primary"
                        size="small"
                    />
                ))}
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Input
                    value={newItem}
                    onChange={(e) => setNewItem(e.target.value)}
                    className="nodrag"
                    placeholder="Add new item"
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                            handleAddItem();
                        }
                    }}
                />
                <IconButton onClick={handleAddItem} size="small">
                    <Add />
                </IconButton>
            </div>
        </div>
    );
}

// Dictionary parameter component
function DictionaryParameterEditor({
    paramName,
    value,
    updateParameter,
}: {
    paramName: string;
    value: string;
    updateParameter: (name: string, value: string) => void;
}) {
    // Parse the value as JSON or start with an empty object
    let parsedDict: Record<string, string> = {};
    try {
        if (typeof value === "string" && value) {
            parsedDict = JSON.parse(value);
        } else if (typeof value === "object" && value !== null) {
            parsedDict = value as Record<string, string>;
        }
    } catch (e) {
        console.error("Failed to parse dictionary value:", e);
    }

    const [newKey, setNewKey] = useState("");
    const [newValue, setNewValue] = useState("");

    const handleAddEntry = () => {
        if (newKey.trim() === "") return;

        const updatedDict = { ...parsedDict, [newKey]: newValue };
        // Update both local and global state with the same value
        updateParameter(paramName, JSON.stringify(updatedDict));
        setNewKey("");
        setNewValue("");
    };

    const handleDeleteEntry = (keyToDelete: string) => {
        const updatedDict = { ...parsedDict };
        delete updatedDict[keyToDelete];
        // Update both local and global state with the same value
        updateParameter(paramName, JSON.stringify(updatedDict));
    };

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    marginBottom: "8px",
                }}>
                {Object.entries(parsedDict).map(([key, value], index) => (
                    <div key={index} style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <Chip label={key} size="small" color="primary" />
                        <span>→</span>
                        <Chip label={value} size="small" />
                        <IconButton onClick={() => handleDeleteEntry(key)} size="small">
                            <Close />
                        </IconButton>
                    </div>
                ))}
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <Input
                    value={newKey}
                    onChange={(e) => setNewKey(e.target.value)}
                    className="nodrag"
                    placeholder="Key"
                    style={{ flex: 1 }}
                />
                <Input
                    value={newValue}
                    onChange={(e) => setNewValue(e.target.value)}
                    className="nodrag"
                    placeholder="Value"
                    style={{ flex: 1 }}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                            handleAddEntry();
                        }
                    }}
                />
                <IconButton onClick={handleAddEntry} size="small">
                    <Add />
                </IconButton>
            </div>
        </div>
    );
}

export default function OperatorNode({ data, id, isConnectable }: NodeProps<OperatorNodeDataType>) {
    const additionalParametersExist = data.declaration.additional_parameters.some(
        (additionalParam) => data.parameters.some((param) => param.name === additionalParam.name),
    );

    const [showAdditionalParameters, setShowAdditionalParameters] =
        React.useState(additionalParametersExist);

    const [localParameters, setLocalParameters] = useState<ParameterType[]>(() => {
        const regular = data.declaration.parameters.map(
            (param): ParameterType => ({ ...param, value: "" }),
        );
        const additional = data.declaration.additional_parameters.map(
            (param): ParameterType => ({ ...param, value: "" }),
        );
        const allDeclared = [...regular, ...additional];
        return allDeclared.map((decl) => {
            const existing = data.parameters.find((p) => p.name === decl.name);
            return existing ? existing : decl;
        });
    });

    // A helper to commit localParameters to global diagram state:
    const commitLocalParameters = (updatedParams = localParameters) => {
        handleNodePayloadChange({ parameters: updatedParams });
    };

    const updateParameterLocalAndGlobal = (paramName: string, newValue: string) => {
        const updatedParams = localParameters.map((p) =>
            p.name === paramName ? { ...p, value: newValue } : p,
        );

        // Update local state
        setLocalParameters(updatedParams);

        // Update global state
        commitLocalParameters(updatedParams);
    };

    // Register commit function - no useEffect needed
    data.nodeCommitFunctionsRef.current.set(id, () => commitLocalParameters());
    // This happens on every render, which is fine since it just updates the ref

    const updateDag = (newDag: DagStepType[]) => {
        if (data.fnDef) {
            const newFnDef = {
                ...data.fnDef,
                dag: newDag,
            };
            data.fnDef.update(data.setFnDef, newFnDef);
        }
    };

    const onRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        // Clean up when removing the node
        data.nodeCommitFunctionsRef.current.delete(id);
        data.setNodes((prevNodes) => {
            const newNodes = prevNodes.filter((n) => n.id !== id);
            data.setEdges((prevEdges) => {
                const newEdges = prevEdges.filter((e) => e.source !== id && e.target !== id);
                const newDag = nodesToDag(newNodes, newEdges);
                updateDag(newDag);
                return newEdges;
            });
            return newNodes;
        });
    };

    const handleNodePayloadChange = (nodePayloadChange: Partial<OperatorNodePayload>) => {
        data.setEdges((prevEdges) => {
            data.setNodes((prevNodes) => {
                const updated = prevNodes.map((n) =>
                    n.id === id ? { ...n, data: { ...n.data, ...nodePayloadChange } } : n,
                );
                const newDag = nodesToDag(updated, prevEdges);
                updateDag(newDag);
                return updated;
            });
            return prevEdges;
        });
    };

    const renderParameters = () => {
        const paramsToRender = showAdditionalParameters
            ? [...data.declaration.parameters, ...data.declaration.additional_parameters]
            : [...data.declaration.parameters];

        return paramsToRender.map((declarationParameter) => {
            if (declarationParameter.condition && declarationParameter.condition.trim() !== "") {
                const [conditionParamName, conditionValue] = declarationParameter.condition
                    .split("==")
                    .map((str) => str.trim());
                const conditionParameter = data.parameters.find(
                    (param) => param.name === conditionParamName,
                );

                if (!conditionParameter || String(conditionParameter.value) !== conditionValue) {
                    // Don't render this parameter if the condition doesn't hold.
                    return null;
                }
            }

            let inputElement;

            if (declarationParameter.data_type === "boolean") {
                // Read/write from localParameters
                const localParam = localParameters.find(
                    (p) => p.name === declarationParameter.name,
                );
                const checkedVal = localParam ? localParam.value === "true" : false;
                inputElement = (
                    <Checkbox
                        checked={checkedVal}
                        onChange={(event) => {
                            const newVal = String(event.target.checked);
                            updateParameterLocalAndGlobal(declarationParameter.name, newVal);
                        }}
                    />
                );
            } else if (declarationParameter.data_type.startsWith("enum(")) {
                // if parameter type is enum, render a dropdown selector
                // get enum values from string

                const matchResult = declarationParameter.data_type.match(/\(([^)]+)\)/);
                let enumValues = [];
                if (matchResult !== null) {
                    enumValues = matchResult[1].split(",");
                } else {
                    enumValues = ["!parameter definition error!"];
                }

                inputElement = (
                    <Select
                        className="nodrag"
                        value={
                            localParameters.find((p) => p.name === declarationParameter.name)
                                ?.value || ""
                        }
                        onChange={(e) => {
                            updateParameterLocalAndGlobal(
                                declarationParameter.name,
                                e.target.value,
                            );
                        }}>
                        {enumValues.map((value) => (
                            <MenuItem key={value} value={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                );
            } else if (isList(declarationParameter.data_type)) {
                // Handle list parameters
                const localParam = localParameters.find(
                    (p) => p.name === declarationParameter.name,
                );
                inputElement = (
                    <ListParameterEditor
                        paramName={declarationParameter.name}
                        value={localParam ? localParam.value : ""}
                        updateParameter={updateParameterLocalAndGlobal}
                    />
                );
            } else if (isDictionary(declarationParameter.data_type)) {
                // Handle dictionary parameters
                const localParam = localParameters.find(
                    (p) => p.name === declarationParameter.name,
                );
                inputElement = (
                    <DictionaryParameterEditor
                        paramName={declarationParameter.name}
                        value={localParam ? localParam.value : ""}
                        updateParameter={updateParameterLocalAndGlobal}
                    />
                );
            } else {
                // otherwise, render regular input field
                const localParam = localParameters.find(
                    (p) => p.name === declarationParameter.name,
                );
                inputElement = (
                    <Input
                        value={localParam && localParam.value !== null ? localParam.value : ""}
                        onChange={(e) => {
                            // Just update local state, we'll commit on blur
                            setLocalParameters((prev) =>
                                prev.map((p) =>
                                    p.name === declarationParameter.name
                                        ? { ...p, value: e.target.value }
                                        : p,
                                ),
                            );
                        }}
                        onBlur={() => {
                            // console.log(`uh oh, cought blur : S`);
                            // Commit local parameters to global state on blur
                            commitLocalParameters(localParameters);
                        }}
                        className="nodrag min-w-full"
                        type={declarationParameter.data_type}
                        placeholder={declarationParameter.placeholder}
                        multiline
                    />
                );
            }

            return (
                <div key={declarationParameter.name}>
                    <label>{declarationParameter.name}: </label>
                    <Tooltip
                        title={
                            <div style={{ fontSize: "16px" }}>
                                {declarationParameter.description}
                            </div>
                        }
                        placement="top">
                        <HelpOutline />
                    </Tooltip>
                    {inputElement}
                </div>
            );
        });
    };

    return (
        <>
            <NodeResizeControl
                style={{ background: "transparent", border: "none" }}
                minWidth={100}
                minHeight={50}
                onResizeEnd={(event, node) => {
                    handleNodePayloadChange({});
                }}>
                <ResizeIcon />
            </NodeResizeControl>
            <Box
                className="space-y-3 rounded-md p-4"
                style={{
                    border: `1px solid darkblue`,
                    background: `white`,
                    height: "100%",
                    overflow: "hidden",
                }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}>
                    <b>{data.name}</b>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {data.declaration.allow_batch && (
                            <FormControlLabel
                                label="Batch Mode"
                                labelPlacement="start"
                                control={
                                    <Checkbox
                                        checked={data.batch || false}
                                        onChange={(event) =>
                                            handleNodePayloadChange({ batch: event.target.checked })
                                        }
                                    />
                                }
                            />
                        )}
                        <button
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                e.preventDefault();
                            }}
                            style={{
                                marginLeft: 8,
                            }}>
                            <OperatorTooltip declaration={data.declaration} />
                        </button>
                        <button
                            onClick={onRemove}
                            style={{
                                marginLeft: 8,
                            }}>
                            <HighlightOff />
                        </button>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {data.inputs.map((input, i) => {
                        const commonProps = {
                            className: "source",
                            "data-handleid": input.name,
                            "data-handlepos": "top",
                            "data-nodeid": id,
                        };

                        return (
                            <Handle
                                type="target"
                                position={Position.Top}
                                style={{
                                    backgroundColor: "#555",
                                    width: "10px",
                                    height: "10px",
                                    borderRadius: "50%",
                                    left:
                                        data.inputs.length > 1
                                            ? `${
                                                  (100.0 / (data.inputs.length - 1.0 / 3)) *
                                                  (i + 1.0 / 3)
                                              }%`
                                            : "50%",
                                }}
                                id={input.name}
                                key={input.name}
                                isConnectable={isConnectable}>
                                <div
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -120%)",
                                    }}>
                                    <Typography variant="body1" {...commonProps}>
                                        {input.name}
                                    </Typography>
                                    <Typography variant="caption" {...commonProps}>
                                        {`type=${
                                            data.batch ? input.data_type + "[]" : input.data_type
                                        }`}
                                    </Typography>
                                </div>
                            </Handle>
                        );
                    })}
                </div>
                {renderParameters()}

                {data.declaration.additional_parameters &&
                    data.declaration.additional_parameters.length > 0 && (
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                setShowAdditionalParameters((prevState) => !prevState);
                            }}>
                            {showAdditionalParameters
                                ? "Hide additional parameters"
                                : "Show additional parameters"}
                        </Button>
                    )}

                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {data.outputs.map((output, i) => {
                        const commonProps = {
                            className: "target",
                            "data-handleid": output.name,
                            "data-handlepos": "bottom",
                            "data-nodeid": id,
                        };

                        return (
                            <Handle
                                type="source"
                                position={Position.Bottom}
                                style={{
                                    backgroundColor: "#555",
                                    width: "10px",
                                    height: "10px",
                                    borderRadius: "50%",
                                    left:
                                        data.outputs.length > 1
                                            ? `${
                                                  (100.0 / (data.outputs.length - 1.0 / 3)) *
                                                  (i + 1.0 / 3)
                                              }%`
                                            : "50%",
                                }}
                                id={output.name}
                                key={output.name}
                                isConnectable={isConnectable}>
                                <div
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, 10%)",
                                    }}>
                                    <Typography variant="body1" {...commonProps}>
                                        {output.name}
                                    </Typography>
                                    <Typography variant="caption" {...commonProps}>
                                        {`type=${
                                            data.batch ? output.data_type + "[]" : output.data_type
                                        }`}
                                    </Typography>
                                </div>
                            </Handle>
                        );
                    })}
                </div>
            </Box>
        </>
    );
}

function nodesToDag(nodes: Node[], edges: Edge[]): DagStepType[] {
    const edgeMap = new Map<string, Edge[]>();

    for (const edge of edges) {
        const edgeArray = edgeMap.get(edge.target) || [];
        edgeArray.push(edge);
        edgeMap.set(edge.target, edgeArray);
    }

    const dag: DagStepType[] = nodes.map((node) => {
        // Example value of a node here:
        /*  {
                "id":"dndnode_1",
                "type":"operator",
                "position":{"x":-172.7086717860799,"y":-447.56448254136876},
                "data":{
                    "description":"",
                    "inputs":[],
                    "name":"Ingest PDF",
                    "outputs":[{"data_type":"Document[]","name":"pdf_content"}],
                    "parameters":[{"data_type":"string","name":"pdf_uri","placeholder":"Enter the URL of the PDF"}],
                    "secrets":[],
                    "batch": false
                },
                "width":270,
                "height":63,
                "selected":true,
                "dragging":false,
                "positionAbsolute":{"x":-172.7086717860799,"y":-447.56448254136876}
            }
        */

        const inputs: Record<string, [string, string]> = {};

        const nodeEdges = edgeMap.get(node.id) || [];
        for (const edge of nodeEdges) {
            if (edge.targetHandle) {
                inputs[edge.targetHandle] = [edge.source, edge.sourceHandle ?? ""];
            }
        }

        // Assert node.data is OperatorNodePayload
        const payload = node.data as OperatorNodePayload;
        const parameters: Record<string, string | null> = {};
        for (const parameter of payload.parameters) {
            // parameters[parameter.name] = parameter.value || null; Not sure change below is meaningful
            parameters[parameter.name] = parameter.value !== undefined ? parameter.value : null;
        }

        return {
            id: node.id,
            operator: payload.name,
            position: node.position,
            width: node.width,
            height: node.height,
            selected: node.selected,
            parameters,
            inputs: Object.keys(inputs).length > 0 ? inputs : {},
            batch: payload.batch,
        };
    });

    return dag;
}

function ResizeIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="#ff0071"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{ position: "absolute", right: 5, bottom: 5 }}>
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <polyline points="16 20 20 20 20 16" />
            <line x1="14" y1="14" x2="20" y2="20" />
            <polyline points="8 4 4 4 4 8" />
            <line x1="4" y1="4" x2="10" y2="10" />
        </svg>
    );
}

export { nodesToDag };
export type { InputOutputType, OperatorNodePayload, ParameterType };
