// src/config/DetailPanelConfig.ts
export interface ColumnFormatSpec {
    columnName: string;
    dataType: "text" | "date" | "url" | "list" | "json" | "number" | "boolean";
}

export interface SectionConfig {
    [sectionName: string]: ColumnFormatSpec[];
}

export interface DetailPanelConfig {
    [entityType: string]: SectionConfig;
}

// Example configuration for 'person' entity type
export const detailPanelConfig: DetailPanelConfig = {
    person: {
        "Bio Information": [
            { columnName: "first_name", dataType: "text" },
            { columnName: "last_name", dataType: "text" },
            { columnName: "middle_initial", dataType: "text" },
            { columnName: "credentials", dataType: "text" },
            { columnName: "speciality", dataType: "text" },
            { columnName: "city", dataType: "text" },
            { columnName: "state", dataType: "text" },
            { columnName: "all_secondary_specialities", dataType: "list" },
            { columnName: "med_school", dataType: "text" },
            { columnName: "grad_year", dataType: "text" },
        ],
        "Person Contact Information": [
            { columnName: "email", dataType: "text" },
            { columnName: "email_domain", dataType: "text" },
            { columnName: "email_score", dataType: "text" },
            { columnName: "email_accept_all", dataType: "text" },
            { columnName: "address_line1", dataType: "text" },
            { columnName: "address_line2", dataType: "text" },
            { columnName: "city", dataType: "text" },
            { columnName: "state", dataType: "text" },
            { columnName: "zipcode", dataType: "text" },
            { columnName: "country", dataType: "text" },
        ],
        "Affiliated Organizations": [
            { columnName: "primary_facility_name", dataType: "text" },
            { columnName: "primary_org_npi", dataType: "text" },
            { columnName: "primary_org_size", dataType: "text" },
            { columnName: "primary_org_classification", dataType: "text" },
            { columnName: "primary_org_authorized_official", dataType: "text" },
            { columnName: "primary_org_auth_official_phone", dataType: "text" },
            { columnName: "affiliated_facility_names", dataType: "list" },
            { columnName: "affiliated_org_sizes", dataType: "list" },
        ],
        "Medicare Information": [
            { columnName: "medicare_participant_indicator", dataType: "text" },
            { columnName: "medicare_beneficiaries", dataType: "number" },
            { columnName: "HCPCS_codes", dataType: "text" },
            { columnName: "services", dataType: "number" },
            { columnName: "total_charges", dataType: "number" },
            { columnName: "total_medicare_allowed", dataType: "number" },
            { columnName: "total_medicare_payout", dataType: "number" },
        ],
        "Billing details": [
            { columnName: "adhd_patient_pct", dataType: "number" },
            { columnName: "alco_and_drug_patient_pct", dataType: "number" },
            { columnName: "tobacco_patient_pct", dataType: "number" },
            { columnName: "alzheimer_and_demenia_patient_pct", dataType: "number" },
            { columnName: "anxiety_patient_pct", dataType: "number" },
            { columnName: "bipolar_patient_pct", dataType: "number" },
            { columnName: "mood_disorder_patient_pct", dataType: "number" },
            { columnName: "depression_patient_pct", dataType: "number" },
            { columnName: "personality_disorder_patient_pct", dataType: "number" },
            { columnName: "ptsd_patient_pct", dataType: "number" },
            { columnName: "schizophrenia_patient_pct", dataType: "number" },
            { columnName: "asthma_patient_pct", dataType: "number" },
            { columnName: "fibrillation_patient_pct", dataType: "number" },
            { columnName: "cancer_patient_pct", dataType: "number" },
            { columnName: "chronic_kidney_patient_pct", dataType: "number" },
            { columnName: "chronic_pulmonary_patient_pct", dataType: "number" },
            { columnName: "diabetes_patient_pct", dataType: "number" },
            { columnName: "heart_disease_patient_pct", dataType: "number" },
            { columnName: "hyperlipidemia_patient_pct", dataType: "number" },
            { columnName: "hypertension_patient_pct", dataType: "number" },
            { columnName: "ischemic_heart_patient_pct", dataType: "number" },
            { columnName: "osteoporosis_patient_pct", dataType: "number" },
            { columnName: "parkinson_patient_pct", dataType: "number" },
            { columnName: "arthritis_patient_pct", dataType: "number" },
            { columnName: "stroke_patient_pct", dataType: "number" },
            { columnName: "avg_HCC_risk_score", dataType: "number" },
        ],
        Demographics: [
            { columnName: "gender", dataType: "text" },
            { columnName: "average_age", dataType: "number" },
            { columnName: "age_less_65_count", dataType: "number" },
            { columnName: "age_65_to_74_count", dataType: "number" },
            { columnName: "age_75_to_84_count", dataType: "number" },
            { columnName: "age_over_84_count", dataType: "number" },
            { columnName: "female_count", dataType: "number" },
            { columnName: "male_count", dataType: "number" },
        ],
        // Add more sections as needed for person entity type
    },
    // Add more entity types as needed
};
