import { useEffect, useState } from "react";

import { Pageview as PageviewIcon, Search as SearchIcon } from "@mui/icons-material";
import {
    Box,
    IconButton,
    Link as MuiLink,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import { Link as RouterLink } from "react-router-dom";
import { JSONTree } from "react-json-tree";

import ActionFeed from "../components/ActionFeed";
import Header from "../components/Header";
import { fetch_with_auth } from "../components/Util";
import { FnRun } from "../types/FnRun";
import { useUserProfile } from "../context/UserProfileContext";

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 900,
    },
});

const formatDateToLocal = (date: Date | null) => {
    if (date) {
        return new Intl.DateTimeFormat("default", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZoneName: "short",
        }).format(date);
    } else {
        return "N/A";
    }
};

const calculateRunTime = (created_ts: string | null, finished_ts: string | null) => {
    if (created_ts && finished_ts) {
        const createdTime = new Date(created_ts).getTime();
        const finishedTime = new Date(finished_ts).getTime();
        const duration = finishedTime - createdTime;

        const hours = Math.floor(duration / (1000 * 60 * 60));
        const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((duration % (1000 * 60)) / 1000);

        return `${hours}h ${minutes}m ${seconds}s`;
    }
    return "N/A";
};

const renderBlobContent = (blobData: object) => {
    const stringifiedData = JSON.stringify(blobData);
    if (stringifiedData.length <= 10000) {
        return <JSONTree data={blobData} />;
    }
    return (
        <CustomWidthTooltip
            max-width="1000"
            title={
                <Box sx={{ width: "100%" }}>
                    <Box p={2}>
                        <JSONTree data={blobData} />
                    </Box>
                </Box>
            }
            arrow
            placement="bottom-end">
            <IconButton>
                <SearchIcon />
            </IconButton>
        </CustomWidthTooltip>
    );
};

const RunHistoryPage = () => {
    const { userProfile } = useUserProfile();

    useEffect(() => {
        if (userProfile.id_token) {
            fetchHistory(userProfile.id_token);
        }
    }, [userProfile.id_token]);

    const [fnRuns, setFnRuns] = useState<FnRun[]>([]);

    const fetchHistory = async (id_token: string) => {
        try {
            const data = await fetch_with_auth("run_history", id_token, "GET");
            const newFnRuns = data.map((element: any) => new FnRun(element));

            newFnRuns.sort((a: FnRun, b: FnRun) => {
                const aDate = a.created_ts ? new Date(a.created_ts).getTime() : 0;
                const bDate = b.created_ts ? new Date(b.created_ts).getTime() : 0;
                return bDate - aDate;
            });
            setFnRuns(newFnRuns);
        } catch (error) {
            console.error("Error fetching pipeline run history:", error);
        }
    };

    return (
        <div className="flex h-full min-w-full flex-col">
            <Header title="History" subtitle="Revisit any of your previous function runs"></Header>
            <div className="flex-grow overflow-hidden">
                <TableContainer
                    component={Paper}
                    sx={{
                        maxHeight: "calc(100vh - 120px)",
                        height: "100%",
                        overflow: "auto",
                    }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Function ID</TableCell>
                                <TableCell>Run ID</TableCell>
                                <TableCell>State</TableCell>
                                <TableCell>Finished</TableCell>
                                <TableCell>Run Time</TableCell>
                                <TableCell>Run Inputs</TableCell>
                                <TableCell>Run Outputs</TableCell>
                                <TableCell>Log</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {fnRuns.map((run, index) => (
                                <TableRow key={index} sx={{ height: "auto" }}>
                                    <TableCell>
                                        <MuiLink
                                            to={`${window.location.pathname}?fn_id=${run.fn_id}`}
                                            component={RouterLink}
                                            rel="noreferrer"
                                            sx={{
                                                textDecoration: "underline",
                                            }}>
                                            {run.fn_id}
                                        </MuiLink>
                                    </TableCell>
                                    <TableCell>
                                        <MuiLink
                                            to={`/function_editor?&run_id=${run.run_id}`}
                                            component={RouterLink}
                                            rel="noreferrer"
                                            sx={{
                                                textDecoration: "underline",
                                            }}>
                                            {run.run_id}
                                        </MuiLink>
                                    </TableCell>
                                    <TableCell>{run.state}</TableCell>
                                    <TableCell>{formatDateToLocal(run.finished_ts)}</TableCell>
                                    <TableCell>
                                        {calculateRunTime(
                                            run.created_ts?.toString() || null,
                                            run.finished_ts?.toString() || null,
                                        )}
                                    </TableCell>
                                    <TableCell>{renderBlobContent(run.run_inputs || {})}</TableCell>
                                    <TableCell>
                                        {renderBlobContent(run.run_outputs || {})}
                                    </TableCell>
                                    <TableCell>
                                        <CustomWidthTooltip
                                            max-width="1000"
                                            title={
                                                <Box sx={{ width: "100%" }}>
                                                    <Box p={2}>
                                                        <ActionFeed fnRun={run} />
                                                    </Box>
                                                </Box>
                                            }
                                            arrow
                                            placement="bottom-end">
                                            <IconButton>
                                                <PageviewIcon />
                                            </IconButton>
                                        </CustomWidthTooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};

export default RunHistoryPage;
