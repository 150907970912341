import React from "react";

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from "@mui/material";
import { Link } from "react-router-dom";

interface InsufficientCreditDialogProps {
    open: boolean;
    onClose: () => void;
}

const InsufficientCreditDialog: React.FC<InsufficientCreditDialogProps> = ({ open, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="insufficient-credit-dialog-title">
            <DialogTitle id="insufficient-credit-dialog-title">Insufficient Credits</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    It looks like you've run out of credits. Please visit My Subscription to get
                    more.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
                <Button
                    component={Link}
                    to="/team"
                    color="primary"
                    variant="contained"
                    onClick={onClose}>
                    Go to My Subscription
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InsufficientCreditDialog;
