import React from "react";

import { Box, Container, Link, Typography } from "@mui/material";

const TermsOfService = () => {
    return (
        <Container maxWidth="md">
            <Typography variant="h2" component="h1" gutterBottom>
                Terms of Service
            </Typography>
            <Typography variant="body1" paragraph>
                Last Updated: December 13th 2023
            </Typography>
            <Box sx={{ my: 2 }}>
                <Typography variant="h6" component="h2">
                    1. Acceptance of Terms
                </Typography>
                <Typography variant="body1" paragraph>
                    By accessing and using the Spesh website and services, you agree to be bound
                    these Terms of Service ("Terms"). If you do not agree to these Terms, please do
                    not use our services. These Terms apply to all users of the Spesh service.
                </Typography>

                <Typography variant="h6" component="h2">
                    2. Modification of Terms
                </Typography>
                <Typography variant="body1" paragraph>
                    We reserve the right, at our discretion, to change or modify these Terms at any
                    time. We will notify you of any changes by posting the new Terms on this page.
                    Your continued use of the Service following the posting of changes will mean you
                    accept and agree to the changes.
                </Typography>

                <Typography variant="h6" component="h2">
                    3. Use of Service
                </Typography>
                <Typography variant="body1" paragraph>
                    The Spesh service is provided for your personal and non-commercial use. You
                    agree not to use the service for any illegal or unauthorized purpose.
                </Typography>

                <Typography variant="h6" component="h2">
                    4. User Accounts
                </Typography>
                <Typography variant="body1" paragraph>
                    To access certain features of the Service, you may be required to create an
                    account. You agree to provide accurate and complete information and keep your
                    account information updated.
                </Typography>

                <Typography variant="h6" component="h2">
                    5. Intellectual Property
                </Typography>
                <Typography variant="body1" paragraph>
                    All content on Spesh, including text, graphics, logos, and software, is the
                    property of Spesh.ai or its licensors and is protected by intellectual property
                    laws.
                </Typography>

                {/* Add more sections as needed */}
            </Box>
            <Box sx={{ my: 2 }}>
                <Typography variant="body2">
                    If you have any questions about these Terms, please contact us at{" "}
                    <Link href="mailto:help@spesh.ai" underline="hover">
                        help@spesh.ai
                    </Link>
                    .
                </Typography>
            </Box>
        </Container>
    );
};

export default TermsOfService;
