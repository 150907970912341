import React, { useState } from "react";

import { TextField, Chip, Box } from "@mui/material";

interface EmailChipInputProps {
    emails: string[];
    setEmails: (emails: string[]) => void;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
}

const EmailChipInput: React.FC<EmailChipInputProps> = ({
    emails,
    setEmails,
    label = "Email addresses",
    placeholder = "Enter email addresses (press Enter, Space or comma to add)",
    disabled = false,
}) => {
    const [inputValue, setInputValue] = useState("");

    const handleInputKeyDown = (event: React.KeyboardEvent) => {
        if (["Enter", " ", ","].includes(event.key) && inputValue.trim()) {
            event.preventDefault();
            addEmail();
        }
    };

    const addEmail = () => {
        const email = inputValue.trim();
        if (email && !emails.includes(email) && /\S+@\S+\.\S+/.test(email)) {
            setEmails([...emails, email]);
            setInputValue("");
        }
    };

    const removeEmail = (email: string) => {
        setEmails(emails.filter((e) => e !== email));
    };

    return (
        <Box>
            <TextField
                fullWidth
                variant="outlined"
                size="small"
                label={label}
                placeholder={placeholder}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleInputKeyDown}
                onBlur={() => inputValue.trim() && addEmail()}
                margin="normal"
                disabled={disabled}
            />
            <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
                {emails.map((email) => (
                    <Chip
                        key={email}
                        label={email}
                        onDelete={disabled ? undefined : () => removeEmail(email)}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default EmailChipInput;
