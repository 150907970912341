import React, { useEffect, useRef, useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Button, Card, CardContent, Container, IconButton, Typography, Link } from "@mui/material";
import { List, ListItem, ListItemSecondaryAction, ListItemText } from "@mui/material";

import Header from "../components/Header";
import { fetch_with_auth } from "../components/Util";
import { UserProfile } from "../types/UserProfile";
import { useUserProfile } from "../context/UserProfileContext";

interface FileListProps {
    userProfile: UserProfile;
    files: string[];
    onDelete: (fileName: string) => void;
}

const FileList: React.FC<FileListProps> = ({ files, onDelete }) => {
    return (
        <List>
            {files.map((file, index) => (
                <ListItem key={index}>
                    <ListItemText primary={file} />
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete" onClick={() => onDelete(file)}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            ))}
        </List>
    );
};

const DataPage: React.FC = () => {
    const [files, setFiles] = useState<string[]>([]);
    const [uploadProgress, setUploadProgress] = useState<number | null>(null);
    const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const { userProfile } = useUserProfile();

    const fetchFiles = async (id_token: string) => {
        const response = await fetch_with_auth(`list_all_files`, id_token, "GET");
        setFiles(response.files);
    };

    useEffect(() => {
        if (userProfile.id_token) {
            fetchFiles(userProfile.id_token);
        }
    }, [userProfile.id_token]);

    const handleFileUpload = async (file: File): Promise<boolean> => {
        try {
            // Step 1: Initialize progress tracking
            setUploadProgress(0);

            // Step 2: Get a signed URL from the backend
            const urlResponse = await fetch_with_auth(
                "get_upload_url",
                userProfile.id_token || "",
                "POST",
                {
                    fileName: file.name,
                    contentType: file.type,
                },
            );

            // Step 3: Upload directly to GCS using the signed URL with progress tracking
            return new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();

                // Track upload progress
                xhr.upload.onprogress = (event) => {
                    if (event.lengthComputable) {
                        const percentComplete = Math.round((event.loaded / event.total) * 100);
                        setUploadProgress(percentComplete);
                    }
                };

                xhr.onload = async () => {
                    if (xhr.status === 200) {
                        // Verify the upload
                        try {
                            await fetch_with_auth(
                                "confirm_upload",
                                userProfile.id_token || "",
                                "POST",
                                { blobPath: urlResponse.blob_path },
                            );

                            // Refresh the file list
                            await fetchFiles(userProfile.id_token || "");
                            setUploadProgress(null);
                            resolve(true);
                        } catch (error) {
                            setUploadProgress(null);
                            reject(error);
                        }
                    } else {
                        setUploadProgress(null);
                        reject(new Error(`Upload failed with status: ${xhr.status}`));
                    }
                };

                xhr.onerror = () => {
                    setUploadProgress(null);
                    reject(new Error("Upload failed due to network error"));
                };

                // Initialize the request
                xhr.open("PUT", urlResponse.upload_url, true);
                xhr.setRequestHeader("Content-Type", file.type);

                // Send the file
                xhr.send(file);
            });
        } catch (error) {
            console.error("Upload error:", error);
            setUploadProgress(null);
            return false;
        }
    };

    const onSelectFile = async () => {
        if (!inputRef.current?.files?.[0]) {
            console.error("No file selected");
            return;
        }
        const file = inputRef.current.files[0];
        inputRef.current.value = ""; // clear files
        try {
            const success = await handleFileUpload(file);
            if (!success) {
                alert("Upload failed. Please try again.");
            }
        } catch (error: any) {
            alert(`Upload error: ${error.message}`);
        }
    };

    const onDelete = async (fileName: string) => {
        const response = await fetch_with_auth(
            `delete_file?&file_name=${fileName}`,
            userProfile.id_token || "",
            "DELETE",
        );

        setFiles(files.filter((file) => file !== fileName));
        fetchFiles(userProfile.id_token || "");
    };

    return (
        <Container className="min-h-screen min-w-full">
            <Header title="Data" subtitle="Manage your uploaded files"></Header>

            <Card>
                <CardContent>
                    <div className="flex flex-row items-center justify-between">
                        <Typography variant="h5">
                            Once file is uploaded you can create a table out of in your{" "}
                            <Link href="/space" style={{ textDecoration: "none" }}>
                                Space
                            </Link>
                            . Click "Add Table" to make table out of a file.
                        </Typography>
                    </div>
                    <div className="flex flex-col space-y-3">
                        <FileList userProfile={userProfile} files={files} onDelete={onDelete} />
                    </div>

                    <div className="mt-3 flex flex-row items-center justify-between space-x-3">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => inputRef.current?.click()}
                            /*sx={{
                                color: `${colors.primary[100]} !important`,
                                background: `${colors.primary[400]} !important`,
                                "&:hover": {
                                    backgroundColor: `${colors.greenAccent[800]} !important`,
                                },
                            }}*/
                        >
                            <UploadFileIcon sx={{ marginRight: "0.5rem" }} />
                            Upload File
                        </Button>
                        {/* Progress indicator */}
                        {uploadProgress !== null && (
                            <div style={{ marginTop: "10px", width: "100%" }}>
                                <Typography variant="body2">
                                    Uploading: {uploadProgress}%
                                </Typography>
                                <div
                                    style={{
                                        height: "4px",
                                        width: "100%",
                                        backgroundColor: "#e0e0e0",
                                    }}>
                                    <div
                                        style={{
                                            height: "100%",
                                            width: `${uploadProgress}%`,
                                            backgroundColor: "#4CAF50",
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        <input
                            type="file"
                            style={{ display: "none" }}
                            ref={inputRef}
                            onChange={onSelectFile}
                        />
                    </div>
                </CardContent>
            </Card>
        </Container>
    );
};

export default DataPage;
