import { FC, useEffect, useRef, useState } from "react";

import FeedbackIcon from "@mui/icons-material/Feedback";
import GroupsIcon from "@mui/icons-material/Groups";
import SaveIcon from "@mui/icons-material/Save";
import FunctionsIcon from "@mui/icons-material/Functions";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import EditIcon from "@mui/icons-material/Edit";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import TableViewOutlinedIcon from "@mui/icons-material/TableViewOutlined";
import SavingsIcon from "@mui/icons-material/Savings";
import {
    Box,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button as MuiButton,
    Chip,
    Stack,
    Tooltip,
} from "@mui/material";
import { getAuth, onAuthStateChanged, signOut, User } from "firebase/auth";
import { Link } from "react-router-dom";

import logo from "../logo.svg";
import app from "../auth/firebase";
import { googleSignIn } from "../auth/login";
import { LoginButton } from "../components/LoginBtn";
import { useUserProfile } from "../context/UserProfileContext";

interface SidebarProps {
    isSmallScreen: boolean;
    isCollapsed: boolean;
    setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
    isHidden: boolean;
    setIsHidden: React.Dispatch<React.SetStateAction<boolean>>;
}

const PlatformSidebar: FC<SidebarProps> = ({
    isSmallScreen,
    isCollapsed,
    setIsCollapsed,
    isHidden,
    setIsHidden,
}) => {
    const [selected, setSelected] = useState("Space");
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [openAuthDialog, setOpenAuthDialog] = useState<boolean>(false);
    const [isMouseHovering, setIsMouseHovering] = useState(false);
    const sidebarRef = useRef<HTMLDivElement>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { userProfile } = useUserProfile();

    const auth = getAuth(app);

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null;

        const handleDelayedCollapse = () => {
            if (!isCollapsed && !isMouseHovering) {
                setIsCollapsed(true);
            }
        };

        if (!isMouseHovering && !isCollapsed) {
            timer = setTimeout(handleDelayedCollapse, 0);
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [isMouseHovering, isCollapsed, setIsCollapsed]);

    // close the sidebar when clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                sidebarRef.current &&
                !sidebarRef.current.contains(event.target as Node) &&
                !anchorEl
            )
                setIsCollapsed(true);
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setIsCollapsed, anchorEl]);

    const handleSignIn = async () => {
        if (!auth.currentUser) {
            await googleSignIn();
        }
    };

    const handleSignOut = () => {
        signOut(auth);
    };

    useEffect(() => {
        const refresh = onAuthStateChanged(auth, (user) => {
            // this is a listener that triggers every time the user's authentication state changes
            setCurrentUser(user);
        });

        return () => {
            refresh();
        };
    }, [auth]);

    // Open the auth dialog if no user is signed in
    useEffect(() => {
        if (!currentUser) {
            setOpenAuthDialog(true);
        } else {
            setOpenAuthDialog(false);
        }
    }, [currentUser]);

    const menuItems = [
        {
            title: "Space",
            to: "/space",
            icon: <TableViewOutlinedIcon />,
        },
        {
            title: "Functions",
            to: "/functions",
            icon: <FunctionsIcon />,
        },
        {
            title: "Function Editor",
            to: "/function_editor",
            icon: <DesignServicesIcon />,
        },
        {
            title: "Function Runs",
            to: "/run_history",
            icon: <WorkHistoryIcon />,
        },
        {
            title: "My files",
            to: "/data",
            icon: <SaveIcon />,
        },
        {
            title: "Help",
            to: "/feedback",
            icon: <FeedbackIcon />,
        },
        ...(currentUser?.email?.endsWith("@spesh.ai")
            ? [
                  {
                      title: "[admin] all spaces",
                      to: "/space_editor",
                      icon: <EditIcon />,
                  },
              ]
            : []),
        ...(currentUser?.email?.endsWith("@spesh.ai")
            ? [
                  {
                      title: "[admin] all teams",
                      to: "/team",
                      icon: <GroupsIcon />,
                  },
              ]
            : [
                  {
                      title: "My Subscription",
                      to: "/team",
                      icon: <GroupsIcon />,
                  },
              ]),
    ];

    return (
        <div className="w-[60px]">
            <Drawer
                ref={sidebarRef}
                variant="permanent"
                onMouseEnter={() => {
                    setIsMouseHovering(true);
                    setIsCollapsed(false);
                }}
                onMouseLeave={() => setIsMouseHovering(false)}
                anchor="left"
                sx={{
                    width: isCollapsed ? 60 : 240,
                    flexShrink: 0,
                    position: "absolute",
                    transition: "width 0.3s ease",
                    "& .MuiDrawer-paper": {
                        width: isCollapsed ? 60 : 240,
                        boxSizing: "border-box",
                        //backgroundColor: colors.primary[400],
                        transition: "width 0.3s ease",
                    },
                }}>
                <Box
                    display="flex"
                    alignItems="center"
                    p={1}
                    sx={{
                        //backgroundColor: colors.primary[400],
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                    }}>
                    <IconButton sx={{ mr: 1.5 }}>
                        <img src={logo} alt="Logo" width={28} />
                    </IconButton>
                    <Typography
                        variant="h4" //color={colors.grey[100]}
                    >
                        Spesh AI
                    </Typography>
                </Box>
                <List>
                    {menuItems.map((item) => (
                        <ListItem
                            disablePadding
                            key={item.to}
                            component={Link}
                            to={item.to}
                            onClick={() => setSelected(item.title)}
                            sx={{
                                display: "block",
                                //"&.Mui-selected": {
                                //    backgroundColor: colors.greenAccent[500],
                                //},
                                //"&.Mui-selected:hover": {
                                //    backgroundColor: colors.greenAccent[600],
                                //},
                                //"&:hover": {
                                //    backgroundColor: colors.greenAccent[500],
                                //},
                            }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: "initial",
                                    px: 2.5,
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                }}>
                                <ListItemIcon
                                    sx={{
                                        //color: colors.grey[100],
                                        minWidth: 0,
                                        mr: 3,
                                        justifyContent: "center",
                                    }}>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText
                                    primary={item.title}
                                    //sx={{ color: colors.grey[100] }}
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                <div className="flex-grow"></div>
                {!isCollapsed && userProfile.team && (
                    <Box px={2} py={1}>
                        <Stack direction="row" spacing={1} justifyContent="space-between">
                            <Tooltip title="Go to My Subscription to get more">
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    flexWrap="nowrap"
                                    overflow="hidden">
                                    <SavingsIcon sx={{ mr: 0.5 }} />
                                    <Chip
                                        label={`${userProfile.team.credit_left} Credits Left`}
                                        color="primary"
                                        size="small"
                                    />
                                </Box>
                            </Tooltip>
                        </Stack>
                    </Box>
                )}
                <Box display="flex">
                    <LoginButton
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        currentUser={currentUser}
                        handleSignOut={handleSignOut}
                        handleSignIn={handleSignIn}
                        collapsed={isCollapsed}
                    />
                </Box>
            </Drawer>
            <Dialog
                open={openAuthDialog}
                onClose={() => setOpenAuthDialog(false)}
                aria-labelledby="auth-dialog-title"
                aria-describedby="auth-dialog-description">
                <DialogTitle id="auth-dialog-title">{"Authentication Required"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="auth-dialog-description">
                        No signup is necessary, we just need to make sure nobody else has access to
                        your data. Please sign in with your Google account.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MuiButton onClick={handleSignIn} color="primary" variant="contained">
                        Sign in with Google
                    </MuiButton>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default PlatformSidebar;
