import React, { useEffect, useState, useRef } from "react";

import { TextField, Menu, MenuItem, Button } from "@mui/material";
import { getAuth } from "firebase/auth";
import toast from "react-hot-toast";
import { useEdgesState, useNodesState, BuiltInEdge, ReactFlowProvider } from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { v4 as uuidv4 } from "uuid";

import app from "../auth/firebase";
import ActionFeed from "../components/ActionFeed";
import { fetch_with_auth } from "../components/Util";
import { Fn } from "../types/Fn";
import { FnRun } from "../types/FnRun";
import { DagStepType, FnDef } from "../types/FnDef";
import AuthPopup from "../components/AuthPopup";
import DagDiagram from "../components/DagDiagram";
import RunSettings from "../components/RunSettings";
import { apiUrl, pipelineRequiresAuth, setCurrentUrlParam } from "../components/Util";
import { nodesToDag, OperatorNodeDataType } from "../components/OperatorNode";
import FnDialog from "../components/FnDialog";
import InsufficientCreditDialog from "../components/m10n/InsufficientCreditDialog";
import { useUserProfile } from "../context/UserProfileContext";

const FunctionEditorPage = () => {
    // Map to store node commit functions. We want to be able to commit all local parameter
    // changes in each operator node prior to submission of the form (i.e. starting new function run).
    const nodeCommitFunctionsRef = useRef<Map<string, () => void>>(new Map());

    const [showInsufficientCreditsDialog, setShowInsufficientCreditsDialog] =
        useState<boolean>(false);

    const [fnDialogCreateNew, setFnDialogCreateNew] = useState(true);
    const [showFnDialog, setShowFnDialog] = useState(false);

    const [providers, setProviders] = useState<string[]>([]);
    const [authRequired, setAuthRequired] = useState(false);

    const [spaceId, setSpaceId] = useState<string>("none");
    const { userProfile } = useUserProfile();

    const auth = getAuth(app);

    const [nodes, setNodes, onNodesChange] = useNodesState<OperatorNodeDataType>([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState<BuiltInEdge>([]);
    const [operatorDeclarations, setOperatorDeclarations] = useState([]);

    const [fn, setFn] = useState<Fn | null>(null);
    const [fnDef, setFnDef] = useState<FnDef | null>(new FnDef({}));
    const [fnRun, setFnRun] = useState<FnRun | null>(null);

    const [isRunning, setIsRunning] = useState(false);
    const [isActionFeedVisible, setIsActionFeedVisible] = useState(false);
    const [isEdgePopupVisible, setIsEdgePopupVisible] = useState(false);

    const [lastSaveTime, setLastSaveTime] = useState<Date | null>(null);
    const [saveIndicatorColor, setSaveIndicatorColor] = useState<string>("grey");

    // There are 2 major modes in /function_editor
    // (1) frozen mode when looking at one specific function run that is frozen in time and is non-editable.
    //     GET parameter run_id should be set when opening a specific function run.
    // (2) when actively editing function.
    const [isFrozenMode, setIsFrozenMode] = useState(false);

    // Function editing dropdown menu:
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const formRef = useRef<HTMLFormElement | null>(null);

    const updateDag = (newDag: DagStepType[]) => {
        if (fnDef) {
            const newFnDef = {
                ...fnDef,
                dag: newDag,
            };
            fnDef.update(setFnDef, newFnDef);
        }
    };

    const loadFunction = async (fnId: string) => {
        try {
            if (fnId) {
                const newFn = await Fn.loadFn(fnId);
                if (newFn) {
                    setFn(newFn);
                    setFnDef(new FnDef(newFn.fn_def));
                }
            }
        } catch (error) {
            console.log(error);
            toast.error(`Failed to fetch Function: ${String(error)}`);
        }
    };

    const loadRunAndFn = async (runId: string) => {
        try {
            const { fn: newFn, fnRun: newFnRun } = await FnRun.loadFnAndRun(runId);
            if (newFn) {
                setFn(newFn);
            }
            if (newFnRun) {
                setFnRun(newFnRun);
                // Note that when we open just a function in /function_editor then fnDef comes from fn.fn_def
                // (and that is also the only source of function definition).
                // fnDef is set from fnRun.fn_def however when run_id GET parameter is present in /function_editor.
                setIsRunning(newFnRun.isRunning());
                setFnDef(new FnDef(newFnRun.fn_def));
            }
        } catch (error) {
            console.log(error);
            toast.error(`Failed to fetch Function Run: ${String(error)}`);
        }
    };

    useEffect(() => {
        // console.log(`auto-save useeffect triggerd, fnDef = ${fnDef}`);
        const timer = setTimeout(() => {
            if (fn && fnDef && !isFrozenMode) {
                handleSaveFn(fn, fnDef);
            }
        }, 1500); // adjust delay as needed

        return () => clearTimeout(timer);
    }, [fnDef, fn, isFrozenMode]);

    useEffect(() => {
        // When loading /function_editor page.
        if (userProfile.id_token) {
            fetchOperatorDeclarations(userProfile.id_token);
            const searchParams = new URLSearchParams(window.location.search);
            const fnIdFromParams = searchParams.get("fn_id");
            const runIdFromParams = searchParams.get("run_id");

            if (!fnIdFromParams && !runIdFromParams) {
                setShowFnDialog(true);
                setFnDialogCreateNew(true);
            } else if (fnIdFromParams && !runIdFromParams) {
                loadFunction(fnIdFromParams);
            } else if (runIdFromParams) {
                loadRunAndFn(runIdFromParams);
                setIsFrozenMode(true);
            }
        }
    }, [userProfile.id_token]);

    const handleSaveFn = async (fn: Fn, fnDef: FnDef) => {
        try {
            let createdNewFn = false;
            if (!fn.fn_id) {
                const newFnId = uuidv4();
                fn.fn_id = newFnId;
                createdNewFn = true;
            }

            setCurrentUrlParam("fn_id", fn.fn_id);
            setFn(fn);
            setShowFnDialog(false);

            await fetch_with_auth("fn", userProfile.id_token || "", "POST", {
                ...fn,
                fn_def: fnDef, // Note that fnDef is used as source of truth here.
            });
            if (createdNewFn) {
                // Only show toast when newly created
                toast.success(`Created new function: ${fn.fn_id}`);
            }
            setLastSaveTime(new Date());
            setSaveIndicatorColor("green");
            setTimeout(() => {
                setSaveIndicatorColor("grey");
            }, 2000); // flash green for 1 second

            //window.location.href = `/function_editor?fn_id=${fn.fn_id}`;
        } catch (error) {
            console.error("Failed to save Function:", error);
            toast.error(`Failed to save Function: ${String(error)}`);
        }
    };

    const fetchOperatorDeclarations = async (id_token: string) => {
        try {
            const data = await fetch_with_auth("operator_declarations", id_token, "GET");
            setOperatorDeclarations(data["pipeline_components"]);
        } catch (error: any) {
            console.error(error);
            toast.error(`Error: ${error.message}`);
        }
    };

    const toggleActionFeed = () => {
        if (isEdgePopupVisible) {
            setIsEdgePopupVisible(false);
        }
        setIsActionFeedVisible(!isActionFeedVisible);
    };

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.ctrlKey && event.key === "Enter") {
                event.preventDefault();

                let needsToWaitForBlur = false;
                if (
                    document.activeElement instanceof HTMLElement &&
                    (document.activeElement.tagName === "INPUT" ||
                        document.activeElement.tagName === "TEXTAREA")
                ) {
                    document.activeElement.blur();
                    needsToWaitForBlur = true;
                }

                // Use requestAnimationFrame to wait for the next rendering cycle
                // This ensures React has processed state updates from the blur event
                // This is a hack but it works on practice in low stakes situation:
                // When user pressed ctrl + Enter in /function_editor while having some
                // changes to text parameter that are not saved in global state.
                if (needsToWaitForBlur) {
                    requestAnimationFrame(() => {
                        // By the next animation frame, React should have processed the state update
                        formRef.current?.requestSubmit();
                    });
                } else {
                    // No blur needed, submit immediately
                    formRef.current?.requestSubmit();
                }

                //formRef.current?.requestSubmit();
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    useEffect(() => {
        let interval: ReturnType<typeof setInterval> | null = null;

        if (isRunning) {
            let requestCount = 0;
            let intervalTime = 2000;

            const fetchAndUpdate = async () => {
                try {
                    if (fnRun) {
                        const newFnRun = await FnRun.loadRun(fnRun.run_id);

                        if (newFnRun) {
                            setIsRunning(newFnRun.isRunning());
                            fnRun.update(setFnRun, newFnRun);
                        }
                    }
                } catch (error) {
                    console.log(`fetchAndUpdate failed: ${error}`);
                }

                // Increment the request count and adjust the interval time if necessary
                requestCount++;
                if (requestCount % 3 === 0) {
                    intervalTime += 500;
                }

                // Schedule the next update
                interval = setTimeout(fetchAndUpdate, intervalTime);
            };

            // Start the updates
            fetchAndUpdate();
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [isRunning]);

    const startOrKillFunctionRun = async () => {
        try {
            if (!fn) {
                alert(`Fn not set`);
                return;
            }

            if (!isRunning) {
                // Commit all pending local parameter changes before running
                nodeCommitFunctionsRef.current.forEach((commitFn) => {
                    commitFn();
                });

                const newDag = nodesToDag(nodes, edges);

                updateDag(newDag);
                if (fnDef) {
                    // Note that fnDef is always source of truth in /function_editor
                    fn.fn_def = fnDef;
                }

                const response = await fetch_with_auth(
                    "/start_fn_run",
                    userProfile.id_token || "",
                    "POST",
                    {
                        fn: fn,
                        space_id: spaceId,
                    },
                );

                if (response.insufficient_credit) {
                    toast.dismiss();
                    setShowInsufficientCreditsDialog(true);
                    return;
                }

                const newFnRun = new FnRun(response["fn_run"]);

                // Not using update method because we create new run so it should not retain
                // any fields whatsoever from previous run.
                if (newFnRun) {
                    setFnRun(newFnRun);

                    setCurrentUrlParam("run_id", newFnRun.run_id);

                    toast.dismiss();
                    toast.success(`Function Run started!`);

                    setIsRunning(true);
                } else {
                    toast.error("Failed to start Function run!");
                }
            } else {
                const response = await fetch(`${apiUrl}/try_kill_fn_run`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        user_id: userProfile.user_id,
                        run_id: fnRun ? fnRun.run_id : "",
                    }),
                });

                if (response.ok) {
                    toast.dismiss();
                    toast.success("Function Run queued for termination.");
                } else {
                    const responseData = await response.json();
                    toast.error(`Error: ${responseData.error}`);
                }

                // Note that we are not setting isRunning state to false here because we want the call to /fn_run
                // to learn about termination of the run and show all relevant log to the user. It represents a problem too, e.g.
                // if run died on backend then nobody will ever changes the state of fn run from Running to something else.
            }
        } catch (error) {
            console.log(error);
            toast.dismiss();
            toast.error(String(error));
        }
    };

    const onFormSubmit = async (event: React.FormEvent) => {
        if (!fn || !fnDef) {
            alert(`Fn or FnDef object not set!`);
            return;
        }

        event.preventDefault();

        const requiredProviders = pipelineRequiresAuth(fnDef.dag, auth.currentUser?.providerData);

        // If this Function requires any sort of account linking (twitter, github, etc.) Force the user to sign in.
        if (requiredProviders.length > 0) {
            if (!auth.currentUser) {
                toast.error("This Function requires you to sign in!");
                return;
            }

            setProviders(requiredProviders);
            setAuthRequired(true);
            return;
        }

        startOrKillFunctionRun();
    };

    return (
        <div className={`h-screen w-full overflow-hidden`}>
            {showFnDialog ? (
                <FnDialog
                    fn={fn || new Fn({})}
                    fnDef={fnDef || new FnDef({})}
                    onSave={handleSaveFn}
                    setShowFnDialog={setShowFnDialog}
                    fnDialogCreateNew={fnDialogCreateNew}
                />
            ) : null}
            <div className="z-10 flex h-full min-w-full flex-col space-y-2 overflow-hidden">
                <div className="h-full w-full">
                    <form
                        ref={formRef}
                        onSubmit={onFormSubmit}
                        className="relative flex h-full flex-col space-y-4 overflow-hidden">
                        <AuthPopup authProviders={providers} authRequired={authRequired} />

                        <ReactFlowProvider>
                            <DagDiagram
                                nodes={nodes}
                                setNodes={setNodes}
                                onNodesChange={onNodesChange}
                                edges={edges}
                                setEdges={setEdges}
                                onEdgesChange={onEdgesChange}
                                operatorDeclarations={operatorDeclarations}
                                fnDef={fnDef}
                                nodeCommitFunctionsRef={nodeCommitFunctionsRef}
                                setFnDef={setFnDef}
                                fnRun={fnRun}
                                isFrozenMode={isFrozenMode}
                                isActionFeedVisible={isActionFeedVisible}
                                setIsActionFeedVisible={setIsActionFeedVisible}
                                isEdgePopupVisible={isEdgePopupVisible}
                                setIsEdgePopupVisible={setIsEdgePopupVisible}
                            />
                        </ReactFlowProvider>

                        <div
                            className="absolute right-0 top-0 
                                       flex w-1/5 
                                       flex-col items-end space-y-3 
                                       p-2 text-sm"
                            style={{ zIndex: 20 }}>
                            <div className="flex w-full flex-col space-y-2">
                                <TextField
                                    label="Function Name"
                                    value={fn?.name || ""}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />

                                {!isFrozenMode && (
                                    <div style={{ color: saveIndicatorColor }}>
                                        {lastSaveTime
                                            ? `Saved at ${lastSaveTime.toLocaleTimeString()}`
                                            : "Not yet saved"}
                                    </div>
                                )}

                                {!isFrozenMode && (
                                    <Button
                                        variant="contained"
                                        size="small"
                                        onClick={() => {
                                            setShowFnDialog(true);
                                            setFnDialogCreateNew(false);
                                        }}>
                                        Edit Function Details
                                    </Button>
                                )}
                                {isFrozenMode && (
                                    <div>
                                        <Button variant="contained" onClick={handleMenuClick}>
                                            Start Editing
                                        </Button>
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleMenuClose}>
                                            {true && fn && (
                                                <>
                                                    <MenuItem
                                                        onClick={() => {
                                                            window.location.href = `/function_editor?fn_id=${fn.fn_id}`;
                                                            handleMenuClose();
                                                        }}>
                                                        Edit Most Recent Function Configuration
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={async () => {
                                                            if (fn && fnRun) {
                                                                const updatedFn = new Fn({
                                                                    ...fn,
                                                                    fn_def: fnRun.fn_def,
                                                                    last_run_id: fnRun.run_id,
                                                                });
                                                                await handleSaveFn(
                                                                    updatedFn,
                                                                    fnRun.fn_def,
                                                                );
                                                                toast.success(
                                                                    "Function configuration reverted to current run. Redirecting to function editor.",
                                                                    { duration: 5000 },
                                                                );
                                                            }
                                                            window.location.href = `/function_editor?fn_id=${fn.fn_id}`;
                                                            handleMenuClose();
                                                        }}>
                                                        Revert to Current Run Configuration
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={() => {
                                                            setShowFnDialog(true);
                                                            setFnDialogCreateNew(false);
                                                            handleMenuClose();
                                                        }}>
                                                        Edit Function Details
                                                    </MenuItem>
                                                </>
                                            )}
                                            <MenuItem
                                                onClick={() => {
                                                    setFnDialogCreateNew(true);
                                                    setShowFnDialog(true);
                                                    handleMenuClose();
                                                }}>
                                                Save as New Function
                                            </MenuItem>
                                        </Menu>
                                    </div>
                                )}
                            </div>
                            {!isFrozenMode && (
                                <RunSettings
                                    userProfile={userProfile}
                                    fnDef={fnDef}
                                    setFnDef={setFnDef}
                                    spaceId={spaceId}
                                    setSpaceId={setSpaceId}
                                />
                            )}
                            {!isFrozenMode && (
                                <Button
                                    className="w-full"
                                    variant="contained"
                                    size="small"
                                    type="submit"
                                    sx={{
                                        fontWeight: "bold",
                                        fontSize: "20px",
                                        /*color: isRunning
                                            ? `${colors.redAccent[500]} !important`
                                            : `${colors.greenAccent[500]} !important`,
                                        background: isRunning
                                            ? `${colors.redAccent[900]} !important`
                                            : `${colors.greenAccent[900]} !important`,
                                        "&:hover": {
                                            backgroundColor: isRunning
                                                ? `${colors.redAccent[800]} !important`
                                                : `${colors.greenAccent[800]} !important`,
                                        },*/
                                        color: isRunning ? `red !important` : `green !important`,
                                        background: isRunning
                                            ? `lightred !important`
                                            : `lightgreen !important`,
                                        "&:hover": {
                                            color: isRunning
                                                ? `red !important`
                                                : `green !important`,
                                        },
                                    }}>
                                    {isRunning
                                        ? "Kill Function Run"
                                        : "Run Function (ctrl + enter)"}
                                </Button>
                            )}
                        </div>
                    </form>
                </div>

                <div
                    id="action-feed-button"
                    className="fixed z-40"
                    style={{
                        top: "10px",
                        right: "14%", // Always positioned at the feed boundary
                        transform: "translateX(50%)", // Center the button on the boundary
                        transition: "all 0.3s ease",
                    }}>
                    <Button
                        variant="contained"
                        onClick={toggleActionFeed}
                        size="small"
                        sx={{
                            borderTopRightRadius: isActionFeedVisible ? 0 : 4,
                            borderBottomRightRadius: isActionFeedVisible ? 0 : 4,
                            minWidth: 0,
                            padding: "6px 12px",
                        }}>
                        {isActionFeedVisible ? "Close Feed" : "Output Feed"}
                    </Button>
                </div>
            </div>

            {isActionFeedVisible && (
                <div
                    id="action-feed-container"
                    className="absolute right-0 top-0 h-full w-full overflow-auto bg-white p-4 pt-0 shadow-lg md:w-1/3"
                    style={{ zIndex: 30 }}>
                    <ActionFeed fnRun={fnRun} isRunning={isRunning} />
                </div>
            )}
            <InsufficientCreditDialog
                open={showInsufficientCreditsDialog}
                onClose={() => setShowInsufficientCreditsDialog(false)}
            />
        </div>
    );
};

export default FunctionEditorPage;
