import { useState } from "react";

import BuildIcon from "@mui/icons-material/Build";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import ShareIcon from "@mui/icons-material/Share";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";

import { useUserProfile } from "../context/UserProfileContext";
import { OnboardingDialog } from "../components/onboarding";
import FeatureBox from "../components/FeatureBox";

function HomePage() {
    const { userProfile } = useUserProfile();
    const [showOnboarding, setShowOnboarding] = useState(false);

    const handleGetStartedButtonClick = () => {
        setShowOnboarding(true);
    };

    return (
        <div className="home-page-container flex min-h-screen min-w-full justify-center">
            <div className="flex min-w-full flex-col items-center space-y-6 pt-10">
                <OnboardingDialog open={showOnboarding} onClose={() => setShowOnboarding(false)} />

                <div className="flex flex-col space-y-6">
                    <div className="flex flex-col items-center justify-center space-y-3 text-3xl md:text-5xl">
                        <h1 className="font-thin">
                            <span style={{ color: "lightblue" }}>CRM you can talk to</span>
                        </h1>
                    </div>
                    <div className="flex justify-center text-xs md:text-[14px]">
                        <div className="w-[350px] space-y-2 md:w-[600px]">
                            <h2 className="text-center">
                                Use plain English for lead generation and enrichment
                            </h2>
                        </div>
                    </div>
                </div>

                <div className="mt-4 flex justify-center">
                    <Button
                        onClick={handleGetStartedButtonClick}
                        style={{
                            backgroundColor: "green",
                        }}
                        sx={{
                            border: `1px solid`,
                            color: "white",
                            "&:hover": {
                                backgroundColor: "green",
                                cursor: "pointer",
                            },
                        }}>
                        Start getting Leads for Free
                        <NorthEastIcon />
                    </Button>
                </div>

                {/* YouTube Video Section */}
                <div className="mt-8 flex w-full justify-center">
                    <div className="w-full max-w-4xl px-4">
                        <Card
                            style={{
                                borderRadius: 8,
                                border: "1px solid black",
                                boxShadow: "0px 10px 20px rgba(0,0,0,0.1)",
                                marginBottom: "30px",
                                overflow: "hidden",
                            }}>
                            <div
                                className="relative"
                                style={{ paddingTop: "56.25%" /* 16:9 Aspect Ratio */ }}>
                                <iframe
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        border: "none",
                                    }}
                                    src="https://www.youtube.com/embed/AHDrtCS0dBk"
                                    title="Spesh AI Demo"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                            </div>
                        </Card>
                    </div>
                </div>

                {/* Feature Boxes Section - kept as requested */}
                <div className="flex w-3/4 flex-col justify-between md:flex-row">
                    <FeatureBox
                        IconComponent={BuildIcon}
                        color="blue"
                        title="Find Leads"
                        description="Use plain English to describe leads you are looking for"
                    />
                    <FeatureBox
                        IconComponent={RocketLaunchIcon}
                        color="blue"
                        title="Quick Iteration"
                        description="Get results instantly and iterate quickly to identify best Prospects"
                    />
                    <FeatureBox
                        IconComponent={ShareIcon}
                        color="blue"
                        title="Enrich"
                        description="Enrich prospects using our toolchain, both with AI and by traditional methods"
                    />
                </div>
                <div className="min-h-[10px]">{/* Spacing between sections */}</div>
                <div>
                    {/* Top images centered */}
                    <div className="mt-8 flex w-full flex-col items-center justify-center">
                        {/* First image - chat screenshot (wide but not tall) */}
                        <div className="w-full max-w-4xl px-4">
                            <Card
                                style={{
                                    borderRadius: 8,
                                    border: "1px solid black",
                                    boxShadow: "0px 10px 20px rgba(0,0,0,0.1)",
                                    marginBottom: "30px",
                                }}>
                                <CardMedia
                                    component="img"
                                    image="images/chat_screenshot.png"
                                    alt="Chat Interface"
                                />
                            </Card>
                        </div>

                        {/* Second image - output screenshot */}
                        <div className="w-full max-w-4xl px-4">
                            <Card
                                style={{
                                    borderRadius: 8,
                                    border: "1px solid black",
                                    boxShadow: "0px 10px 20px rgba(0,0,0,0.1)",
                                    marginBottom: "30px",
                                }}>
                                <CardMedia
                                    component="img"
                                    image="images/output_screenshot.png"
                                    alt="Output Example"
                                />
                            </Card>
                        </div>

                        {/* third image - rigt click menu - find email */}
                        <div className="w-full max-w-4xl px-4">
                            <Card
                                style={{
                                    borderRadius: 8,
                                    border: "1px solid black",
                                    boxShadow: "0px 10px 20px rgba(0,0,0,0.1)",
                                }}>
                                <CardMedia
                                    component="img"
                                    image="images/right_click_menu_showing_find_email.png"
                                    alt="Per Row Function Menu"
                                />
                            </Card>
                        </div>
                    </div>
                </div>

                <div className="min-h-[10px]">{/* Spacing between sections */}</div>

                {/* Call to action button */}
                <div className="content-middle flex w-full justify-center">
                    <Button
                        onClick={handleGetStartedButtonClick}
                        style={{
                            backgroundColor: "green",
                        }}
                        sx={{
                            border: `1px solid`,
                            color: "white",
                            "&:hover": {
                                backgroundColor: "green",
                                cursor: "pointer",
                            },
                        }}>
                        Start getting Leads for Free
                        <NorthEastIcon />
                    </Button>
                </div>

                {/* Simple footer */}
                <div className="mt-16 w-2/3 border-t border-gray-300"></div>
                <div className="mb-8 text-center text-sm">
                    <p>© 2025 Spesh AI. All rights reserved.</p>
                </div>
            </div>
        </div>
    );
}

export default HomePage;
