import { useEffect, useState } from "react";

import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Link,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import { Link as RouterLink } from "react-router-dom";
import { Ring } from "@uiball/loaders";
import DeleteIcon from "@mui/icons-material/Delete";
import toast from "react-hot-toast";

import Header from "../components/Header";
import { fetch_with_auth } from "../components/Util";
import { Fn } from "../types/Fn";
import { useUserProfile } from "../context/UserProfileContext";

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 900,
    },
});

const formatDateToLocal = (date: Date | null) => {
    if (date) {
        try {
            return new Intl.DateTimeFormat("default", {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                timeZoneName: "short",
            }).format(date);
        } catch (e) {
            console.error("Invalid date value:", date);
            return "N/A";
        }
    } else {
        return "N/A";
    }
};

const FunctionsPage = () => {
    const { userProfile } = useUserProfile();
    const [fns, setFns] = useState<Fn[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [functionToDelete, setFunctionToDelete] = useState<Fn | null>(null);

    const fetchFunctions = async (id_token: string) => {
        setIsLoading(true);
        try {
            const data = await fetch_with_auth("fn", id_token, "GET");
            const newFns = data.map((element: any) => new Fn(element));
            setFns(newFns);
            setIsLoading(false);
        } catch (error) {
            toast.error(
                `Error fetching functions: ${error instanceof Error ? error.message : String(error)}`,
            );
            setIsLoading(false);
        }
    };

    const handleDeleteClick = (fn: Fn) => {
        setFunctionToDelete(fn);
        setDeleteDialogOpen(true);
    };

    const handleDeleteCancel = () => {
        setDeleteDialogOpen(false);
        setFunctionToDelete(null);
    };

    const handleDeleteConfirm = async () => {
        if (!functionToDelete) return;

        try {
            await functionToDelete.delete_from_db(userProfile.id_token || "");
            setFns((prevFns) => prevFns.filter((fn) => fn.fn_id !== functionToDelete.fn_id));
            toast.success(`Function "${functionToDelete.name}" was deleted successfully`);
        } catch (error) {
            toast.error(
                `Error deleting function: ${error instanceof Error ? error.message : String(error)}`,
            );
        } finally {
            setDeleteDialogOpen(false);
            setFunctionToDelete(null);
        }
    };

    useEffect(() => {
        if (userProfile.id_token) {
            fetchFunctions(userProfile.id_token);
        }
    }, [userProfile.id_token]);

    return (
        <div className="min-w-full">
            <Header title="Functions" subtitle="List of all available functions"></Header>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Function ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Author</TableCell>
                            <TableCell>Created</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {isLoading ? (
                            <TableRow>
                                <TableCell colSpan={6} align="center">
                                    <Ring size={25} lineWeight={5} speed={2} color="black" />
                                </TableCell>
                            </TableRow>
                        ) : (
                            fns.map((fn, index) => (
                                <TableRow key={index} sx={{ height: "auto" }}>
                                    <TableCell>{fn.fn_id}</TableCell>
                                    <TableCell>
                                        <Link
                                            to={`/function_editor?fn_id=${fn.fn_id}`}
                                            component={RouterLink}
                                            rel="noreferrer"
                                            sx={{
                                                textDecoration: "underline",
                                                //color: colors.blueAccent[500],
                                            }}>
                                            {fn.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{fn.description}</TableCell>
                                    <TableCell>{fn.author_name}</TableCell>
                                    <TableCell>
                                        {formatDateToLocal(
                                            fn.created_ts ? new Date(fn.created_ts) : null,
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            aria-label="delete"
                                            onClick={() => handleDeleteClick(fn)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog
                open={deleteDialogOpen}
                onClose={handleDeleteCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Deleting a function is not possible to reverse and it will no longer be
                        available in all spaces where it is used. Are you sure you want to delete
                        function "{functionToDelete?.name}"?
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Button onClick={handleDeleteConfirm} color="error" variant="contained">
                        Delete
                    </Button>
                    <Button
                        onClick={handleDeleteCancel}
                        color="inherit"
                        variant="contained"
                        autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default FunctionsPage;
