import React, { useState } from "react";

import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    TextField,
    Typography,
    Box,
    Divider,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import toast from "react-hot-toast";
import ReactJson from "@microlink/react-json-view";

import Header from "../components/Header";
import { Team } from "../types/Team";
import { useUserProfile } from "../context/UserProfileContext";
import EmailChipInput from "../components/EmailChipInput";

const TeamPage: React.FC = () => {
    const { userProfile } = useUserProfile();
    const [teams, setTeams] = useState<Team[]>([]);
    const [editedTeams, setEditedTeams] = useState<Record<string, Team>>({});
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [newTeamDescription, setNewTeamDescription] = useState("");
    const [openDeleteDialog, setOpenDeleteDialog] = useState<string | null>(null);
    const isAdmin = !!userProfile.user_email && userProfile.user_email.endsWith("@spesh.ai");
    const [expandedTeams, setExpandedTeams] = useState<Record<string, boolean>>({});

    // Load teams once when the component mounts
    React.useEffect(() => {
        if (userProfile.id_token) {
            Team.get_teams_for_user(userProfile.id_token).then((loadedTeams) => {
                setTeams(loadedTeams);
                // Initialize edited teams with copies of the loaded teams
                const initialEditedTeams: Record<string, Team> = {};
                const initialExpandedTeams: Record<string, boolean> = {};

                loadedTeams.forEach((team) => {
                    initialEditedTeams[team.id] = new Team({ ...team });
                    // For non-admins, all teams should be expanded by default
                    initialExpandedTeams[team.id] = !isAdmin;
                });

                setEditedTeams(initialEditedTeams);
                setExpandedTeams(initialExpandedTeams);
            });
        }
    }, [userProfile.id_token, isAdmin]);

    const hasUnsavedChanges = (teamId: string) => {
        const originalTeam = teams.find((t) => t.id === teamId);
        const editedTeam = editedTeams[teamId];

        if (!originalTeam || !editedTeam) return false;

        // Compare fields that can be edited
        return (
            originalTeam.description !== editedTeam.description ||
            originalTeam.credit_left !== editedTeam.credit_left ||
            JSON.stringify(originalTeam.members) !== JSON.stringify(editedTeam.members) ||
            JSON.stringify(originalTeam.admins) !== JSON.stringify(editedTeam.admins) ||
            originalTeam.state !== editedTeam.state ||
            originalTeam.credit_package !== editedTeam.credit_package ||
            originalTeam.credit_cost_usd !== editedTeam.credit_cost_usd ||
            originalTeam.billing_rate_usd !== editedTeam.billing_rate_usd ||
            originalTeam.billing_period_months !== editedTeam.billing_period_months
        );
    };

    const handleCreateTeam = async () => {
        try {
            const newTeam = await Team.create_new(userProfile.id_token || "", newTeamDescription);
            setTeams([...teams, newTeam]);
            setEditedTeams({
                ...editedTeams,
                [newTeam.id]: new Team({ ...newTeam }),
            });
            setOpenCreateDialog(false);
            setNewTeamDescription("");
            toast.success("Team created successfully");
        } catch (error: any) {
            console.error(error);
            toast.error(`Error: ${error.message}`);
        }
    };

    const handleSaveTeam = async (teamId: string) => {
        try {
            const updatedTeam = await Team.update(userProfile.id_token || "", editedTeams[teamId]);

            // Update the teams array with the updated team
            setTeams(teams.map((team) => (team.id === teamId ? updatedTeam : team)));

            // Update the editedTeams with the updated team
            setEditedTeams({
                ...editedTeams,
                [teamId]: new Team({ ...updatedTeam }),
            });

            toast.success("Team updated successfully");
        } catch (error: any) {
            console.error(error);
            toast.error(`Error: ${error.message}`);
        }
    };

    const handleDeleteTeam = async (teamId: string) => {
        try {
            await Team.delete_team(userProfile.id_token || "", teamId);

            // Remove the team from the teams array
            setTeams(teams.filter((team) => team.id !== teamId));

            // Remove the team from editedTeams
            const newEditedTeams = { ...editedTeams };
            delete newEditedTeams[teamId];
            setEditedTeams(newEditedTeams);

            setOpenDeleteDialog(null);
            toast.success("Team deleted successfully");
        } catch (error: any) {
            console.error(error);
            toast.error(`Error: ${error.message}`);
        }
    };

    const toggleTeamExpansion = (teamId: string) => {
        // Only allow toggling if user is admin or there are multiple teams
        if (isAdmin || teams.length > 1) {
            setExpandedTeams((prev) => ({
                ...prev,
                [teamId]: !prev[teamId],
            }));
        }
    };

    const handleFieldChange = (teamId: string, field: keyof Team, value: any) => {
        setEditedTeams({
            ...editedTeams,
            [teamId]: new Team({
                ...editedTeams[teamId],
                [field]: value,
            }),
        });
    };

    const handleJsonChange = (teamId: string, edit: { updated_src: any }) => {
        setEditedTeams({
            ...editedTeams,
            [teamId]: new Team({
                ...editedTeams[teamId],
                ...edit.updated_src,
            }),
        });
    };

    return (
        <Container className="team-page-container min-h-screen min-w-full">
            <Header
                title={isAdmin ? "Teams" : "My Subscription"}
                subtitle={
                    isAdmin
                        ? "Manage all team settings, members, and billing information"
                        : "Manage your subscription"
                }
            />

            {isAdmin && (
                <Box display="flex" justifyContent="flex-end" mb={2}>
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => setOpenCreateDialog(true)}>
                        Create New Team
                    </Button>
                </Box>
            )}

            {teams.map((team) => (
                <Card
                    key={team.id}
                    sx={{
                        marginBottom: 2,
                        cursor: isAdmin || teams.length > 1 ? "pointer" : "default",
                    }}>
                    <CardHeader
                        onClick={() => toggleTeamExpansion(team.id)}
                        sx={{ cursor: isAdmin || teams.length > 1 ? "pointer" : "default" }}
                        title={
                            <Box display="flex" alignItems="center">
                                <Typography variant="h6">
                                    {/* Only show collapse/expand indicators if user is admin or there are multiple teams */}
                                    {(isAdmin || teams.length > 1) && (
                                        <span>{expandedTeams[team.id] ? "▼" : "►"} </span>
                                    )}
                                    Team: {team.id}
                                </Typography>
                            </Box>
                        }
                        subheader={
                            <Box>
                                <Typography variant="body2">
                                    {team.description || "No description"}
                                </Typography>
                                <Typography variant="caption">
                                    Created: {team.created_date.toLocaleDateString()}
                                </Typography>
                                {!expandedTeams[team.id] && (
                                    <Typography variant="body2" sx={{ mt: 1 }}>
                                        Credits: {team.credit_left} | Members: {team.members.length}{" "}
                                        | Admins: {team.admins.length}
                                    </Typography>
                                )}
                            </Box>
                        }
                    />

                    {expandedTeams[team.id] && (
                        <>
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Team ID"
                                            value={editedTeams[team.id]?.id || team.id}
                                            disabled
                                            margin="normal"
                                        />

                                        <TextField
                                            fullWidth
                                            label="Description"
                                            value={
                                                editedTeams[team.id]?.description ||
                                                team.description
                                            }
                                            onChange={(e) =>
                                                handleFieldChange(
                                                    team.id,
                                                    "description",
                                                    e.target.value,
                                                )
                                            }
                                            margin="normal"
                                        />

                                        {isAdmin ? (
                                            <TextField
                                                fullWidth
                                                label="Credits Left"
                                                type="number"
                                                value={
                                                    editedTeams[team.id]?.credit_left ||
                                                    team.credit_left
                                                }
                                                onChange={(e) =>
                                                    handleFieldChange(
                                                        team.id,
                                                        "credit_left",
                                                        parseInt(e.target.value),
                                                    )
                                                }
                                                margin="normal"
                                            />
                                        ) : (
                                            <Box mt={2} mb={2}>
                                                <Typography variant="subtitle1">
                                                    Credits Left:
                                                </Typography>
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        color: "success.main",
                                                        fontWeight: "bold",
                                                    }}>
                                                    {team.credit_left}
                                                </Typography>
                                                <Typography variant="body2">
                                                    Credits used this period:{" "}
                                                    {team.credit_used_this_period}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Typography variant="h6" gutterBottom>
                                            Members
                                        </Typography>
                                        <EmailChipInput
                                            emails={editedTeams[team.id]?.members || team.members}
                                            setEmails={(emails) =>
                                                handleFieldChange(team.id, "members", emails)
                                            }
                                            label="Team Members"
                                            placeholder="Add team members (press Enter to add)"
                                        />

                                        <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                                            Admins
                                        </Typography>
                                        <EmailChipInput
                                            emails={editedTeams[team.id]?.admins || team.admins}
                                            setEmails={(emails) =>
                                                handleFieldChange(team.id, "admins", emails)
                                            }
                                            label="Team Admins"
                                            placeholder="Add team admins (press Enter to add)"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Divider sx={{ my: 2 }} />
                                        <Typography variant="h6" gutterBottom>
                                            Advanced Settings
                                        </Typography>
                                        {isAdmin ? (
                                            <Box sx={{ maxHeight: 400, overflow: "auto" }}>
                                                <ReactJson
                                                    src={{
                                                        state:
                                                            editedTeams[team.id]?.state ||
                                                            team.state,
                                                        credit_package:
                                                            editedTeams[team.id]?.credit_package ||
                                                            team.credit_package,
                                                        credit_cost_usd:
                                                            editedTeams[team.id]?.credit_cost_usd ||
                                                            team.credit_cost_usd,
                                                        billing_rate_usd:
                                                            editedTeams[team.id]
                                                                ?.billing_rate_usd ||
                                                            team.billing_rate_usd,
                                                        billing_period_months:
                                                            editedTeams[team.id]
                                                                ?.billing_period_months ||
                                                            team.billing_period_months,
                                                        billing_period_end:
                                                            editedTeams[team.id]
                                                                ?.billing_period_end ||
                                                            team.billing_period_end,
                                                        credit_used_this_period:
                                                            editedTeams[team.id]
                                                                ?.credit_used_this_period ||
                                                            team.credit_used_this_period,
                                                        paid_since_date:
                                                            editedTeams[team.id]?.paid_since_date ||
                                                            team.paid_since_date,
                                                        cancelled_date:
                                                            editedTeams[team.id]?.cancelled_date ||
                                                            team.cancelled_date,
                                                    }}
                                                    onEdit={(edit) =>
                                                        handleJsonChange(team.id, edit)
                                                    }
                                                    displayDataTypes={false}
                                                    displayObjectSize={false}
                                                    enableClipboard={false}
                                                />
                                            </Box>
                                        ) : (
                                            <Box>
                                                <table className="w-full border-collapse">
                                                    <tbody>
                                                        <tr>
                                                            <td className="p-2 font-bold">
                                                                Subscription Status:
                                                            </td>
                                                            <td className="p-2">{team.state}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="p-2 font-bold">
                                                                Billing Rate:
                                                            </td>
                                                            <td className="p-2">
                                                                {team.billing_rate_usd
                                                                    ? `$${team.billing_rate_usd.toFixed(2)}`
                                                                    : "N/A"}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="p-2 font-bold">
                                                                Billing Period:
                                                            </td>
                                                            <td className="p-2">
                                                                {team.billing_period_months
                                                                    ? `${team.billing_period_months} months`
                                                                    : "N/A"}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="p-2 font-bold">
                                                                Billing Period End:
                                                            </td>
                                                            <td className="p-2">
                                                                {team.billing_period_end
                                                                    ? team.billing_period_end.toLocaleDateString()
                                                                    : "N/A"}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Box>
                                        )}
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions sx={{ justifyContent: "flex-end", p: 2 }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<SaveIcon />}
                                    onClick={() => handleSaveTeam(team.id)}
                                    disabled={!hasUnsavedChanges(team.id)}>
                                    Save Changes
                                </Button>
                                {isAdmin && (
                                    <Button
                                        variant="contained"
                                        color="error"
                                        startIcon={<DeleteIcon />}
                                        onClick={() => setOpenDeleteDialog(team.id)}>
                                        Delete Team
                                    </Button>
                                )}
                            </CardActions>
                        </>
                    )}
                </Card>
            ))}

            {/* Create Team Dialog */}
            <Dialog
                open={openCreateDialog}
                onClose={() => setOpenCreateDialog(false)}
                aria-labelledby="create-team-dialog-title">
                <DialogTitle id="create-team-dialog-title">Create New Team</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter a description for the new team. You will be automatically added as an
                        admin.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="team-description"
                        label="Team Description"
                        type="text"
                        fullWidth
                        value={newTeamDescription}
                        onChange={(e) => setNewTeamDescription(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenCreateDialog(false)} startIcon={<CancelIcon />}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleCreateTeam}
                        variant="contained"
                        color="primary"
                        startIcon={<SaveIcon />}>
                        Create
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Team Dialog */}
            <Dialog
                open={openDeleteDialog !== null}
                onClose={() => setOpenDeleteDialog(null)}
                aria-labelledby="delete-team-dialog-title">
                <DialogTitle id="delete-team-dialog-title" color="error">
                    Delete Team
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to nuke the team?</DialogContentText>
                    {openDeleteDialog && (
                        <>
                            <Typography variant="subtitle1">
                                <strong>ID:</strong>{" "}
                                {teams.find((t) => t.id === openDeleteDialog)?.id}
                            </Typography>
                            <Typography variant="subtitle1">
                                <strong>Description:</strong>{" "}
                                {teams.find((t) => t.id === openDeleteDialog)?.description}
                            </Typography>
                            <Typography variant="subtitle1">
                                <strong>Members:</strong>{" "}
                                {teams.find((t) => t.id === openDeleteDialog)?.members.join(", ")}
                            </Typography>
                            <Typography variant="subtitle1">
                                <strong>Admins:</strong>{" "}
                                {teams.find((t) => t.id === openDeleteDialog)?.admins.join(", ")}
                            </Typography>
                        </>
                    )}
                    <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                        This action cannot be undone.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="error"
                        startIcon={<DeleteIcon />}
                        onClick={() => openDeleteDialog && handleDeleteTeam(openDeleteDialog)}>
                        Delete
                    </Button>
                    <Button
                        variant="outlined"
                        startIcon={<CancelIcon />}
                        onClick={() => setOpenDeleteDialog(null)}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default TeamPage;
