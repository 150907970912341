import React, { useEffect, useState } from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import {
    Button,
    Checkbox,
    Container,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Paper,
    TextField,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Autocomplete from "@mui/material/Autocomplete";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { fetch_with_auth } from "../components/Util";
import { Fn } from "../types/Fn";
import { Space } from "../types/Space";
import { useUserProfile } from "../context/UserProfileContext";
import EmailChipInput from "../components/EmailChipInput";

const SpaceEditorPage = () => {
    const { userProfile } = useUserProfile();
    const navigate = useNavigate();
    const [openDeleteDialog, setOpenDeleteDialog] = useState<string | null>(null);
    const [spaces, setSpaces] = useState<Space[]>([]);
    const [editedSpaces, setEditedSpaces] = useState<Record<string, Space>>({});
    const [fns, setFns] = useState<Fn[]>([]);
    const [isCreatingNewSpace, setIsCreatingNewSpace] = useState(false);
    const [newSpace, setNewSpace] = useState<Space>(
        new Space({
            name: "",
            description: "",
            authz: { is_public: false, emails: [], teams: [] },
            author_id: "",
            functions: {},
            table_md: {},
            table_type: {},
        }),
    );

    // Check if user is an admin
    const isAdmin = !!userProfile.user_email && userProfile.user_email.endsWith("@spesh.ai");

    // Redirect if user is not an admin
    useEffect(() => {
        if (!isAdmin && userProfile.user_email) {
            toast.error("You don't have permission to access the Space Editor");
            navigate("/");
        }
    }, [isAdmin, userProfile.user_email, navigate]);

    // Load all spaces and functions when component mounts
    useEffect(() => {
        if (userProfile.id_token) {
            loadAllSpaces();
            loadAllFunctions();
        }
    }, [userProfile.id_token]);

    const loadAllSpaces = async () => {
        if (!userProfile.id_token) return;

        try {
            const spacesData = await Space.get_spaces_for_user(userProfile.id_token);
            setSpaces(spacesData);

            // Initialize edited spaces
            const editedSpacesCopy: Record<string, Space> = {};
            spacesData.forEach((space) => {
                editedSpacesCopy[space.space_id] = new Space({ ...space });
            });
            setEditedSpaces(editedSpacesCopy);
        } catch (error: any) {
            console.error(error);
            toast.error(`Error loading spaces: ${error.message}`);
        }
    };

    const loadAllFunctions = async () => {
        if (!userProfile.id_token) return;

        try {
            const functionsData = await fetch_with_auth(`fn`, userProfile.id_token, "GET");
            // Make sure we convert raw data to Fn objects
            setFns(functionsData.map((fnData: any) => new Fn(fnData)));
        } catch (error: any) {
            console.error(error);
            toast.error(`Error loading functions: ${error.message}`);
        }
    };

    const handleSaveSpace = async (spaceId: string) => {
        const spaceToSave = spaceId ? editedSpaces[spaceId] : newSpace;

        if (!spaceToSave) {
            toast.error("Space not found");
            return;
        }

        toast.loading(`${spaceId ? "Updating" : "Creating"} space...`);

        try {
            spaceToSave.author_id = userProfile.user_id;
            spaceToSave.author_name = userProfile.display_name || "Anonymous";

            const res = await spaceToSave.save_to_db(userProfile.id_token || "");
            const savedSpaceId = res.space_id;

            toast.dismiss();
            toast.success(`Space ${spaceId ? "updated" : "created"} successfully`);

            // Refresh all spaces to get the latest data
            await loadAllSpaces();

            if (!spaceId) {
                // Reset the new space form
                setIsCreatingNewSpace(false);
                setNewSpace(
                    new Space({
                        name: "",
                        description: "",
                        authz: { is_public: false, emails: [], teams: [] },
                        author_id: "",
                        functions: {},
                        table_md: {},
                        table_type: {},
                    }),
                );
            }
        } catch (error: any) {
            toast.dismiss();
            toast.error(`Failed to ${spaceId ? "update" : "create"} space: ${error.message}`);
        }
    };

    const handleDeleteSpace = async () => {
        if (!openDeleteDialog) return;

        toast.loading("Deleting space...");

        try {
            await Space.delete_from_db(openDeleteDialog, userProfile.id_token || "");

            toast.dismiss();
            toast.success("Space deleted successfully");

            // Refresh all spaces to get the latest data
            await loadAllSpaces();
        } catch (error: any) {
            toast.dismiss();
            toast.error(`Failed to delete space: ${error.message}`);
        }

        setOpenDeleteDialog(null);
    };

    const handleAddFunction = (spaceId: string) => {
        if (spaceId) {
            const updatedSpace = new Space({
                ...editedSpaces[spaceId],
                functions: { ...editedSpaces[spaceId].functions, "": "" },
            });

            setEditedSpaces({
                ...editedSpaces,
                [spaceId]: updatedSpace,
            });
        } else {
            setNewSpace(
                new Space({
                    ...newSpace,
                    functions: { ...newSpace.functions, "": "" },
                }),
            );
        }
    };

    const handleRemoveFunction = (spaceId: string, functionName: string) => {
        if (spaceId) {
            const { [functionName]: removed, ...remainingFunctions } =
                editedSpaces[spaceId].functions;

            const updatedSpace = new Space({
                ...editedSpaces[spaceId],
                functions: remainingFunctions,
            });

            setEditedSpaces({
                ...editedSpaces,
                [spaceId]: updatedSpace,
            });
        } else {
            const { [functionName]: removed, ...remainingFunctions } = newSpace.functions;

            setNewSpace(
                new Space({
                    ...newSpace,
                    functions: remainingFunctions,
                }),
            );
        }
    };

    const handleFunctionNameChange = (
        spaceId: string,
        oldFunctionName: string,
        newFunctionName: string,
    ) => {
        let currentSpace: Space;
        let setterFunction: (updatedSpace: Space) => void;

        if (spaceId) {
            currentSpace = editedSpaces[spaceId];
            setterFunction = (updatedSpace: Space) => {
                setEditedSpaces({
                    ...editedSpaces,
                    [spaceId]: updatedSpace,
                });
            };
        } else {
            currentSpace = newSpace;
            setterFunction = setNewSpace;
        }

        const newFunctions = { ...currentSpace.functions };
        newFunctions[newFunctionName] = newFunctions[oldFunctionName];
        delete newFunctions[oldFunctionName];

        setterFunction(
            new Space({
                ...currentSpace,
                functions: newFunctions,
            }),
        );
    };

    const handleFunctionChange = (
        spaceId: string,
        functionName: string,
        selectedFunction: Fn | null,
    ) => {
        if (spaceId) {
            const updatedSpace = new Space({
                ...editedSpaces[spaceId],
                functions: {
                    ...editedSpaces[spaceId].functions,
                    [functionName]: selectedFunction ? selectedFunction.fn_id : "",
                },
            });

            setEditedSpaces({
                ...editedSpaces,
                [spaceId]: updatedSpace,
            });
        } else {
            setNewSpace(
                new Space({
                    ...newSpace,
                    functions: {
                        ...newSpace.functions,
                        [functionName]: selectedFunction ? selectedFunction.fn_id : "",
                    },
                }),
            );
        }
    };

    const handleFieldChange = (spaceId: string, field: keyof Space, value: any) => {
        if (spaceId) {
            const updatedSpace = new Space({
                ...editedSpaces[spaceId],
                [field]: value,
            });

            setEditedSpaces({
                ...editedSpaces,
                [spaceId]: updatedSpace,
            });
        } else {
            setNewSpace(
                new Space({
                    ...newSpace,
                    [field]: value,
                }),
            );
        }
    };

    const handleAuthzFieldChange = (spaceId: string, field: keyof Space["authz"], value: any) => {
        if (spaceId) {
            const updatedSpace = new Space({
                ...editedSpaces[spaceId],
                authz: {
                    ...editedSpaces[spaceId].authz,
                    [field]: value,
                },
            });

            setEditedSpaces({
                ...editedSpaces,
                [spaceId]: updatedSpace,
            });
        } else {
            setNewSpace(
                new Space({
                    ...newSpace,
                    authz: {
                        ...newSpace.authz,
                        [field]: value,
                    },
                }),
            );
        }
    };

    const hasUnsavedChanges = (spaceId: string): boolean => {
        const originalSpace = spaces.find((s) => s.space_id === spaceId);
        const editedSpace = editedSpaces[spaceId];

        if (!originalSpace || !editedSpace) return false;

        // Compare fields that can be edited
        return (
            originalSpace.name !== editedSpace.name ||
            originalSpace.description !== editedSpace.description ||
            originalSpace.authz.is_public !== editedSpace.authz.is_public ||
            JSON.stringify(originalSpace.authz.emails) !==
                JSON.stringify(editedSpace.authz.emails) ||
            JSON.stringify(originalSpace.authz.teams) !== JSON.stringify(editedSpace.authz.teams) ||
            JSON.stringify(originalSpace.functions) !== JSON.stringify(editedSpace.functions)
        );
    };

    const renderSpaceEditor = (space: Space, isNew = false) => {
        const currentSpace = isNew ? newSpace : editedSpaces[space.space_id];
        const spaceId = isNew ? "" : space.space_id;

        if (!currentSpace) return null;

        return (
            <Box sx={{ mb: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            name="name"
                            label="Name"
                            value={currentSpace.name || ""}
                            onChange={(e) => handleFieldChange(spaceId, "name", e.target.value)}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                            height="100%">
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<SaveIcon />}
                                onClick={() => handleSaveSpace(spaceId)}
                                disabled={isNew ? false : !hasUnsavedChanges(spaceId)}
                                sx={{ mt: 2 }}>
                                {isNew ? "Create Space" : "Save Changes"}
                            </Button>
                            {!isNew && (
                                <Button
                                    variant="contained"
                                    color="error"
                                    startIcon={<DeleteIcon />}
                                    onClick={() => setOpenDeleteDialog(spaceId)}
                                    sx={{ mt: 2, ml: 2 }}>
                                    Delete Space
                                </Button>
                            )}
                            {isNew && (
                                <Button
                                    variant="outlined"
                                    onClick={() => setIsCreatingNewSpace(false)}
                                    sx={{ mt: 2, ml: 2 }}>
                                    Cancel
                                </Button>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            multiline
                            rows={3}
                            name="description"
                            label="Description"
                            value={currentSpace.description || ""}
                            onChange={(e) =>
                                handleFieldChange(spaceId, "description", e.target.value)
                            }
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{ mt: 2 }}>
                            Visibility
                        </Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={currentSpace.authz.is_public || false}
                                    onChange={(e) =>
                                        handleAuthzFieldChange(
                                            spaceId,
                                            "is_public",
                                            e.target.checked,
                                        )
                                    }
                                />
                            }
                            label="Public"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1" gutterBottom>
                            Eligible User Emails
                        </Typography>
                        <EmailChipInput
                            emails={currentSpace.authz.emails}
                            setEmails={(emails) =>
                                handleAuthzFieldChange(spaceId, "emails", emails)
                            }
                            label="User Emails"
                            placeholder="Add user emails (press Enter to add)"
                            disabled={currentSpace.authz.is_public || false}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1" gutterBottom>
                            Eligible Team Names
                        </Typography>
                        <EmailChipInput
                            emails={currentSpace.authz.teams}
                            setEmails={(teams) => handleAuthzFieldChange(spaceId, "teams", teams)}
                            label="Team Names"
                            placeholder="Add team names (press Enter to add)"
                            disabled={currentSpace.authz.is_public || false}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{ mt: 2 }}>
                            Functions
                        </Typography>
                        <Divider />
                    </Grid>

                    {Object.entries(currentSpace.functions).map(([fnName, fnId], index) => (
                        <Grid item xs={12} key={index}>
                            <Paper elevation={2} sx={{ p: 2 }}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            fullWidth
                                            name="functionName"
                                            label="Action Name"
                                            value={fnName}
                                            onChange={(e) =>
                                                handleFunctionNameChange(
                                                    spaceId,
                                                    fnName,
                                                    e.target.value,
                                                )
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={7}>
                                        <Autocomplete
                                            options={fns}
                                            getOptionLabel={(option: Fn) =>
                                                `${option?.name || "Unnamed"} (id: ${option?.fn_id || "unknown"})`
                                            }
                                            renderInput={(params) => (
                                                <TextField {...params} label="Choose function" />
                                            )}
                                            value={fns.find((fn) => fn?.fn_id === fnId) || null}
                                            onChange={(event, newValue) =>
                                                handleFunctionChange(spaceId, fnName, newValue)
                                            }
                                            isOptionEqualToValue={(option, value) =>
                                                option?.fn_id === value?.fn_id
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={1} container justifyContent="center">
                                        <IconButton
                                            color="primary"
                                            onClick={() => handleRemoveFunction(spaceId, fnName)}>
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    ))}

                    <Grid item xs={12}>
                        <Button
                            color="primary"
                            onClick={() => handleAddFunction(spaceId)}
                            startIcon={<AddCircleOutlineIcon />}>
                            Add Function
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        );
    };

    if (!isAdmin) {
        return null; // Don't render anything for non-admins
    }

    return (
        <Container className="space-editor-page-container min-w-full">
            <Box sx={{ mb: 4 }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h4">Space Management</Typography>
                    {!isCreatingNewSpace && (
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={() => setIsCreatingNewSpace(true)}>
                            Create New Space
                        </Button>
                    )}
                </Box>

                {isCreatingNewSpace && (
                    <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
                        <Typography variant="h5" gutterBottom>
                            Create New Space
                        </Typography>
                        {renderSpaceEditor(newSpace, true)}
                    </Paper>
                )}

                {spaces.map((space) => (
                    <Accordion key={space.space_id} defaultExpanded={false} sx={{ mb: 2 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Grid container alignItems="center">
                                <Grid item xs={9}>
                                    <Typography variant="h6">
                                        {space.name || "Unnamed Space"}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        ID: {space.space_id} | Author:{" "}
                                        {space.author_name || "Unknown"} | Created:{" "}
                                        {space.created_ts
                                            ? new Date(space.created_ts).toLocaleDateString()
                                            : "Unknown"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} container justifyContent="flex-end">
                                    {hasUnsavedChanges(space.space_id) && (
                                        <Typography
                                            variant="body2"
                                            color="primary"
                                            sx={{ fontStyle: "italic", mr: 2 }}>
                                            Unsaved changes
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>{renderSpaceEditor(space)}</AccordionDetails>
                    </Accordion>
                ))}
            </Box>

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={!!openDeleteDialog}
                onClose={() => setOpenDeleteDialog(null)}
                aria-labelledby="delete-space-dialog-title">
                <DialogTitle id="delete-space-dialog-title" color="error">
                    Delete Space
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this space? This action cannot be undone.
                    </DialogContentText>
                    <Box mt={2}>
                        {openDeleteDialog &&
                            spaces.find((s) => s.space_id === openDeleteDialog) && (
                                <>
                                    <Typography variant="subtitle1">
                                        <strong>ID:</strong>{" "}
                                        {
                                            spaces.find((s) => s.space_id === openDeleteDialog)
                                                ?.space_id
                                        }
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        <strong>Name:</strong>{" "}
                                        {spaces.find((s) => s.space_id === openDeleteDialog)?.name}
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        <strong>Description:</strong>{" "}
                                        {
                                            spaces.find((s) => s.space_id === openDeleteDialog)
                                                ?.description
                                        }
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        <strong>Author:</strong>{" "}
                                        {
                                            spaces.find((s) => s.space_id === openDeleteDialog)
                                                ?.author_name
                                        }
                                    </Typography>
                                </>
                            )}
                    </Box>
                    <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                        This will permanently delete the space and all its associated data.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="error"
                        startIcon={<DeleteIcon />}
                        onClick={handleDeleteSpace}>
                        Delete
                    </Button>
                    <Button
                        variant="outlined"
                        startIcon={<CancelIcon />}
                        onClick={() => setOpenDeleteDialog(null)}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default SpaceEditorPage;
