import React, { useState } from "react";

import {
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    TextField,
    Chip,
    Box,
} from "@mui/material";

interface TeamMembersInputProps {
    teamMembers: string[];
    setTeamMembers: (members: string[]) => void;
    onNext: () => void;
    onBack: () => void;
}

const TeamMembersInput: React.FC<TeamMembersInputProps> = ({
    teamMembers,
    setTeamMembers,
    onNext,
    onBack,
}) => {
    const [useType, setUseType] = useState(teamMembers.length > 0 ? "team" : "alone");
    const [inputValue, setInputValue] = useState("");

    const handleUseTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        setUseType(value);
        if (value === "alone") {
            setTeamMembers([]);
        }
    };

    const handleInputKeyDown = (event: React.KeyboardEvent) => {
        if (["Enter", " ", ","].includes(event.key) && inputValue.trim()) {
            event.preventDefault();
            addMember();
        }
    };

    const addMember = () => {
        const email = inputValue.trim();
        if (email && !teamMembers.includes(email) && /\S+@\S+\.\S+/.test(email)) {
            setTeamMembers([...teamMembers, email]);
            setInputValue("");
        }
    };

    const removeMember = (member: string) => {
        setTeamMembers(teamMembers.filter((m) => m !== member));
    };

    return (
        <>
            <DialogContent>
                <DialogContentText>
                    Do you plan to use this platform alone or with others on your team?
                </DialogContentText>
                <FormControl component="fieldset" style={{ marginTop: 16 }}>
                    <RadioGroup
                        aria-label="use-type"
                        name="use-type"
                        value={useType}
                        onChange={handleUseTypeChange}>
                        <FormControlLabel
                            value="alone"
                            control={<Radio />}
                            label="I'll use it alone"
                        />
                        <FormControlLabel
                            value="team"
                            control={<Radio />}
                            label="I'll use it with others on my team"
                        />
                    </RadioGroup>
                </FormControl>

                {useType === "team" && (
                    <Box mt={2}>
                        <DialogContentText variant="body2">
                            Add team members' email addresses:
                        </DialogContentText>
                        <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            placeholder="Enter email addresses (press Enter, Space or comma to add)"
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            onKeyDown={handleInputKeyDown}
                            onBlur={() => inputValue.trim() && addMember()}
                            margin="normal"
                        />
                        <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
                            {teamMembers.map((member) => (
                                <Chip
                                    key={member}
                                    label={member}
                                    onDelete={() => removeMember(member)}
                                />
                            ))}
                        </Box>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onBack}>Back</Button>
                <Button onClick={onNext} variant="contained" color="primary">
                    Finish
                </Button>
            </DialogActions>
        </>
    );
};

export default TeamMembersInput;
