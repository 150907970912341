// src/components/DetailPanel.tsx
import React, { useState } from "react";

import { Box, Typography, Divider, IconButton, Collapse, Paper, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import { detailPanelConfig, ColumnFormatSpec } from "../components/DetailPanelConfig";

interface DetailPanelProps {
    rowData: any;
    entityType: string;
    onClose: () => void;
    columnDescriptions?: { [key: string]: string };
}

interface DetailSectionProps {
    sectionName: string;
    columns: ColumnFormatSpec[];
    isExpanded: boolean;
    onToggle: (section: string) => void;
    rowData: any;
    columnDescriptions?: { [key: string]: string };
}

const formatValue = (
    value: any,
    dataType: string,
    columnName: string,
    rowData: any,
): React.ReactNode => {
    if (value === undefined || value === null) return "";

    // Special case for email_score (red if < 85 and email_accept_all is True)
    if (columnName === "email_score" && Number(value) < 85 && rowData.email_accept_all === "True") {
        return <span style={{ color: "red", fontWeight: "bold" }}>{value}</span>;
    }

    // Special case for email_accept_all (green if "False")
    if (columnName === "email_accept_all" && value === "False") {
        return <span style={{ color: "green", fontWeight: "bold" }}>{value}</span>;
    }

    switch (dataType) {
        case "number":
            return typeof value === "number" ? value.toLocaleString() : value;
        case "date":
            return value ? new Date(value).toLocaleDateString() : "";
        case "boolean":
            return value ? "Yes" : "No";
        case "json":
        case "list":
            try {
                const jsonValue = typeof value === "string" ? JSON.parse(value) : value;
                return JSON.stringify(jsonValue, null, 2);
            } catch {
                return String(value);
            }
        default:
            return String(value);
    }
};

// Extracted component for rendering a section to reduce duplication
const DetailSection: React.FC<DetailSectionProps> = ({
    sectionName,
    columns,
    isExpanded,
    onToggle,
    rowData,
    columnDescriptions = {},
}) => {
    return (
        <Box sx={{ mb: 1 }}>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: "rgba(0, 0, 0, 0.05)",
                    p: 0.5,
                    borderRadius: 1,
                }}
                onClick={() => onToggle(sectionName)}>
                <IconButton size="small" sx={{ p: 0, mr: 0.5 }}>
                    {isExpanded ? (
                        <ExpandLessIcon fontSize="small" />
                    ) : (
                        <ExpandMoreIcon fontSize="small" />
                    )}
                </IconButton>
                <Typography variant="subtitle2" fontWeight="bold">
                    {sectionName}
                </Typography>
            </Box>

            <Collapse in={isExpanded} timeout="auto">
                <Box sx={{ pl: 1, pr: 1, mt: 0.5 }}>
                    {columns.map((col: ColumnFormatSpec) => {
                        const value = rowData[col.columnName];
                        if (value === undefined || value === null || value === "") return null;

                        return (
                            <Box
                                key={col.columnName}
                                sx={{
                                    display: "flex",
                                    mb: 0.5,
                                    lineHeight: 1.2,
                                    fontSize: "0.7rem",
                                }}>
                                <Tooltip
                                    title={columnDescriptions?.[col.columnName] || ""}
                                    placement="left-start"
                                    disableHoverListener={!columnDescriptions?.[col.columnName]}
                                    arrow>
                                    <Box
                                        sx={{
                                            flexBasis: "50%",
                                            fontWeight: "bold",
                                            wordBreak: "break-word",
                                            whiteSpace: "normal",
                                            hyphens: "auto",
                                        }}>
                                        {col.columnName}:
                                    </Box>
                                </Tooltip>
                                <Box
                                    sx={{
                                        flexBasis: "50%",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        wordBreak: "break-word",
                                    }}>
                                    {formatValue(value, col.dataType, col.columnName, rowData)}
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            </Collapse>
            <Divider sx={{ mt: 1, mb: 1 }} />
        </Box>
    );
};

const DetailPanel: React.FC<DetailPanelProps> = ({
    rowData,
    entityType,
    onClose,
    columnDescriptions,
}) => {
    // Find all columns in the row data that aren't in the config
    const getOtherColumns = () => {
        const configuredColumns: Set<string> = new Set();

        // Collect all configured column names
        if (detailPanelConfig[entityType]) {
            Object.values(detailPanelConfig[entityType]).forEach((columns) => {
                columns.forEach((col) => {
                    configuredColumns.add(col.columnName);
                });
            });
        }

        // Find columns in rowData that aren't in the configured columns
        const otherColumns: ColumnFormatSpec[] = [];
        if (rowData) {
            Object.keys(rowData).forEach((colName) => {
                if (!configuredColumns.has(colName)) {
                    otherColumns.push({
                        columnName: colName,
                        dataType: "text", // Default type as text
                    });
                }
            });
        }

        return otherColumns;
    };

    const otherColumns = getOtherColumns();

    // Default open state for each section (all open by default)
    const [expandedSections, setExpandedSections] = useState<{ [key: string]: boolean }>(() => {
        const initialState: { [key: string]: boolean } = {};
        const config = detailPanelConfig[entityType] || {};

        Object.keys(config).forEach((section) => {
            initialState[section] = true;
        });

        // Add "Other Columns" section
        if (otherColumns.length > 0) {
            initialState["Other Columns"] = true;
        }

        return initialState;
    });

    const handleToggleSection = (section: string) => {
        setExpandedSections((prev) => ({
            ...prev,
            [section]: !prev[section],
        }));
    };

    // If no configuration exists for the entity type, show a message
    if (!detailPanelConfig[entityType] && otherColumns.length === 0) {
        return (
            <Paper
                sx={{
                    height: "100%",
                    p: 2,
                    position: "relative",
                    overflowY: "auto",
                }}>
                <IconButton
                    size="small"
                    onClick={onClose}
                    sx={{ position: "absolute", top: 8, right: 8 }}>
                    <CloseIcon fontSize="small" />
                </IconButton>
                <Typography variant="h6">No configuration for entity type: {entityType}</Typography>
            </Paper>
        );
    }

    return (
        <Paper
            sx={{
                height: "100%",
                overflowY: "auto",
                position: "relative",
            }}>
            <IconButton
                size="small"
                onClick={onClose}
                sx={{ position: "absolute", top: 8, right: 8, zIndex: 2 }}>
                <CloseIcon fontSize="small" />
            </IconButton>

            <Box
                sx={{
                    p: 1,
                    fontSize: "0.75rem", // Very small font
                    maxHeight: "100%",
                    pt: 4, // Leave space for the close button
                }}>
                <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                    Row Details
                </Typography>

                {/* Render configured sections */}
                {detailPanelConfig[entityType] &&
                    Object.entries(detailPanelConfig[entityType]).map(([sectionName, columns]) => (
                        <DetailSection
                            key={sectionName}
                            sectionName={sectionName}
                            columns={columns}
                            isExpanded={expandedSections[sectionName]}
                            onToggle={handleToggleSection}
                            rowData={rowData}
                            columnDescriptions={columnDescriptions}
                        />
                    ))}

                {/* Render "Other Columns" section if needed */}
                {otherColumns.length > 0 && (
                    <DetailSection
                        sectionName="Other Columns"
                        columns={otherColumns}
                        isExpanded={expandedSections["Other Columns"]}
                        onToggle={handleToggleSection}
                        rowData={rowData}
                        columnDescriptions={columnDescriptions}
                    />
                )}
            </Box>
        </Paper>
    );
};

export default DetailPanel;
