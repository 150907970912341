import { useEffect } from "react";

import { CssBaseline } from "@mui/material";
import Cookies from "js-cookie";
import { Toaster } from "react-hot-toast";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import Layout from "./components/Layout";
import { ScreenSizeProvider } from "./context/ScreenSizeContext";
import { UserProfileProvider } from "./context/UserProfileContext";
import DataPage from "./pages/data";
import FeedbackPage from "./pages/feedback";
import HomePage from "./pages/home_page";
import PrivacyPolicy from "./pages/privacy_policy";
import SecretsPage from "./pages/secrets";
import SpaceEditorPage from "./pages/space_editor";
import TeamPage from "./pages/team";
import TermsOfService from "./pages/terms_of_service";
import FunctionsPage from "./pages/functions";
import FunctionEditorPage from "./pages/function_editor";
import SpacePage from "./pages/space";
import RunHistoryPage from "./pages/run_history";

import "./styles/index.css";

function App() {
    useEffect(() => {
        const uid = Cookies.get("__UID");
        if (!uid) {
            const newUid = uuidv4();
            Cookies.set("__UID", newUid, {
                sameSite: "strict",
                expires: 365,
            });
        }
    }, []);

    return (
        <>
            <CssBaseline />
            <ScreenSizeProvider>
                <UserProfileProvider>
                    <Router>
                        <Layout>
                            <Routes>
                                <Route path="/" element={<HomePage />} />
                                <Route path="/space_editor" element={<SpaceEditorPage />} />
                                <Route path="/space" element={<SpacePage />} />
                                <Route path="/function_editor" element={<FunctionEditorPage />} />
                                <Route path="/functions" element={<FunctionsPage />} />
                                <Route path="/run_history" element={<RunHistoryPage />} />
                                <Route path="/team" element={<TeamPage />} />
                                <Route path="/feedback" element={<FeedbackPage />} />
                                <Route path="/secrets" element={<SecretsPage />} />
                                <Route path="/data" element={<DataPage />} />
                                <Route path="/privacy_policy" element={<PrivacyPolicy />} />
                                <Route path="/terms_of_service" element={<TermsOfService />} />
                            </Routes>
                            <Toaster />
                        </Layout>
                    </Router>
                </UserProfileProvider>
            </ScreenSizeProvider>
        </>
    );
}

export default App;
