import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { Dialog, DialogTitle, CircularProgress, Box } from "@mui/material";

import { googleSignIn } from "../../auth/login";
import { fetch_with_auth } from "../../components/Util";
import AuthDialog from "./AuthDialog";
import ObjectiveInput from "./ObjectiveInput";
import UsecaseSelection from "./UsecaseSelection";
import TeamMembersInput from "./TeamMembersInput";
import { Team } from "../../types/Team";
import { useUserProfile } from "../../context/UserProfileContext";
import { UserProfile } from "../../types/UserProfile";

enum OnboardingStep {
    OBJECTIVE,
    USECASE,
    TEAM_MEMBERS,
}

interface OnboardingDialogProps {
    open: boolean;
    onClose: () => void;
}

const OnboardingDialog: React.FC<OnboardingDialogProps> = ({ open, onClose }) => {
    const { userProfile, setUserProfile, loading } = useUserProfile();
    const navigate = useNavigate();

    // Start with first onboarding step
    const [currentStep, setCurrentStep] = useState<OnboardingStep>(OnboardingStep.OBJECTIVE);
    const [objective, setObjective] = useState("");
    const [selectedUsecase, setSelectedUsecase] = useState("");
    const [teamMembers, setTeamMembers] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleSignIn = async () => {
        try {
            await googleSignIn();
        } catch (error) {
            console.error("Error signing in:", error);
        }
    };

    const handleSubmit = async () => {
        if (!userProfile.user_id) return;

        setIsLoading(true);

        try {
            // Create team data
            const team = {
                description: objective,
                id: "", // Will be generated by backend
                // Current user is already included by backend as admin & member
                members: teamMembers,
                admins: [],
            };

            const requestData = {
                team: team,
                usecase_id: selectedUsecase,
            };

            // Send to onboarding endpoint using fetch_with_auth
            const data = await fetch_with_auth(
                "onboarding",
                userProfile.id_token || "",
                "POST",
                requestData,
            );

            // Update user profile context with the new team
            if (data.team) {
                setUserProfile(
                    (prev) =>
                        new UserProfile({
                            ...prev,
                            team: new Team(data.team),
                        }),
                );
            }

            // Navigate to space page with the new space ID
            navigate(`/space?space_id=${data.space_id}`);
            onClose();
        } catch (error) {
            console.error("Error during onboarding:", error);
            setIsLoading(false);
        }
    };

    // Don't show dialog if not open or still loading
    if (!open || loading) return null;

    // If user is authenticated and has a team, redirect to space
    if (userProfile.user_id && userProfile.team) {
        // Use setTimeout to avoid state updates during render
        setTimeout(() => {
            navigate("/space");
            onClose();
        }, 0);
        return null;
    }

    // If user is not authenticated, show auth dialog
    if (!userProfile.user_id) {
        return <AuthDialog open={open} onClose={onClose} handleSignIn={handleSignIn} />;
    }

    const renderStep = () => {
        switch (currentStep) {
            case OnboardingStep.OBJECTIVE:
                return (
                    <ObjectiveInput
                        objective={objective}
                        setObjective={setObjective}
                        onNext={() => setCurrentStep(OnboardingStep.USECASE)}
                        onBack={onClose}
                    />
                );
            case OnboardingStep.USECASE:
                return (
                    <UsecaseSelection
                        selectedUsecase={selectedUsecase}
                        setSelectedUsecase={setSelectedUsecase}
                        onNext={() => setCurrentStep(OnboardingStep.TEAM_MEMBERS)}
                        onBack={() => setCurrentStep(OnboardingStep.OBJECTIVE)}
                    />
                );
            case OnboardingStep.TEAM_MEMBERS:
                return (
                    <TeamMembersInput
                        teamMembers={teamMembers}
                        setTeamMembers={setTeamMembers}
                        onNext={handleSubmit}
                        onBack={() => setCurrentStep(OnboardingStep.USECASE)}
                    />
                );
            default:
                return null;
        }
    };

    const getDialogTitle = () => {
        switch (currentStep) {
            case OnboardingStep.OBJECTIVE:
                return "What's your objective?";
            case OnboardingStep.USECASE:
                return "Select your use case";
            case OnboardingStep.TEAM_MEMBERS:
                return "Team setup";
            default:
                return "Onboarding";
        }
    };

    return (
        <Dialog
            open={open}
            onClose={!isLoading ? onClose : undefined}
            aria-labelledby="onboarding-dialog-title"
            maxWidth="sm"
            fullWidth>
            {isLoading ? (
                <>
                    <DialogTitle id="onboarding-dialog-title">Creating your workspace</DialogTitle>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        p={4}>
                        <CircularProgress />
                        <Box mt={2}>Setting up your workspace...</Box>
                    </Box>
                </>
            ) : (
                <>
                    <DialogTitle id="onboarding-dialog-title">{getDialogTitle()}</DialogTitle>
                    {renderStep()}
                </>
            )}
        </Dialog>
    );
};

export default OnboardingDialog;
