import React, { Dispatch, DragEvent, useCallback, useEffect, useRef, useState } from "react";

import {
    ReactFlow,
    addEdge,
    Background,
    BackgroundVariant,
    Connection,
    Controls,
    Edge,
    Node,
    applyEdgeChanges,
    EdgeChange,
    useReactFlow,
} from "@xyflow/react";
import shortUUID from "short-uuid";

import { OperatorDeclaration } from "../interfaces/OperatorDeclaration";
import { FnRun } from "../types/FnRun";
import { DagStepType, FnDef } from "../types/FnDef";
import { nodesToDag } from "./OperatorNode";
import EdgeDataPopup from "./EdgeDataPopup";
import OperatorNode from "./OperatorNode";
import DagDiagramOperatorSelector from "./DagDiagramOperatorSelector";

import "@xyflow/react/dist/style.css";

const nodeTypes = {
    operator: OperatorNode,
};

const getId = () => {
    const translator = shortUUID();
    return translator.new();
};

interface DagDiagramProps {
    nodes: any[];
    setNodes: Dispatch<React.SetStateAction<any[]>>;
    onNodesChange: (newState: any[]) => void;
    edges: any[];
    setEdges: Dispatch<React.SetStateAction<any[]>>;
    onEdgesChange: (newState: any[]) => void;

    operatorDeclarations: OperatorDeclaration[];

    fnDef: FnDef | null;
    setFnDef: Dispatch<React.SetStateAction<FnDef | null>>;

    nodeCommitFunctionsRef: React.MutableRefObject<Map<string, () => void>>;

    fnRun: FnRun | null;
    isFrozenMode: boolean;

    isActionFeedVisible: boolean;
    setIsActionFeedVisible: React.Dispatch<React.SetStateAction<boolean>>;
    isEdgePopupVisible: boolean;
    setIsEdgePopupVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const DagDiagram: React.FC<DagDiagramProps> = (props) => {
    const reactFlowWrapper = useRef<HTMLDivElement>(null);
    const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);

    const [selectedEdge, setSelectedEdge] = useState<Edge | null>(null);

    // Listen for keyboard 'Delete' if an edge is selected:
    React.useEffect(() => {
        function handleKeyDown(e: KeyboardEvent) {
            // If 'Delete' is pressed and we have a selectedEdge, remove it from edges
            if (e.key === "Delete" && selectedEdge) {
                props.setEdges((currentEdges) => {
                    const newEdges = currentEdges.filter((edge) => edge.id !== selectedEdge.id);
                    // Rebuild the dag after removing the edge
                    const newDag = nodesToDag(props.nodes, newEdges);
                    updateDag(newDag);
                    return newEdges;
                });
                // Clear the selected edge
                setSelectedEdge(null);
            }
        }
        window.addEventListener("keydown", handleKeyDown);
        return () => window.removeEventListener("keydown", handleKeyDown);
    }, [selectedEdge, props.setEdges, props.nodes]);

    useEffect(() => {
        if (props.fnDef && props.fnDef.dag) {
            const { nodes, edges } = dagToNodes(props.fnDef.dag, props.operatorDeclarations);
            props.setNodes(nodes);
            props.setEdges(edges);
        }
    }, [props.fnDef, props.operatorDeclarations]);

    const onEdgeClick = useCallback((event: React.MouseEvent, edge: Edge) => {
        setSelectedEdge(edge);
        props.setIsActionFeedVisible(false);
        props.setIsEdgePopupVisible(true);
    }, []);

    const onPaneClick = useCallback(() => {
        setSelectedEdge(null);
        props.setIsEdgePopupVisible(false);
    }, []);

    const updateDag = (newDag: DagStepType[]) => {
        if (props.fnDef) {
            const newFnDef = {
                ...props.fnDef,
                dag: newDag,
            };
            props.fnDef.update(props.setFnDef, newFnDef);
        }
    };

    const onConnect = useCallback(
        (connection: Connection) => {
            const edge_id = `e-${connection.source}-${connection.target}-${connection.targetHandle}`;

            const edge: Edge = {
                type: "smoothstep",
                id: edge_id,
                source: connection.source ?? "",
                target: connection.target ?? "",
                sourceHandle: connection.sourceHandle ?? "",
                targetHandle: connection.targetHandle ?? "",
            };
            props.setEdges((eds) => {
                const newEdges = addEdge(edge, eds);
                const newDag = nodesToDag(props.nodes, newEdges);
                updateDag(newDag);
                return newEdges;
            });
        },
        [props.nodes, props.setEdges, props.edges, props.fnDef, props.setFnDef],
    );

    const onEdgesDelete = useCallback(
        (edgesToDelete: Edge[]) => {
            props.setEdges((currentEdges) => {
                const edgeIdsToDelete = edgesToDelete.map((edge) => edge.id);
                const newEdges = currentEdges.filter((edge) => !edgeIdsToDelete.includes(edge.id));
                const newDag = nodesToDag(props.nodes, newEdges);
                updateDag(newDag);
                return newEdges;
            });
        },
        [props.nodes, props.edges, props.setEdges, props.fnDef, props.setFnDef],
    );

    const onDragOver = useCallback((event: DragEvent) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
    }, []);

    function dagToNodes(
        dag: DagStepType[],
        operatorDeclarations: OperatorDeclaration[],
    ): { nodes: Node[]; edges: Edge[] } {
        function findPlaceholder(operatorDeclaration: any, paramName: string): string {
            for (const parameter of operatorDeclaration.parameters) {
                if (parameter.name === paramName) {
                    return parameter.placeholder || ""; // return an empty string if placeholder is null
                }
            }
            return "";
        }

        const nodes: Node[] = [];
        const edges: Edge[] = [];

        const nodeMap = new Map<string, Node>();

        dag.forEach((dagNode, index) => {
            // Example value of dagNode here:
            // {
            //     "id":"dndnode_3",
            //     "position": {"x":-172.7086717860799,"y":-447.56448254136876},
            //     "width": 100,
            //     "height": 100,
            //     "operator":"Web search",
            //     "parameters":{"query":"test","results_count":null}
            // }

            const operatorDeclaration = operatorDeclarations.find(
                (op) => op.name === dagNode.operator,
            );
            // Example value of operatorDeclaration
            /*    {
                    "description": "",
                    "inputs": [],
                    "name": "Web search",
                    "outputs": [
                        {
                            "data_type": "{name,content}[]",
                            "name": "search_results"
                        }
                    ],
                    "parameters": [
                        {
                            "data_type": "string",
                            "name": "query",
                            "placeholder": "Enter your search query"
                        },
                        {
                            "data_type": "integer",
                            "name": "results_count",
                            "placeholder": "Enter the number of results"
                        }
                    ],
                    "additional_parameters": [
                        {
                            "data_type": "enum(gpt-4,gpt-3.5-turbo)",
                            "name": "model_preference",
                            "placeholder": ""
                        }
                    ],
                    "secrets": []
                }
            */

            if (!operatorDeclaration) {
                console.error(`Operator declaration not found for operator: ${dagNode.operator}`);
                return;
            }

            const node: Node = {
                id: dagNode.id,
                type: "operator",
                position: dagNode.position,
                selected: dagNode.selected,
                width: dagNode.width ?? undefined,
                height: dagNode.height ?? undefined,
                data: {
                    name: dagNode.operator,
                    parameters: Object.entries(dagNode.parameters).map(([name, value]) => ({
                        name,
                        placeholder: findPlaceholder(operatorDeclaration, name),
                        data_type: "string",
                        value,
                    })),
                    inputs: operatorDeclaration.inputs.map(({ name, data_type }) => ({
                        name,
                        data_type,
                    })),
                    outputs: operatorDeclaration.outputs.map(({ name, data_type }) => ({
                        name,
                        data_type,
                    })),
                    declaration: operatorDeclaration,
                    batch: dagNode.batch || false,

                    edges: props.edges,
                    setEdges: props.setEdges,
                    nodes: props.nodes,
                    nodeCommitFunctionsRef: props.nodeCommitFunctionsRef,
                    setNodes: props.setNodes,
                    fnDef: props.fnDef,
                    setFnDef: props.setFnDef,
                },
            };

            nodes.push(node);
            nodeMap.set(node.id, node);

            if (dagNode.inputs) {
                Object.entries(dagNode.inputs).forEach(([targetHandle, [source, sourceHandle]]) => {
                    const edge_id = `e-${source}-${dagNode.id}-${targetHandle}`;

                    const edge: Edge = {
                        type: "smoothstep",
                        id: edge_id,
                        source,
                        target: dagNode.id,
                        sourceHandle,
                        targetHandle,
                        animated: true,
                        style: { strokeWidth: 4 },
                    };
                    edges.push(edge);
                });
            }
        });

        return { nodes, edges };
    }

    const { screenToFlowPosition } = useReactFlow();

    interface DraggedNodeData {
        name: string;
        // add other properties if needed
    }

    const onDrop = useCallback(
        (event: DragEvent) => {
            event.preventDefault();
            if (reactFlowWrapper.current) {
                const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
                const type = event.dataTransfer.getData("application/reactflow");

                const dataStr = event.dataTransfer.getData("application/reactflow-data");
                if (!dataStr) {
                    console.error("No data found for 'application/reactflow-data'");
                    return;
                }

                let nodeData: DraggedNodeData;
                try {
                    nodeData = JSON.parse(dataStr);
                } catch (error) {
                    console.error("Error parsing reactflow-data:", error);
                    return;
                }

                const position = screenToFlowPosition({
                    x: event.clientX,
                    y: event.clientY,
                });

                const operatorDeclaration = props.operatorDeclarations.find(
                    (op) => op.name === nodeData.name,
                );
                const newNode: Node = {
                    id: getId(),
                    type,
                    position,
                    data: {
                        ...nodeData,
                        declaration: operatorDeclaration,
                        edges: props.edges,
                        setEdges: props.setEdges,
                        nodeCommitFunctionsRef: props.nodeCommitFunctionsRef,
                        nodes: props.nodes,
                        setNodes: props.setNodes,
                        fnDef: props.fnDef,
                        setFnDef: props.setFnDef,
                    },
                };

                props.setNodes((nds: Node[]) => {
                    const updatedNodes = nds.concat(newNode);
                    const newDag = nodesToDag(updatedNodes, props.edges);
                    updateDag(newDag);
                    return updatedNodes;
                });
            }
        },
        [props, reactFlowWrapper, screenToFlowPosition],
    );

    const onNodeDragStop = useCallback(
        (event: any, node: Node) => {
            props.setNodes((ns) => {
                const updatedNodes = ns.map((n) => (n.id === node.id ? node : n));
                const newDag = nodesToDag(updatedNodes, props.edges);
                updateDag(newDag);
                return updatedNodes;
            });
        },
        [props.setNodes, props.edges, props.setEdges, props.fnDef, props.setFnDef],
    );

    const handleEdgesChange = useCallback(
        (changes: EdgeChange[]) => {
            props.setEdges((eds) => {
                const updatedEdges = applyEdgeChanges(changes, eds);
                const newDag = nodesToDag(props.nodes, updatedEdges);
                updateDag(newDag);
                return updatedEdges;
            });
        },
        [props.nodes, props.fnDef, props.setFnDef, props.setEdges],
    );

    const renderEdgeDataPopup = () => {
        if (selectedEdge && props.fnRun && props.fnRun.step_outputs && props.isEdgePopupVisible) {
            return <EdgeDataPopup edge={selectedEdge} stepOutputs={props.fnRun.step_outputs} />;
        }
        return null;
    };

    return (
        <div className="flex h-full flex-col overflow-hidden">
            <div className="flex min-w-full flex-grow flex-row-reverse">
                <div
                    className="h-full flex-grow"
                    ref={reactFlowWrapper}
                    style={{
                        height: "100%",
                        backgroundColor: props.isFrozenMode ? "#f0f0f0" : "#e6fff2",
                    }}>
                    <ReactFlow
                        nodes={props.nodes}
                        edges={props.edges}
                        // panOnDrag allows using mouse to navigate around the graph.
                        panOnDrag={true}
                        onNodesChange={!props.isFrozenMode ? props.onNodesChange : undefined}
                        onEdgesChange={!props.isFrozenMode ? handleEdgesChange : undefined}
                        onConnect={!props.isFrozenMode ? onConnect : undefined}
                        onInit={setReactFlowInstance}
                        onDrop={!props.isFrozenMode ? onDrop : undefined}
                        onDragOver={!props.isFrozenMode ? onDragOver : undefined}
                        fitView
                        nodeTypes={nodeTypes}
                        onNodeDragStop={!props.isFrozenMode ? onNodeDragStop : undefined}
                        onEdgesDelete={onEdgesDelete}
                        onEdgeClick={onEdgeClick}
                        onPaneClick={onPaneClick}
                        edgesReconnectable={!props.isFrozenMode}
                        edgesFocusable={!props.isFrozenMode}
                        nodesDraggable={!props.isFrozenMode}
                        nodesConnectable={!props.isFrozenMode}
                        nodesFocusable={!props.isFrozenMode}
                        // draggable seems to enable dragging of the entire graph, which is not really a function
                        // we want, note that dragging individual nodes is still possible when draggable=false.
                        draggable={false}
                        //panOnDrag={!props.isFrozenMode}
                        elementsSelectable={!props.isFrozenMode}
                        minZoom={0.1}>
                        {!props.isFrozenMode && <Controls />}
                        <Background color="#ccc" variant={BackgroundVariant.Dots} />
                    </ReactFlow>
                    {renderEdgeDataPopup()}
                </div>
                <div
                    style={{
                        height: "100vh",
                        maxWidth: "300px",
                        overflow: "hidden",
                        display: "flex",
                        flexDirection: "column",
                    }}>
                    <DagDiagramOperatorSelector ops={props.operatorDeclarations} />
                </div>
            </div>
        </div>
    );
};

export default DagDiagram;
