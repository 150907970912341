import { Dispatch, SetStateAction } from "react";

import { User } from "firebase/auth";
import Cookies from "js-cookie";

import { Team } from "./Team";

export class UserProfile {
    user_id: string;
    color_scheme: string | null;
    id_token: string | null;
    user_email: string | null;
    display_name: string | null;
    team: Team | null;

    constructor(userProfile: Partial<UserProfile>) {
        this.user_id = userProfile.user_id || "";
        this.color_scheme = userProfile.color_scheme || null;
        this.id_token = userProfile.id_token || null;
        this.user_email = userProfile.user_email || null;
        this.display_name = userProfile.display_name || null;
        this.team = userProfile.team || null;
    }

    update(
        setState: React.Dispatch<React.SetStateAction<UserProfile>>,
        someValues: Partial<UserProfile>,
    ) {
        const newInstance = new UserProfile({
            ...this,
            ...someValues,
        });
        setState(newInstance);
    }

    async init(
        setState: Dispatch<SetStateAction<UserProfile>>,
        user: User | null,
    ): Promise<Partial<UserProfile> | null> {
        const updatedUserProfile: Partial<UserProfile> = {
            user_id: user ? user.uid : "",
            color_scheme: Cookies.get("color_scheme") || "light",
            id_token: user ? await user.getIdToken() : null,
            user_email: user ? user.email : null,
            display_name: user ? user.displayName : null,
        };

        this.update(setState, updatedUserProfile);
        return updatedUserProfile;
    }
}
