import { fetch_with_auth } from "../components/Util";
import { FnRun } from "./FnRun";

export type MessageState =
    | "simple_text"
    | "space_chat_running"
    | "space_chat_done"
    | "fn_running"
    | "fn_done";

export type MessageAuthor = "spesh" | "user";

export class ChatMessage {
    id: string;
    author: MessageAuthor;
    state: MessageState;
    text: string;
    fn_name?: string;
    fn_id?: string;
    run_id?: string;
    flow?: object;
    space_id: string;
    user_id: string;
    created_ts: Date | null;
    credit_cost: number;

    // Non-persisted property for UI state
    _fnRun?: FnRun; // FnRun object loaded on demand

    constructor(message: Partial<ChatMessage> = {}) {
        this.id = message.id || "";
        this.author = message.author || "user";
        this.state = message.state || "simple_text";
        this.text = message.text || "";
        this.fn_name = message.fn_name;
        this.fn_id = message.fn_id;
        this.run_id = message.run_id;
        this.flow = message.flow;
        this.space_id = message.space_id || "";
        this.user_id = message.user_id || "";
        this.created_ts =
            message.created_ts instanceof Date
                ? message.created_ts
                : message.created_ts
                  ? new Date(message.created_ts)
                  : null;
        this.credit_cost = message.credit_cost || 0;
        this._fnRun = message._fnRun;
    }

    async save(id_token: string): Promise<string> {
        // Create a copy of the message without the _fnRun property
        const messageToPersist = {
            id: this.id,
            author: this.author,
            state: this.state,
            text: this.text,
            fn_name: this.fn_name,
            fn_id: this.fn_id,
            run_id: this.run_id,
            flow: this.flow,
            space_id: this.space_id,
            user_id: this.user_id,
            created_ts: this.created_ts,
            credit_cost: this.credit_cost,
        };
        const data = await fetch_with_auth("chat_message", id_token, "POST", messageToPersist);
        this.id = data.message.id;
        return this.id;
    }

    async delete(id_token: string): Promise<boolean> {
        return await fetch_with_auth(`chat_message?id=${this.id}`, id_token, "DELETE");
    }

    static async loadMessagesForSpace(space_id: string, id_token: string): Promise<ChatMessage[]> {
        const data = await fetch_with_auth(`chat_message?space_id=${space_id}`, id_token, "GET");
        return data.map((msg: any) => new ChatMessage(msg));
    }

    // Load FnRun for this message if it has a run_id
    async loadFnRun(): Promise<FnRun | null> {
        if (!this.run_id) return null;

        if (this._fnRun) return this._fnRun; // Return cached FnRun if available

        try {
            const fnRun = await FnRun.loadRun(this.run_id);
            this._fnRun = fnRun;
            return fnRun;
        } catch (error) {
            console.error("Error loading FnRun:", error);
            return null;
        }
    }
}
