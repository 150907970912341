import React, { DragEvent } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";

import { OperatorDeclaration } from "../interfaces/OperatorDeclaration";
import OperatorTooltip from "./OperatorTooltip";

interface SelectorProps {
    ops: OperatorDeclaration[];
}

const DagDiagramOperatorSelector: React.FC<SelectorProps> = (props) => {
    // Arrange ops by category
    const opsByCategory = props.ops.reduce<Record<string, OperatorDeclaration[]>>((groups, op) => {
        const category = op.category;
        if (!groups[category]) {
            groups[category] = [];
        }
        groups[category].push(op);
        return groups;
    }, {});

    const onDragStart = (event: DragEvent, nodeData: OperatorDeclaration) => {
        event.dataTransfer.setData("application/reactflow", "operator");
        event.dataTransfer.setData("application/reactflow-data", JSON.stringify(nodeData));
        event.dataTransfer.effectAllowed = "move";
    };

    return (
        <aside
            style={{
                padding: "0px",
                fontSize: "12px",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                width: "100%",
            }}>
            <div className="m-4 text-xl font-bold">Drag & Drop operator</div>
            <div
                style={{
                    height: "calc(100% - 50px)",
                    overflowY: "auto",
                    overflowX: "hidden",
                    width: "100%",
                    flex: "1 1 auto",
                }}
                className="operator-scroller">
                <div className="min-w-full">
                    {opsByCategory &&
                        Object.entries(opsByCategory).map(([category, ops], index) => (
                            <Accordion key={index}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Typography
                                        className="text-xs"
                                        style={{ wordBreak: "break-word" }}>
                                        {category}
                                    </Typography>
                                </AccordionSummary>
                                {ops.map((declaration, index) => (
                                    <AccordionDetails
                                        key={index}
                                        style={{
                                            padding: "0px 5px 0px 5px",
                                        }}>
                                        <div
                                            key={index}
                                            className="h-auto"
                                            onDragStart={(event) => onDragStart(event, declaration)}
                                            draggable
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                border: `1px solid lightblue`,
                                                borderRadius: "1px",
                                                marginBottom: "2px",
                                                cursor: "grab",
                                                overflow: "hidden",
                                                whiteSpace: "normal",
                                                padding: "2px",
                                            }}>
                                            {declaration.name}
                                            <OperatorTooltip declaration={declaration} />
                                        </div>
                                    </AccordionDetails>
                                ))}
                            </Accordion>
                        ))}
                </div>
            </div>
        </aside>
    );
};

export default DagDiagramOperatorSelector;
