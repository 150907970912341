import { Dispatch, SetStateAction } from "react";

import { fetch_with_auth } from "../components/Util";

type TeamState = "free" | "paid" | "cancelled";

export class Team {
    id: string;
    description: string;
    members: string[];
    admins: string[];
    state: TeamState;
    credit_package: number | null;
    credit_cost_usd: number | null;
    billing_rate_usd: number | null;
    billing_period_months: number | null;
    billing_period_end: Date | null;
    credit_used_this_period: number;
    created_date: Date;
    paid_since_date: Date | null;
    cancelled_date: Date | null;
    credit_left: number;

    constructor(team: Partial<Team>) {
        this.id = team.id || "";
        this.description = team.description || "";
        this.members = team.members || [];
        this.admins = team.admins || [];
        this.state = team.state || "free";
        this.credit_package = team.credit_package || null;
        this.credit_cost_usd = team.credit_cost_usd || null;
        this.billing_rate_usd = team.billing_rate_usd || null;
        this.billing_period_months = team.billing_period_months || null;
        this.billing_period_end = team.billing_period_end
            ? new Date(team.billing_period_end)
            : null;
        this.credit_used_this_period = team.credit_used_this_period || 0;
        this.created_date = team.created_date ? new Date(team.created_date) : new Date();
        this.paid_since_date = team.paid_since_date ? new Date(team.paid_since_date) : null;
        this.cancelled_date = team.cancelled_date ? new Date(team.cancelled_date) : null;
        this.credit_left = team.credit_left || 0;
    }

    update(setState: Dispatch<SetStateAction<Team>>, someValues: Partial<Team>) {
        const newInstance = new Team({
            ...this,
            ...someValues,
        });
        setState(newInstance);
    }

    static async create_new(idToken: string, description: string) {
        const data = await fetch_with_auth(`team`, idToken, "POST", {
            action: "create",
            id: "",
            description: description,
        });

        return new Team(data.team);
    }

    static async update(idToken: string, team: Team) {
        const data = await fetch_with_auth(`team`, idToken, "POST", {
            action: "update",
            id: team.id,
            team: team,
        });

        return new Team(data.team);
    }

    static async delete_team(idToken: string, teamId: string) {
        return await fetch_with_auth(`team`, idToken, "DELETE", {
            id: teamId,
        });
    }

    async add_user(id_token: string, user_email: string, admin = false) {
        const data = await fetch_with_auth(`team`, id_token, "POST", {
            action: admin ? "add_admin" : "add_user",
            user_email: user_email,
            id: this.id,
        });

        return new Team(data.team);
    }

    async remove_user(id_token: string, user_email: string) {
        const data = await fetch_with_auth(`team`, id_token, "POST", {
            action: "remove_user",
            user_email: user_email,
            id: this.id,
        });

        return new Team(data.team);
    }

    async remove_admin(id_token: string, user_email: string) {
        const data = await fetch_with_auth(`team`, id_token, "POST", {
            action: "remove_admin",
            user_email: user_email,
            id: this.id,
        });

        return new Team(data.team);
    }

    static async get_teams_for_user(id_token: string): Promise<Team[]> {
        const data = await fetch_with_auth(`team`, id_token, "GET");

        // data should be an array of team objects, so we map each object to a Team instance
        return data.map((team: Partial<Team>) => new Team(team));
    }
}
