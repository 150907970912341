import { FnDef } from "./FnDef";
import { Authz } from "./Authz";
import { fetch_with_auth } from "../components/Util";

export class Fn {
    fn_id: string;
    author_id: string;
    author_name: string;
    created_ts: Date | null;
    fn_def: FnDef;
    authz: Authz;
    name: string;
    description: string;
    last_run_id: string;
    credit_cost_estimate: string;

    constructor(fn: Partial<Fn> = {}) {
        this.fn_id = fn.fn_id || "";
        this.author_id = fn.author_id || "";
        this.author_name = fn.author_name || "";
        this.created_ts = fn.created_ts || null;

        //console.log(`fn.fn_def = ${JSON.stringify(fn.fn_def)}`);
        const fnDef = fn.fn_def || {};
        this.fn_def = new FnDef(fnDef);

        const defaultAuthz: Authz = {
            is_public: false,
            emails: [],
            teams: [],
        };

        const authz = fn.authz || defaultAuthz;
        this.authz = new Authz(authz);

        this.name = fn.name || "";
        this.description = fn.description || "";
        this.last_run_id = fn.last_run_id || "";
        this.credit_cost_estimate = fn.credit_cost_estimate || "";
    }

    update(setState: React.Dispatch<React.SetStateAction<Fn | null>>, someValues: Partial<Fn>) {
        const newInstance = new Fn({
            ...this,
            ...someValues,
        });
        setState(newInstance);
    }

    async save_to_db(id_token: string): Promise<string> {
        const data = await fetch_with_auth("fn", id_token, "POST", this);
        this.fn_id = data.fn_id;
        return this.fn_id;
    }

    async delete_from_db(id_token: string): Promise<boolean> {
        return await fetch_with_auth(`fn?fn_id=${this.fn_id}`, id_token, "DELETE");
    }

    static async loadFn(fn_id: string): Promise<Fn> {
        const data = await fetch_with_auth(`fn?fn_id=${fn_id}`, "", "GET");
        const fn = new Fn(data);
        return fn;
    }
}
