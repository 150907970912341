import { FnDef } from "./FnDef";
import { Fn } from "./Fn";
import { UserProfile } from "./UserProfile";
import { apiUrl, fetch_with_auth } from "../components/Util";

type IoValues = { [name: string]: string };

export class FnRun {
    fn_id: string;
    run_id: string;
    space_id: string | null;
    state: string;
    created_ts: Date | null;
    finished_ts: Date | null;
    user_id: string;
    log: string[] | null;
    step_outputs: Record<string, Record<string, string | number | boolean>> | null;
    step_states: Record<string, string> | null;
    run_inputs: IoValues | null;
    run_outputs: IoValues | null;
    credit_cost: number;
    credit_cost_summary: Record<string, [number, number]> | null;
    fn_def: FnDef;

    constructor(fnRun: Partial<FnRun>) {
        this.fn_id = fnRun.fn_id || "";
        this.run_id = fnRun.run_id || "";
        this.space_id = fnRun.space_id || null;
        this.state = fnRun.state || "";
        this.created_ts =
            fnRun.created_ts instanceof Date
                ? fnRun.created_ts
                : fnRun.created_ts
                  ? new Date(fnRun.created_ts)
                  : null;
        this.finished_ts =
            fnRun.finished_ts instanceof Date
                ? fnRun.finished_ts
                : fnRun.finished_ts
                  ? new Date(fnRun.finished_ts)
                  : null;
        this.user_id = fnRun.user_id || "";
        this.log = fnRun.log || null;
        this.step_outputs =
            typeof fnRun.step_outputs === "string"
                ? JSON.parse(fnRun.step_outputs)
                : fnRun.step_outputs || null;
        this.step_states =
            typeof fnRun.step_states === "string"
                ? JSON.parse(fnRun.step_states)
                : fnRun.step_states || null;
        this.run_inputs = fnRun.run_inputs || null;
        this.run_outputs = fnRun.run_outputs || null;
        this.credit_cost = fnRun.credit_cost || 0;
        this.credit_cost_summary = fnRun.credit_cost_summary || null;
        const fnDef: FnDef | string = fnRun.fn_def || "";
        if (typeof fnDef === "string") {
            this.fn_def = JSON.parse(fnDef);
        } else {
            this.fn_def = fnDef;
        }
    }

    update(
        setState: React.Dispatch<React.SetStateAction<FnRun | null>>,
        someValues: Partial<FnRun>,
    ) {
        const newInstance = new FnRun({
            ...this,
            ...someValues,
        });

        setState(newInstance);
    }

    isRunning(): boolean {
        return this.state === "RUNNING" || this.state === "TERMINATING" || this.state === "STARTED";
    }

    static async loadRun(run_id: string): Promise<FnRun> {
        const response = await fetch(`${apiUrl}/fn_run?run_id=${run_id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const fnRun = new FnRun(data);

        return fnRun;
    }

    static async loadFnAndRun(run_id: string): Promise<{ fn: Fn; fnRun: FnRun }> {
        const data = await fetch_with_auth(`fn_run_and_fn?run_id=${run_id}`, "", "GET");
        const fnRun = new FnRun(data.fn_run);
        const fn = new Fn(data.fn);
        return { fn, fnRun };
    }

    static async startRun(fn: Fn, userProfile: UserProfile, spaceId: string): Promise<FnRun> {
        const data = await fetch_with_auth("/start_fn_run", userProfile.id_token || "", "POST", {
            fn: fn,
            space_id: spaceId,
        });

        return new FnRun(data["fn_run"]);
    }
}
