import React, { useCallback, useEffect, useState, useRef } from "react";

import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import MenuIcon from "@mui/icons-material/Menu";
import ShareIcon from "@mui/icons-material/Share";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import SavingsIcon from "@mui/icons-material/Savings";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Tab,
    Tabs,
    TextField,
    Typography,
    IconButton,
    Menu,
    Tooltip,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Column, ColumnMovedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import toast from "react-hot-toast";

import DetailPanel from "../components/DetailPanel";
import SpaceChat from "../components/SpaceChat";
import { fetch_with_auth } from "../components/Util";
import { Space } from "../types/Space";
import { Fn } from "../types/Fn";
import { useUserProfile } from "../context/UserProfileContext";

import type { SpaceChatRef } from "../components/SpaceChat";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

const tooltipStyles = `
  .ag-tooltip {
    max-width: 10vw !important;
    white-space: normal !important;
    overflow-wrap: break-word !important;
  }
`;

type RowDictionary = {
    [key: string]: number | string;
};

interface TableTabLabelProps {
    tableName: string;
    isSelected: boolean;
    isHome: boolean; // true if table_type for table is 'n'
    onDownloadClick: () => void;
    onDeleteClick: () => void;
    onShareClick: () => void;
    onRenameClick: () => void;
}

const TableTabLabel: React.FC<TableTabLabelProps> = ({
    tableName,
    isSelected,
    isHome,
    onDownloadClick,
    onDeleteClick,
    onShareClick,
    onRenameClick,
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box display="flex" alignItems="center">
            <Typography variant="body2" sx={{ textTransform: "none" }}>
                {tableName}
            </Typography>
            {isSelected && (
                <>
                    <IconButton
                        onClick={handleMenuOpen}
                        size="small"
                        sx={{ ml: 0.5, padding: "2px" }}>
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}>
                        {isHome && (
                            <MenuItem
                                onClick={() => {
                                    handleMenuClose();
                                    onRenameClick();
                                }}>
                                <DriveFileRenameOutlineIcon sx={{ mr: 1 }} />
                                Rename
                            </MenuItem>
                        )}
                        <MenuItem
                            onClick={() => {
                                handleMenuClose();
                                onDownloadClick();
                            }}>
                            <DownloadIcon sx={{ mr: 1 }} />
                            Download
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                handleMenuClose();
                                onDeleteClick();
                            }}>
                            <DeleteIcon color="error" sx={{ mr: 1 }} />
                            Delete
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                handleMenuClose();
                                onShareClick();
                            }}>
                            <ShareIcon sx={{ mr: 1 }} />
                            Share
                        </MenuItem>
                    </Menu>
                </>
            )}
        </Box>
    );
};

const SpacePage = () => {
    const [contextMenuPosition, setContextMenuPosition] = useState<{ x: number; y: number }>({
        x: 0,
        y: 0,
    });

    // State variables for current table dropdown menu.
    const [openRenameTableDialog, setOpenRenameTableDialog] = useState<boolean>(false);
    const [newTableName, setNewTableName] = useState<string>("");

    // State variables for column rename dialog
    const [openRenameColumnDialog, setOpenRenameColumnDialog] = useState<boolean>(false);
    const [selectedColumn, setSelectedColumn] = useState<string>("");
    const [newColumnName, setNewColumnName] = useState<string>("");

    const [openShareTableDialog, setOpenShareTableDialog] = useState<boolean>(false);
    const [selectedConsumerSpace, setSelectedConsumerSpace] = useState<string>("");

    const [openDeleteTableDialog, setOpenDeleteTableDialog] = useState<boolean>(false);
    const [openAddTableDialog, setOpenAddTableDialog] = useState<boolean>(false);
    // End of state variables for current table dropdown menu.

    const [selectedFile, setSelectedFile] = useState<string>("");
    const [dialogTableName, setDialogTableName] = useState<string>("");

    const [tableGridData, setTableGridData] = useState<{
        [spaceId: string]: { [tableName: string]: any[] };
    }>({});
    const [tableColumnDefs, setTableColumnDefs] = useState<{
        [spaceId: string]: { [tableName: string]: any[] };
    }>({});
    const [loadedTableTimestamps, setLoadedTableTimestamps] = useState<{
        [spaceId: string]: { [tableName: string]: Date };
    }>({});
    const [selectedTableBySpace, setSelectedTableBySpace] = useState<{
        [spaceId: string]: string;
    }>({});

    // Store Fn objects for all spaces
    const [spaceFunctions, setSpaceFunctions] = useState<{
        [spaceId: string]: { [functionId: string]: Fn };
    }>({});

    const gridApiRefs = useRef<{ [spaceId: string]: { [tableName: string]: any } }>({});

    const [selectedRows, setSelectedRows] = useState<any[]>([]);

    const [chatVisible, setChatVisible] = useState<boolean>(true);
    const [files, setFiles] = useState<string[]>([]);

    const gridRef = useRef<HTMLDivElement>(null);
    const chatRef = useRef<SpaceChatRef>(null);

    const [selectedSpaceId, setSelectedSpaceId] = useState<string>("");
    const [spaces, setSpaces] = useState<Space[]>([]);
    const { userProfile } = useUserProfile();

    // DetailPanel state below:
    const [detailPanelVisible, setDetailPanelVisible] = useState<boolean>(false);
    const [selectedRowData, setSelectedRowData] = useState<any | null>(null);
    const [detailPanelWidth, setDetailPanelWidth] = useState<number>(200);
    const [isResizingDetailPanel, setIsResizingDetailPanel] = useState<boolean>(false);

    const navigate = useNavigate();
    const location = useLocation();

    // CHAT WIDTH dividing/slider logic:
    // Default to 30% of current window width (in px).
    const [chatWidth, setChatWidth] = useState<number>(window.innerWidth * 0.3);

    // Track whether user is dragging the resizer.
    const [isResizingChat, setIsResizingChat] = useState<boolean>(false);

    //-------- Helper functions to get and set 2 level map state variables
    // Helper to get current space object
    const getCurrentSpace = React.useCallback((): Space | undefined => {
        return spaces.find((s) => s.space_id === selectedSpaceId);
    }, [spaces, selectedSpaceId]);

    const getCurrentSpaceById = React.useCallback(
        (spaceId: string): Space | undefined => {
            return spaces.find((s) => s.space_id === spaceId);
        },
        [spaces],
    );

    // Helper to get the *current* table name for the selected space
    const getCurrentTable = React.useCallback((): string | undefined => {
        if (!selectedSpaceId) return undefined;
        const current = selectedTableBySpace[selectedSpaceId];
        if (current) return current;

        const currentSpace = getCurrentSpace();
        if (!currentSpace) return undefined;

        const tableList = Object.keys(currentSpace.table_type);
        if (tableList.length > 0) {
            return tableList[0];
        }
        return undefined;
    }, [selectedSpaceId, selectedTableBySpace, spaces]);

    // Helper to set the current table name for the selected space
    const setCurrentTable = React.useCallback(
        (table: string) => {
            if (!selectedSpaceId) return;
            setSelectedTableBySpace((prev) => ({
                ...prev,
                [selectedSpaceId]: table,
            }));
        },
        [selectedSpaceId],
    );

    const fetchAllFnsForSpaces = useCallback(
        async (spaces: Space[]) => {
            if (!spaces.length) return;

            // Collect all unique function IDs from all spaces
            const allFunctionIds = new Set<string>();
            spaces.forEach((space) => {
                if (space.functions && Object.keys(space.functions).length > 0) {
                    Object.values(space.functions).forEach((fnId) => {
                        allFunctionIds.add(fnId as string);
                    });
                }
            });

            if (allFunctionIds.size === 0) return;

            try {
                // Fetch function details in batch
                const fnIdsParam = Array.from(allFunctionIds).join(",");
                const response = await fetch_with_auth(
                    `fn?fn_ids=${fnIdsParam}`,
                    userProfile.id_token || "",
                    "GET",
                );

                if (response) {
                    // Convert all fetched functions to Fn objects
                    const allFnMap: { [id: string]: Fn } = {};
                    for (const [id, fnData] of Object.entries(
                        response as Record<string, Partial<Fn>>,
                    )) {
                        allFnMap[id] = new Fn(fnData);
                    }

                    // Organize functions by space
                    const newSpaceFunctions: { [spaceId: string]: { [functionId: string]: Fn } } =
                        {};

                    spaces.forEach((space) => {
                        if (space.functions && Object.keys(space.functions).length > 0) {
                            newSpaceFunctions[space.space_id] = {};

                            Object.entries(space.functions).forEach(([fnName, fnId]) => {
                                if (allFnMap[fnId as string]) {
                                    newSpaceFunctions[space.space_id][fnId as string] =
                                        allFnMap[fnId as string];
                                }
                            });
                        }
                    });

                    setSpaceFunctions((prev) => {
                        // Merge previous state with new functions
                        const merged = { ...prev };

                        // Update each space's functions
                        Object.entries(newSpaceFunctions).forEach(([spaceId, spaceFns]) => {
                            merged[spaceId] = { ...(merged[spaceId] || {}), ...spaceFns };
                        });

                        return merged;
                    });
                }
            } catch (error) {
                console.error("Error fetching function details:", error);
            }
        },
        [userProfile.id_token],
    );

    // Helper to read or write from tableGridData
    const getGridData = (spaceId: string, tableName: string): any[] => {
        return tableGridData[spaceId]?.[tableName] || [];
    };
    const setGridData = (spaceId: string, tableName: string, rows: any[]) => {
        setTableGridData((prev) => {
            const spaceObj = prev[spaceId] || {};
            return {
                ...prev,
                [spaceId]: {
                    ...spaceObj,
                    [tableName]: rows,
                },
            };
        });
    };

    const getColumnDescriptions = useCallback(() => {
        const currentSpace = getCurrentSpace();
        if (!currentSpace) return {};

        const currentTable = getCurrentTable();
        if (!currentTable) return {};

        return currentSpace.table_md[currentTable]?.column_desc || {};
    }, [getCurrentSpace, getCurrentTable]);

    // Helper for tableColumnDefs
    const getColumnDefs = (spaceId: string, tableName: string): any[] => {
        return tableColumnDefs[spaceId]?.[tableName] || [];
    };
    const setColumnDefs = (spaceId: string, tableName: string, defs: any[]) => {
        setTableColumnDefs((prev) => {
            const spaceObj = prev[spaceId] || {};
            return {
                ...prev,
                [spaceId]: {
                    ...spaceObj,
                    [tableName]: defs,
                },
            };
        });
    };

    // Helper for loadedTableTimestamps
    const getLoadedTs = (spaceId: string, tableName: string): Date => {
        // If no timestamp is stored, return a date from 1989 to force reloading.
        return loadedTableTimestamps[spaceId]?.[tableName] || new Date("1989-01-01T00:00:00Z");
    };
    const setLoadedTs = (spaceId: string, tableName: string, ts: Date) => {
        setLoadedTableTimestamps((prev) => {
            const spaceObj = prev[spaceId] || {};
            return {
                ...prev,
                [spaceId]: {
                    ...spaceObj,
                    [tableName]: ts,
                },
            };
        });
    };
    //-------- End of (Helper functions to get and set 2 level map state varaibles)

    // onMouseDown on the divider: begin listening for mouse moves
    const handleMouseDownOnChatResizer = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsResizingChat(true);
    };

    // onMouseMove: if in "resizing" mode, update chatWidth
    const handleMouseMoveOnChatResizer = (e: MouseEvent) => {
        if (!isResizingChat) return;
        // e.clientX is the mouse's position from the left of the viewport
        // You can clamp min=200, max=80% of container, etc.
        const newWidth = Math.max(
            200,
            Math.min(window.innerWidth - e.clientX, window.innerWidth - 200),
        );
        setChatWidth(newWidth);
    };

    // onMouseUp: stop resizing
    const handleMouseUpOnChatResizer = () => {
        setIsResizingChat(false);
    };

    const handleDetailPanelResizeStart = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsResizingDetailPanel(true);
    };

    const handleDetailPanelResize = (e: MouseEvent) => {
        if (!isResizingDetailPanel) return;
        const newWidth = Math.max(
            200,
            Math.min(800, window.innerWidth - e.clientX - (chatVisible ? chatWidth : 0)),
        );
        setDetailPanelWidth(newWidth);
    };

    const handleDetailPanelResizeEnd = () => {
        setIsResizingDetailPanel(false);
    };

    useEffect(() => {
        if (isResizingChat) {
            document.addEventListener("mousemove", handleMouseMoveOnChatResizer);
            document.addEventListener("mouseup", handleMouseUpOnChatResizer);
        } else {
            document.removeEventListener("mousemove", handleMouseMoveOnChatResizer);
            document.removeEventListener("mouseup", handleMouseUpOnChatResizer);
        }
        return () => {
            document.removeEventListener("mousemove", handleMouseMoveOnChatResizer);
            document.removeEventListener("mouseup", handleMouseUpOnChatResizer);
        };
    }, [isResizingChat]);

    // END OF CHAT WIDTH LOGIC

    // START OF DETAIL PANEL LOGIC

    useEffect(() => {
        if (isResizingDetailPanel) {
            document.addEventListener("mousemove", handleDetailPanelResize);
            document.addEventListener("mouseup", handleDetailPanelResizeEnd);
        } else {
            document.removeEventListener("mousemove", handleDetailPanelResize);
            document.removeEventListener("mouseup", handleDetailPanelResizeEnd);
        }
        return () => {
            document.removeEventListener("mousemove", handleDetailPanelResize);
            document.removeEventListener("mouseup", handleDetailPanelResizeEnd);
        };
    }, [isResizingDetailPanel]);

    const handleRowClick = (params: any) => {
        if (params.node && params.node.data) {
            setSelectedRowData(params.node.data);
            setDetailPanelVisible(true);
        }
    };

    const handleCloseDetailPanel = () => {
        setDetailPanelVisible(false);
    };

    // Add global click handler to close panel on right-click or non-row clicks
    useEffect(() => {
        const handleGlobalClick = (event: MouseEvent) => {
            // Don't close panel if we're currently resizing
            if (isResizingDetailPanel) {
                return;
            }

            if (event.button === 2 && detailPanelVisible) {
                // Right click
                setDetailPanelVisible(false);
                return;
            }

            // Only check for non-row clicks if panel is visible
            if (detailPanelVisible) {
                const clickedElement = event.target as HTMLElement;
                const isRowClick = clickedElement.closest(".ag-row") !== null;
                const isDetailPanelClick = clickedElement.closest("#detail-panel") !== null;
                const isResizeDividerClick =
                    clickedElement.closest(".panel-resize-divider") !== null;

                if (!isRowClick && !isDetailPanelClick && !isResizeDividerClick) {
                    setDetailPanelVisible(false);
                }
            }
        };

        document.addEventListener("mousedown", handleGlobalClick);

        return () => {
            document.removeEventListener("mousedown", handleGlobalClick);
        };
    }, [detailPanelVisible, isResizingDetailPanel]);

    // END OF DETAIL PANEL LOGIC

    useEffect(() => {
        if (userProfile.id_token) {
            fetchFiles(userProfile.id_token);
            setSelectedFile("");
        }
    }, [userProfile.id_token]);

    useEffect(() => {
        const initializeSpaces = async () => {
            if (userProfile.id_token) {
                const newSpaces = await fetchSpaces(userProfile.id_token);
                const searchParams = new URLSearchParams(location.search);
                const spaceId = searchParams.get("space_id");

                if (spaceId && spaceId !== selectedSpaceId) {
                    setSelectedSpaceId(spaceId);
                } else if (!spaceId && !selectedSpaceId && newSpaces.length > 0) {
                    // Select first space if none are selected
                    const newSpaceId = newSpaces[0].space_id;
                    setSelectedSpaceId(newSpaceId);

                    searchParams.set("space_id", newSpaceId);
                    navigate(`/space?${searchParams.toString()}`, { replace: true });
                }
            }
        };

        initializeSpaces();
    }, [userProfile.id_token]);

    useEffect(() => {
        //console.log("useEffect -> renderSpace()");
        renderSpace();
    }, [spaces, selectedSpaceId]);

    // Add effect to capture right-click positions
    useEffect(() => {
        const captureRightClick = (e: MouseEvent) => {
            // Only update for right-clicks
            if (e.button === 2 || e.type === "contextmenu") {
                setContextMenuPosition({ x: e.pageX, y: e.pageY });
            }
        };

        // Listen for both mousedown and contextmenu events to ensure we catch it
        document.addEventListener("mousedown", captureRightClick);
        document.addEventListener("contextmenu", captureRightClick);

        return () => {
            document.removeEventListener("mousedown", captureRightClick);
            document.removeEventListener("contextmenu", captureRightClick);
        };
    }, []);

    // Ensure that default event listener is not going to trigger when right-clicking on the ag-grid spreadsheet.
    useEffect(() => {
        const handleContextMenu = (event: MouseEvent) => {
            event.preventDefault();
        };
        const gridContainer = gridRef.current;
        if (gridContainer) {
            gridContainer.addEventListener("contextmenu", handleContextMenu as EventListener);
        } else {
            console.log("Grid container not found, this should not happen");
        }
        return () => {
            if (gridContainer) {
                gridContainer.removeEventListener(
                    "contextMenu",
                    handleContextMenu as EventListener,
                );
            }
        };
    }, [gridRef]);

    // Adding an event listener to hide the context menu when clicking outside the popup menu
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const contextMenu = document.getElementById("contextMenu");
            if (contextMenu && !contextMenu.contains(event.target)) {
                contextMenu.style.display = "none";
            }

            const columnMenu = document.getElementById("columnContextMenu");
            if (columnMenu && !columnMenu.contains(event.target)) {
                columnMenu.style.display = "none";
            }
        };
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    // Deriving table names from the currently selected space's table_type.
    const renderSpace = () => {
        const cSpace = getCurrentSpace();
        if (cSpace && userProfile.id_token) {
            const searchParams = new URLSearchParams(location.search);
            const tableNameParam = searchParams.get("table_name");

            let cTable = getCurrentTable();

            // If there's a table_name parameter and the table exists in the current space, switch to it
            if (tableNameParam && tableNameParam in cSpace.table_type) {
                cTable = tableNameParam;
            }

            if (cTable) {
                searchParams.set("table_name", cTable);
                navigate(`/space?${searchParams.toString()}`, { replace: true });
            }

            loadTableDataIfNeeded(cTable || "");
            // This is prone to over-rendering, consider only setting when actually transitioning undefined -> table_name.
            setCurrentTable(cTable || "");
        }
    };

    const loadTableDataIfNeeded = async (tableName: string) => {
        if (!selectedSpaceId) return;

        const currentSpace = getCurrentSpace();
        if (!currentSpace) return;
        if (!(tableName in currentSpace.table_type)) return;

        const tableMetadata = currentSpace.table_md[tableName];
        if (!tableMetadata?.last_mutation_ts) return;

        const lastMutationTs = new Date(tableMetadata.last_mutation_ts);
        const loadedTs = getLoadedTs(currentSpace.space_id, tableName);

        if (!loadedTs || loadedTs.getTime() < lastMutationTs.getTime()) {
            await loadTableData(selectedSpaceId, tableName, userProfile.id_token || "");
        }
    };

    const handleSpaceChange = async (event: SelectChangeEvent<string>) => {
        const spaceId = event.target.value as string;
        setSelectedSpaceId(spaceId);
        navigate(`/space?space_id=${spaceId}`);
    };

    const fetchSpaces = async (idToken: string) => {
        const newSpaces = await Space.get_spaces_for_user(idToken);
        setSpaces(newSpaces);
        fetchAllFnsForSpaces(newSpaces);
        return newSpaces;
    };

    const storeSpaceUpdates = async (space: Space) => {
        await fetch_with_auth("space", userProfile.id_token || "", "POST", space);
        setSpaces((prevSpaces) => {
            const newSpaces = prevSpaces.map((s) => (s.space_id === space.space_id ? space : s));
            return newSpaces;
        });
    };

    const loadSpaceUpdates = async (spaceId: string): Promise<Space> => {
        const refreshedSpace: Space = await Space.loadSpace(spaceId, userProfile.id_token || "");
        setSpaces((prevSpaces) => {
            const newSpaces = prevSpaces.map((s) => (s.space_id === spaceId ? refreshedSpace : s));
            return newSpaces;
        });
        return refreshedSpace;
    };

    const ensureCurrentSpaceFresh = async () => {
        if (!selectedSpaceId) return;
        try {
            const refreshedSpace: Space = await loadSpaceUpdates(selectedSpaceId);
            const newTables = Object.keys(refreshedSpace.table_type);
            const cTable = getCurrentTable();

            if (!newTables.includes(cTable || "")) {
                if (newTables.length > 0) {
                    const newFirstTable = newTables[0];
                    setCurrentTable(newFirstTable);
                    loadTableData(
                        refreshedSpace.space_id,
                        newFirstTable,
                        userProfile.id_token || "",
                    );
                } else {
                    setCurrentTable("");
                }
            } else {
                loadTableDataIfNeeded(cTable || "");
            }
        } catch (err) {
            console.error("ensureCurrentSpaceFresh error:", err);
        }
    };

    const handleSpaceDataChanged = async () => {
        ensureCurrentSpaceFresh();
    };

    const fetchFiles = async (idToken: string) => {
        try {
            const response = await fetch_with_auth("list_all_files", idToken, "GET");
            setFiles(response.files);
        } catch (error) {
            console.error("Error fetching files:", error);
        }
    };

    const handleColumnContextMenu = useCallback(
        (params: any, table: string) => {
            const columnId = params.column.getColId();
            if (!columnId || columnId === "idx") return; // Do not allow renaming 'idx' column

            const currentSpace = getCurrentSpace();
            if (!currentSpace || !table) return;

            const isNativeTable = currentSpace.table_type[table] === "n";
            if (!isNativeTable) return;

            const columnMenu = document.getElementById("columnContextMenu");
            if (columnMenu) {
                // Use the captured right-click position from our event listener
                columnMenu.style.top = `${contextMenuPosition.y}px`;
                columnMenu.style.left = `${contextMenuPosition.x}px`;
                columnMenu.style.transform = ""; // Clear any transform
                columnMenu.style.display = "block";
                columnMenu.setAttribute("data-column-id", params.column.getColId());
                columnMenu.setAttribute("data-table", table);
            }
        },
        [getCurrentSpace, contextMenuPosition],
    );

    const handleRenameColumnCtxMenuOptionSelected = () => {
        const columnMenu = document.getElementById("columnContextMenu");
        if (!columnMenu) return;

        const columnId = columnMenu.getAttribute("data-column-id") || "";
        setSelectedColumn(columnId);
        setNewColumnName(columnId);
        setOpenRenameColumnDialog(true);
        columnMenu.style.display = "none";
    };

    const handleCellContextMenu = useCallback((params: any, table: string) => {
        params.event.preventDefault();
        params.event.stopPropagation();

        if (params.node.data.idx !== "") {
            const contextMenu = document.getElementById("contextMenu");
            if (contextMenu) {
                contextMenu.style.top = `${params.event.pageY}px`;
                contextMenu.style.left = `${params.event.pageX}px`;
                contextMenu.style.display = "block";
                contextMenu.setAttribute("data-row-index", params.node.rowIndex);
                contextMenu.setAttribute("data-idx", params.node.data.idx);
                contextMenu.setAttribute("data-table", table);
            }
        }
    }, []);

    const handleColumnRenameButtonClicked = async () => {
        if (!selectedSpaceId || !newColumnName || newColumnName === selectedColumn) {
            return;
        }
        const currentTable = getCurrentTable();
        if (!currentTable) return;
        try {
            await fetch_with_auth("space_rename_column", userProfile.id_token || "", "POST", {
                space_id: selectedSpaceId,
                table_name: currentTable,
                old_col_name: selectedColumn,
                new_col_name: newColumnName,
            });
            toast.success(`Column renamed successfully.`);
            setOpenRenameColumnDialog(false);
            // Refresh data to show the renamed column
            await ensureCurrentSpaceFresh();
            await loadTableData(selectedSpaceId, currentTable, userProfile.id_token || "");
        } catch (error: any) {
            toast.error(`Error renaming column: ${error.message}`);
        }
    };

    // Helper to enhance column definitions with tooltips and context menu support
    const enhanceColumnDefs = useCallback(
        (spaceId: string, tableName: string, defs: any[]) => {
            const currentSpace = getCurrentSpaceById(spaceId);
            if (!currentSpace) return defs;
            const isNativeTable = currentSpace.table_type[tableName] === "n";
            const tableMd = currentSpace.table_md[tableName] || {};

            return defs.map((def) => {
                // Clone the definition to avoid mutating the original
                const newDef = { ...def };
                const description = tableMd.column_desc?.[def.field] || "";
                //const hasColumnValues = tableMd.column_values && def.field in tableMd.column_values;

                if (description) {
                    newDef.headerTooltip = description;
                }

                // Allow right-click on column headers
                newDef.suppressHeaderContextMenu = false;

                // Only allow editing for native tables
                if (!isNativeTable) {
                    newDef.editable = false;
                }

                // Lock the idx column from being edited
                if (newDef.field === "idx") {
                    newDef.editable = false;
                }

                return newDef;
            });
        },
        [getCurrentSpaceById],
    );

    const handleAddRow = async () => {
        const contextMenu = document.getElementById("contextMenu");
        if (contextMenu) {
            const rowIndex = parseInt(contextMenu.getAttribute("data-row-index") || "0", 10);
            const idx = parseInt(contextMenu.getAttribute("data-idx") || "0", 10);
            const newIdx = idx + 1;

            // Close the context menu
            contextMenu.style.display = "none";

            // Use the helper to get the currently selected table for the current space
            const currentTable = getCurrentTable();
            if (!selectedSpaceId || !currentTable) return;

            // Get the current grid data for this space and table
            const currentRows = getGridData(selectedSpaceId, currentTable);

            // Create a new empty row with only the idx field set.
            const newRow: RowDictionary = { idx: newIdx };
            // Use our helper to get the current column defs:
            const currentColDefs = getColumnDefs(selectedSpaceId, currentTable);
            currentColDefs.forEach((colDef) => {
                if (colDef.field !== "idx") {
                    newRow[colDef.field as string] = "";
                }
            });

            // Insert new row at the proper index.
            const updatedData = [...currentRows];
            updatedData.splice(rowIndex + 1, 0, newRow);
            for (let i = rowIndex + 2; i < updatedData.length; i++) {
                const currentIdx = parseInt(updatedData[i].idx);
                if (isNaN(currentIdx)) {
                    console.error(`Invalid idx value at row ${i}: ${updatedData[i].idx}`);
                } else {
                    updatedData[i].idx = currentIdx + 1;
                }
            }
            setGridData(selectedSpaceId, currentTable, updatedData);

            await fetch_with_auth("space_table_row", userProfile.id_token || "", "POST", {
                action: "ADD",
                idx: newIdx,
                space_id: selectedSpaceId,
                table_name: currentTable,
            });
        }
    };

    const handleAddColumn = async () => {
        const contextMenu = document.getElementById("contextMenu");
        if (contextMenu) {
            contextMenu.style.display = "none";

            const currentTable = getCurrentTable();
            if (!selectedSpaceId || !currentTable) return;

            const currentDefs = getColumnDefs(selectedSpaceId, currentTable);
            const newColumnDefs = [...currentDefs];
            newColumnDefs.push({
                headerName: "new_column_name",
                field: "new_column_name",
                editable: true,
            });
            setColumnDefs(selectedSpaceId, currentTable, newColumnDefs);

            await fetch_with_auth("space_table", userProfile.id_token || "", "POST", {
                space_id: selectedSpaceId,
                table_name: currentTable,
                columns: newColumnDefs.map((col) => col.field),
            });
        }
    };

    const handleDeleteRow = async () => {
        const contextMenu = document.getElementById("contextMenu");
        if (contextMenu) {
            const rowIndex = parseInt(contextMenu.getAttribute("data-row-index") || "0", 10);
            const idx = parseInt(contextMenu.getAttribute("data-idx") || "0", 10);

            contextMenu.style.display = "none";

            const currentTable = getCurrentTable();
            if (!selectedSpaceId || !currentTable) return;

            const currentRows = getGridData(selectedSpaceId, currentTable);
            const updatedData = currentRows.filter((_, index) => index !== rowIndex);
            for (let i = rowIndex; i < updatedData.length; i++) {
                updatedData[i].idx = parseInt(updatedData[i].idx) - 1;
            }
            setGridData(selectedSpaceId, currentTable, updatedData);

            await fetch_with_auth("space_table_row", userProfile.id_token || "", "POST", {
                action: "DELETE",
                idx: idx,
                space_id: selectedSpaceId,
                table_name: currentTable,
            });
        }
    };

    const loadTableData = useCallback(
        async (spaceId: string, tableName: string, idToken: string) => {
            try {
                const response = await fetch_with_auth(
                    `space_table?space_id=${spaceId}&table_name=${tableName}`,
                    idToken,
                    "GET",
                );
                const currentSpace = getCurrentSpaceById(spaceId);
                if (!currentSpace) {
                    console.log(`This should not happen, couldn't find space with id = ${spaceId}`);
                    return;
                } else {
                    let columnOrder = currentSpace.table_md[tableName]?.columns || [];

                    // Ensure idx column is always in front.
                    const idx_pos = columnOrder.indexOf("idx");
                    if (idx_pos > 0) {
                        columnOrder = [
                            columnOrder[idx_pos],
                            ...columnOrder.slice(0, idx_pos),
                            ...columnOrder.slice(idx_pos + 1),
                        ];
                    }

                    const newColumnDefs = columnOrder.map((column: string) => {
                        const maybeRemWidth =
                            currentSpace.table_md[tableName]?.column_width?.[column];
                        let widthPx: number | undefined = undefined;
                        if (typeof maybeRemWidth === "number") {
                            widthPx = Math.round(maybeRemWidth * 16); // rem -> px
                        }
                        // fallback to something if undefined
                        return {
                            headerName: column,
                            field: column,
                            editable: true,
                            width: widthPx, // If undefined, Ag-Grid auto-sizes. If numeric, that's the starting width
                        };
                    });

                    const transformedData = response.data.rows.map((row: any[]) => {
                        const rowData: RowDictionary = {};
                        columnOrder.forEach((colName: string) => {
                            const dataPos = response.data.columns.indexOf(colName);
                            rowData[colName] = row[dataPos];
                        });
                        return rowData;
                    });

                    // Apply column enhancements (tooltips, etc.)
                    const enhancedColumnDefs = enhanceColumnDefs(spaceId, tableName, newColumnDefs);

                    setColumnDefs(spaceId, tableName, enhancedColumnDefs);
                    setGridData(spaceId, tableName, transformedData);
                    setLoadedTs(spaceId, tableName, new Date());
                }
            } catch (error: any) {
                toast.error(`Error fetching table ${tableName} data: ${error.message}`);
            }
        },
        [spaces, enhanceColumnDefs],
    );

    const handleFileChange = (event: SelectChangeEvent<string>) => {
        setSelectedFile(event.target.value);
    };

    const handleTableNameChangeInDialog = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDialogTableName(event.target.value);
    };

    const handlePopulateData = async () => {
        try {
            if (selectedSpaceId) {
                const body = {
                    file_name: selectedFile,
                    space_id: selectedSpaceId,
                    table_name: dialogTableName,
                };
                await fetch_with_auth(
                    "space_spawn_table_from_file",
                    userProfile.id_token || "",
                    "POST",
                    body,
                );
                toast.dismiss();
                toast.success(`Populated table ${dialogTableName} successfully`);
                // Make newly created table on top (selected)
                // First make sure that frontend is aware of most accurate set of tables in current space.
                ensureCurrentSpaceFresh();
                switchToTable(dialogTableName);
                setOpenAddTableDialog(false);
            }
        } catch (error: any) {
            toast.error(`Error populating data: ${error.message}`);
        }
    };

    const switchToTable = (newTableName: string) => {
        if (!selectedSpaceId) {
            return;
        }
        setCurrentTable(newTableName);
        loadTableDataIfNeeded(newTableName);

        // Update the URL to include the new table name
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("table_name", newTableName);
        navigate(`/space?${searchParams.toString()}`, { replace: true });

        // Allow the new grid to render and then refresh its view.
        setTimeout(() => {
            const api = gridApiRefs.current[selectedSpaceId]?.[newTableName];
            if (api) {
                api.refreshCells();
            }
        }, 0);
    };

    const handleTabNavigation = (event: React.ChangeEvent<any>, clickedTabName: string) => {
        if (clickedTabName !== "add_table") {
            switchToTable(clickedTabName);
        }
    };

    const handleRowSelection = (event: any, table: string) => {
        setSelectedRows(event.api.getSelectedRows());
    };

    const handleDownloadCSV = async () => {
        const cTable = getCurrentTable();
        if (!selectedSpaceId || !cTable) {
            toast.error("No table selected for download.");
            return;
        }

        const url = `space_export_table?space_id=${selectedSpaceId}&table_name=${cTable}`;
        const result_blob = await fetch_with_auth(
            url,
            userProfile.id_token || "",
            "GET",
            null /* body */,
            true /* return_blob */,
        );

        const downloadUrl = window.URL.createObjectURL(result_blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = downloadUrl;
        a.download = `${cTable}.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(downloadUrl); // Clean up the URL object
        a.remove();
    };

    const handleRenameTableClick = () => {
        // When the user clicks rename, prefill with the current table name.
        const cTable = getCurrentTable();
        setNewTableName(cTable || "");
        setOpenRenameTableDialog(true);
    };

    const handleDeleteTableClick = () => {
        setOpenDeleteTableDialog(true);
    };

    const handleCloseDeleteTableDialog = () => {
        setOpenDeleteTableDialog(false);
    };

    const handleOpenAddTableDialog = () => {
        setOpenAddTableDialog(true);
    };

    const handleCloseAddTableDialog = () => {
        setOpenAddTableDialog(false);
    };

    const handleConfirmDelete = async () => {
        const cTable = getCurrentTable();
        try {
            if (selectedSpaceId) {
                const url = `space_table?space_id=${selectedSpaceId}&table_name=${cTable}`;
                await fetch_with_auth(url, userProfile.id_token || "", "DELETE");

                toast.success(`Table ${cTable} deleted successfully.`);
                setOpenDeleteTableDialog(false);
                ensureCurrentSpaceFresh();
            }
        } catch (error: any) {
            toast.error(`Error deleting table ${cTable}: ${error.message}`);
        }
    };

    const toggleChatVisibility = () => {
        setChatVisible((prev) => !prev);
        // Ensure the page scrolls to the top
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);
    };

    const onColumnMoved = async (event: ColumnMovedEvent, table: string) => {
        if (event.finished && event.column) {
            // The desired behavior for this method to do something only when user manually dragged and dropped
            // column in new spot. To ensure it we are checking for:
            // (1) event.finished for being True so that to not act upon incomplete column movement where
            // user did not stop dragging just yet. I.e. they could start dragging and already change the position of the
            // column but did not release mouse-1 yet.
            // (2) event.column being set to True-y value which is only the case when single column is moved.
            // When switching between tables it is re-creating all the columns thus triggering onColumnMoved callback.

            // Note that "idx" column is special and should not be movable.
            if (event.column.getColId() === "idx") {
                console.log(
                    "This should not be happening as idx column should always be pinned in the first place",
                );
                event.api.setColumnsPinned(["idx"], "left");
                return;
            }

            const newColumnOrder = event.api
                .getAllGridColumns()
                .map((col: Column) => col.getColId());
            // Update Space object: update table_md for the given table with the new column order.
            // TODO: ensure that user cannot change order of columns in the read-only table.
            const updatedSpaces = spaces.map((space) => {
                if (space.space_id === selectedSpaceId) {
                    return {
                        ...space,
                        table_md: {
                            ...space.table_md,
                            [table]: {
                                ...(space.table_md[table] || {}),
                                columns: newColumnOrder,
                            },
                        },
                    } as Space;
                }
                return space;
            });

            const updatedSpace = updatedSpaces.find((space) => space.space_id === selectedSpaceId);
            if (!updatedSpace) {
                console.error("Could not find current space in updated spaces.");
                return;
            }
            try {
                await storeSpaceUpdates(updatedSpace);
                toast.success(`Column order for table ${table} saved successfully.`);
            } catch (error) {
                toast.error(`Failed to save column order for table ${table}.`);
                return;
            }
        }
    };

    // We assume 1 rem = 16 px. If your page has a different root font-size, adjust accordingly.
    const PX_PER_REM = 16;

    const handleColumnResized = async (event: any, table: string) => {
        // The 'finished' flag is true if the user just released the mouse
        // meaning the resize is complete. If it's not finished, the user might still be dragging.
        if (!event.finished || event.source !== "uiColumnResized") {
            return;
        }

        // Grab the new column widths from the column state.
        // Each item in columnState is like: { colId: string; width: number; ...}
        const columnState = event.api.getColumnState();
        if (!columnState) return;

        // Prepare a new "column_width" dictionary
        const newColumnWidthMap: { [colName: string]: number } = {};

        columnState.forEach((state: any) => {
            // Convert px -> rem
            // If the user hasn't manually set a width, 'width' might be undefined.
            // Typically, 'width' is always numeric after a resize, but let's be safe.
            if (typeof state.width === "number") {
                const widthRem = state.width / PX_PER_REM;
                newColumnWidthMap[state.colId] = widthRem;
            }
        });

        // We'll look up the "current table" from getCurrentTable() if needed,
        // or we can rely on "table" param.
        // Then we do the same procedure, but store in 2-level structure.

        if (!selectedSpaceId) return;
        const cSpace = getCurrentSpace();
        if (!cSpace) return;

        const newSpaces = spaces.map((sp) => {
            if (sp.space_id !== selectedSpaceId) return sp;

            const newSpace = new Space(sp);
            const tableMd = { ...newSpace.table_md[table] };
            const colWidthMap = { ...(tableMd.column_width || {}) };

            for (const colId in newColumnWidthMap) {
                colWidthMap[colId] = newColumnWidthMap[colId];
            }
            tableMd.column_width = colWidthMap;
            tableMd.last_mutation_ts = new Date();
            newSpace.table_md = {
                ...newSpace.table_md,
                [table]: tableMd,
            };
            return newSpace;
        });

        const updatedSpace = newSpaces.find((s) => s.space_id === selectedSpaceId);
        if (!updatedSpace) return;

        // We also update our local "spaces" right away
        setSpaces(newSpaces);

        try {
            //setSpaces(updatedSpaces);
            await storeSpaceUpdates(updatedSpace);
            // toast.success("Column widths saved!");
            // Update local `spaces` state with the newSpace object
        } catch (error) {
            console.error("Failed to save updated column widths:", error);
            toast.error("Could not save column widths");
        }
    };

    const handleCellValueChanged = useCallback(
        async (params: any, table: string) => {
            const updatedRow = params.data;
            const updatedRowJson = JSON.stringify(updatedRow);
            const idx = updatedRow.idx;

            await fetch_with_auth("space_table_row", userProfile.id_token || "", "POST", {
                action: "EDIT",
                idx: idx,
                row: updatedRowJson,
                space_id: selectedSpaceId,
                table_name: table,
            });
        },
        [userProfile.id_token, selectedSpaceId],
    );

    const removeBracketedParts = (fnName: string): string => {
        // E.g. "MyFunc [rows] [some-other-info]" => "MyFunc "
        return fnName.replace(/\[.*?\]/g, "").trim();
    };

    // When user clicks on one of the function items in the context menu
    // that is applicable to selected rows:
    const handleApplyFnToSelectedRows = async (fnId: string, fnName: string) => {
        // Call a method on the <SpaceChat> (the chat component) to handle spawning
        // the function runs with these selected rows. We'll do this so
        // the chat re-uses the same poll logic for showing FnRun statuses.
        // We'll pass: space_id, fn_id, selectedRows
        if (!selectedSpaceId || selectedRows.length === 0) {
            return;
        }

        const currentSpace = getCurrentSpace();
        if (!currentSpace) return;

        const tableName = getCurrentTable() || "";
        const tableMd = currentSpace.table_md[tableName];

        // Determine the key column: if any column description contains "[_t_uid_]", use it.
        // Otherwise, default to "idx".
        let keyColumn = "idx";
        if (tableMd && tableMd.column_desc) {
            for (const col in tableMd.column_desc) {
                if (tableMd.column_desc[col].includes("[_t_uid_]")) {
                    keyColumn = col;
                    break;
                }
            }
        }

        // Extract only the key column value from each selected row.
        const keyRows = selectedRows.map((row) => ({ [keyColumn]: row[keyColumn] }));

        if (chatRef.current && chatRef.current.startFnRunsForRows) {
            chatRef.current.startFnRunsForRows(
                selectedSpaceId,
                tableName,
                fnId,
                removeBracketedParts(fnName),
                keyRows,
            );
        }
    };

    // Compute table names on the fly from the selected space.
    const currentSpace = getCurrentSpace();
    const tableNames = currentSpace ? Object.keys(currentSpace.table_type) : [];

    return (
        <>
            <style>{tooltipStyles}</style>
            <Box display="flex" flexDirection="column" flex={1} sx={{ height: "calc(100vh )" }}>
                <div
                    className="flex"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}>
                    <Tabs
                        value={getCurrentTable() || "add_table"}
                        onChange={handleTabNavigation}
                        sx={{ minHeight: "36px" }}>
                        {tableNames.map((table) => (
                            <Tab
                                key={table}
                                value={table}
                                label={
                                    <TableTabLabel
                                        tableName={table}
                                        isSelected={getCurrentTable() === table}
                                        isHome={
                                            currentSpace
                                                ? currentSpace.table_type[table] === "n"
                                                : false
                                        }
                                        onDownloadClick={handleDownloadCSV}
                                        onDeleteClick={handleDeleteTableClick}
                                        onShareClick={() => setOpenShareTableDialog(true)}
                                        onRenameClick={handleRenameTableClick}
                                    />
                                }
                                sx={{
                                    "&:hover": {
                                        backgroundColor:
                                            getCurrentTable() === table ? "#d0d0d0" : "#f0f0f0",
                                    },
                                    backgroundColor:
                                        getCurrentTable() === table ? "#d0d0d0" : "transparent",
                                    minHeight: "36px", // Make tabs shorter
                                    padding: "0 10px", // Reduce horizontal padding
                                }}
                            />
                        ))}
                        <Tab
                            icon={<AddCircleOutlineIcon />}
                            label="Add Table"
                            value="add_table"
                            onClick={handleOpenAddTableDialog}
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                "&:hover": {
                                    backgroundColor: "#f0f0f0",
                                },
                                minHeight: "36px", // Make tabs shorter
                                padding: "0 10px", // Reduce horizontal padding
                            }}
                        />
                    </Tabs>
                    <div style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>
                        <FormControl
                            variant="outlined"
                            size="small" // Smaller size
                            sx={{ width: "240px", marginRight: "10px" }}>
                            <InputLabel id="space-select-label">Space</InputLabel>
                            <Select
                                labelId="space-select-label"
                                id="space-select"
                                value={selectedSpaceId}
                                onChange={handleSpaceChange}
                                label="Space">
                                {spaces.map((space) => (
                                    <MenuItem key={space.space_id} value={space.space_id}>
                                        {`${space.name} (id: ${space.space_id})`}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button
                            onClick={toggleChatVisibility}
                            variant="contained"
                            color="primary"
                            size="small" // Smaller button
                        >
                            {chatVisible ? "Hide Chat" : "Show Chat"}
                        </Button>
                    </div>
                </div>

                <div
                    className="ag-theme-alpine"
                    style={{ display: "flex", width: "100%", height: "100%", overflow: "hidden" }}>
                    {/* LEFT SIDE: AG Grid */}
                    <div
                        className="ag-grid-container flex-1"
                        ref={gridRef}
                        style={{
                            overflow: "auto",
                            position: "relative",
                            height: "100%",
                            display: "flex",
                            flexDirection: "row",
                        }}>
                        <div
                            style={{
                                flex: 1,
                                overflow: "auto",
                                height: "100%",
                                transition: "width 0.3s ease",
                            }}>
                            {Object.keys(tableGridData).map((spaceId) => (
                                <div
                                    key={spaceId}
                                    style={{
                                        display: spaceId === selectedSpaceId ? "block" : "none",
                                        width: "100%",
                                        height: "100%",
                                    }}>
                                    {Object.keys(tableGridData[spaceId]).map((tableName) => (
                                        <div
                                            key={tableName}
                                            style={{
                                                display:
                                                    getCurrentTable() === tableName
                                                        ? "block"
                                                        : "none",
                                                width: "100%",
                                                height: "100%",
                                            }}>
                                            <AgGridReact
                                                rowData={getGridData(spaceId, tableName)}
                                                columnDefs={getColumnDefs(spaceId, tableName)}
                                                pagination={true}
                                                paginationPageSize={200}
                                                rowSelection="multiple"
                                                components={{}}
                                                // Ensure tooltips are enabled
                                                tooltipShowDelay={0}
                                                tooltipHideDelay={10000}
                                                onGridReady={(params) => {
                                                    if (!gridApiRefs.current[spaceId]) {
                                                        gridApiRefs.current[spaceId] = {};
                                                    }
                                                    gridApiRefs.current[spaceId][tableName] =
                                                        params.api;
                                                }}
                                                onSelectionChanged={(event) =>
                                                    handleRowSelection(event, tableName)
                                                }
                                                onColumnMoved={(event) =>
                                                    onColumnMoved(event, tableName)
                                                }
                                                onCellContextMenu={(event) =>
                                                    handleCellContextMenu(event, tableName)
                                                }
                                                onCellValueChanged={(event) =>
                                                    handleCellValueChanged(event, tableName)
                                                }
                                                onColumnResized={(event) =>
                                                    handleColumnResized(event, tableName)
                                                }
                                                onColumnHeaderContextMenu={(e) =>
                                                    handleColumnContextMenu(e, tableName)
                                                }
                                                onRowClicked={(params) => handleRowClick(params)}
                                                domLayout="normal"
                                            />
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                        {detailPanelVisible && selectedRowData && (
                            <>
                                <div
                                    className="panel-resize-divider"
                                    style={{
                                        width: "3px",
                                        backgroundColor: "#ccc",
                                        cursor: "col-resize",
                                    }}
                                    onMouseDown={handleDetailPanelResizeStart}
                                />
                                <div
                                    id="detail-panel"
                                    style={{
                                        width: `${detailPanelWidth}px`,
                                        height: "100%",
                                        overflow: "hidden",
                                        transition: "none",
                                    }}>
                                    <DetailPanel
                                        rowData={selectedRowData}
                                        entityType="person" // TODO: Make this dynamic based on table
                                        onClose={handleCloseDetailPanel}
                                        columnDescriptions={getColumnDescriptions()}
                                    />
                                </div>
                            </>
                        )}
                    </div>

                    {/* DRAGGABLE DIVIDER for chat*/}
                    {chatVisible && (
                        <div
                            onMouseDown={handleMouseDownOnChatResizer}
                            style={{
                                cursor: "col-resize",
                                width: "3px",
                                backgroundColor: "#ccc",
                            }}
                        />
                    )}

                    {/* RIGHT SIDE: Chat */}

                    <div
                        style={{
                            position: "sticky",
                            top: "0",
                            right: 0,
                            height: "100%",
                            width: chatWidth,
                            minWidth: 200,
                            background: "#f8f8f8",
                            overflow: "auto",
                            zIndex: 1,
                            boxShadow: "-2px 0px 5px rgba(0,0,0,0.2)",
                            display: chatVisible ? "block" : "none",
                        }}>
                        <SpaceChat
                            ref={chatRef}
                            selectedSpaceId={selectedSpaceId}
                            userProfile={userProfile}
                            selectedRows={selectedRows}
                            selectedTable={getCurrentTable() || ""}
                            onSpaceDataChanged={handleSpaceDataChanged}
                        />
                    </div>
                </div>
            </Box>
            <Dialog
                open={openDeleteTableDialog}
                onClose={handleCloseDeleteTableDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Delete Table"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete {getCurrentTable()}? This operation is not
                        possible to reverse.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteTableDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="error" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openAddTableDialog}
                onClose={handleCloseAddTableDialog}
                aria-labelledby="add-table-dialog-title"
                aria-describedby="add-table-dialog-description">
                <DialogTitle id="add-table-dialog-title">{"Add Table from CSV"}</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth variant="outlined" style={{ marginBottom: "20px" }}>
                        <InputLabel id="file-select-label">Select CSV File</InputLabel>
                        <Select
                            labelId="file-select-label"
                            id="file-select"
                            value={selectedFile}
                            onChange={handleFileChange}
                            label="Select CSV File">
                            {files.map((file, index) => (
                                <MenuItem key={index} value={file}>
                                    {file}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Table Name"
                        value={dialogTableName}
                        onChange={handleTableNameChangeInDialog}
                        style={{ marginBottom: "20px" }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddTableDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handlePopulateData} color="primary" autoFocus>
                        Add Table
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openShareTableDialog}
                onClose={() => setOpenShareTableDialog(false)}
                aria-labelledby="share-table-dialog-title"
                aria-describedby="share-table-dialog-description">
                <DialogTitle id="share-table-dialog-title">{"Share Table"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="share-table-dialog-description">
                        Any table can be mutated only in its home Space. When sharing a table the
                        other Space will have read-only access to it.
                    </DialogContentText>
                    <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                        <InputLabel id="consumer-space-select-label">
                            Select Consumer Space
                        </InputLabel>
                        <Select
                            labelId="consumer-space-select-label"
                            value={selectedConsumerSpace}
                            label="Select Consumer Space"
                            onChange={(e) => setSelectedConsumerSpace(e.target.value)}>
                            {spaces
                                .filter((space) => space.space_id !== selectedSpaceId)
                                .map((space) => (
                                    <MenuItem key={space.space_id} value={space.space_id}>
                                        {`${space.name} (id: ${space.space_id})`}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenShareTableDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={async () => {
                            try {
                                await fetch_with_auth(
                                    "space_share_table",
                                    userProfile.id_token || "",
                                    "POST",
                                    {
                                        source_space_id: selectedSpaceId,
                                        table_name: getCurrentTable(),
                                        consumer_space_id: selectedConsumerSpace,
                                    },
                                );
                                toast.success("Table shared successfully.");
                                setOpenShareTableDialog(false);
                            } catch (error: any) {
                                toast.error(`Error sharing table: ${error.message}`);
                            }
                        }}
                        color="success"
                        variant="contained">
                        Share
                    </Button>
                </DialogActions>
            </Dialog>{" "}
            <Dialog
                open={openRenameTableDialog}
                onClose={() => setOpenRenameTableDialog(false)}
                aria-labelledby="rename-table-dialog-title"
                aria-describedby="rename-table-dialog-description">
                <DialogTitle id="rename-table-dialog-title">Rename Table</DialogTitle>
                <DialogContent>
                    <DialogContentText id="rename-table-dialog-description">
                        Enter a new name for the table:
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Table Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={newTableName}
                        onChange={(e) => setNewTableName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenRenameTableDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={async () => {
                            if (
                                newTableName &&
                                newTableName !== getCurrentTable() &&
                                selectedSpaceId
                            ) {
                                try {
                                    await fetch_with_auth(
                                        "space_rename_table",
                                        userProfile.id_token || "",
                                        "POST",
                                        {
                                            space_id: selectedSpaceId,
                                            old_table_name: getCurrentTable(),
                                            new_table_name: newTableName,
                                        },
                                    );
                                    toast.success("Table renamed successfully.");
                                    setCurrentTable(newTableName);
                                    ensureCurrentSpaceFresh();
                                    setOpenRenameTableDialog(false);
                                } catch (error: any) {
                                    toast.error(`Error renaming table: ${error.message}`);
                                }
                            }
                        }}
                        color="success"
                        variant="contained"
                        disabled={
                            newTableName.trim() === "" ||
                            newTableName === getCurrentTable() ||
                            // Make shure new table name is not present in current space.
                            Object.keys(
                                spaces.find((space) => space.space_id === selectedSpaceId)
                                    ?.table_type || {},
                            ).includes(newTableName.trim())
                        }>
                        Rename
                    </Button>
                </DialogActions>
            </Dialog>
            {/* Column Rename Dialog */}
            <Dialog
                open={openRenameColumnDialog}
                onClose={() => setOpenRenameColumnDialog(false)}
                aria-labelledby="rename-column-dialog-title"
                aria-describedby="rename-column-dialog-description">
                <DialogTitle id="rename-column-dialog-title">Rename Column</DialogTitle>
                <DialogContent>
                    <DialogContentText id="rename-column-dialog-description">
                        Enter a new name for the column:
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Column Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={newColumnName}
                        onChange={(e) => setNewColumnName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenRenameColumnDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleColumnRenameButtonClicked}
                        color="success"
                        variant="contained"
                        disabled={
                            newColumnName.trim() === "" ||
                            newColumnName === selectedColumn ||
                            // Make sure column name doesn't already exist in current table
                            getColumnDefs(selectedSpaceId, getCurrentTable() || "").some(
                                (col) => col.field === newColumnName.trim(),
                            )
                        }>
                        Rename
                    </Button>
                </DialogActions>
            </Dialog>
            <div
                id="contextMenu"
                style={{
                    display: "none",
                    position: "absolute",
                    backgroundColor: "white",
                    border: "2px solid black",
                    zIndex: 1000,
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}>
                <ul style={{ listStyleType: "none", margin: 0, padding: "10px" }}>
                    <li className="contextMenuItem" onClick={handleAddRow}>
                        Add new row after this one
                    </li>
                    {false && (
                        <li className="contextMenuItem" onClick={handleAddColumn}>
                            Add new column after this one
                        </li>
                    )}
                    <li className="contextMenuItem" onClick={handleDeleteRow}>
                        Delete this row
                    </li>
                    {selectedRows.length > 0 && currentSpace && (
                        <>
                            <hr style={{ margin: "8px 0" }} />
                            <li
                                style={{
                                    fontWeight: "bold",
                                    cursor: "default",
                                    padding: "4px 0",
                                    userSelect: "none",
                                }}>
                                Apply Function to {selectedRows.length} selected rows
                            </li>{" "}
                            {Object.entries(currentSpace.functions || {})
                                // Filter only function names with "[rows]"
                                .filter(([fnName, fnId]) => fnName.includes("[rows]"))
                                .map(([fnName, fnId]) => {
                                    const fnDetails =
                                        spaceFunctions[selectedSpaceId]?.[fnId as string];
                                    // Remove bracketed parts, e.g. "[rows]" => ""
                                    const displayName = fnName.replace(/\[.*?\]/g, "").trim();
                                    return (
                                        <li
                                            key={fnId as string}
                                            className="contextMenuItem"
                                            onClick={() => {
                                                const menuEl =
                                                    document.getElementById("contextMenu");
                                                if (menuEl) {
                                                    (menuEl as HTMLDivElement).style.display =
                                                        "none";
                                                }
                                                handleApplyFnToSelectedRows(
                                                    fnId as string,
                                                    displayName,
                                                );
                                            }}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                            }}>
                                            <span>{displayName}</span>
                                            {fnDetails?.credit_cost_estimate && (
                                                <Tooltip
                                                    title={`Typical credit cost is ${fnDetails.credit_cost_estimate}`}>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                        }}>
                                                        <SavingsIcon fontSize="small" />
                                                        <span style={{ marginLeft: "4px" }}>
                                                            {fnDetails.credit_cost_estimate}
                                                        </span>
                                                    </div>
                                                </Tooltip>
                                            )}
                                        </li>
                                    );
                                })}
                        </>
                    )}
                </ul>
            </div>
            {/* Column Context Menu */}
            <div
                id="columnContextMenu"
                style={{
                    display: "none",
                    position: "absolute",
                    backgroundColor: "white",
                    border: "2px solid black",
                    zIndex: 1000,
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}>
                <ul style={{ listStyleType: "none", margin: 0, padding: "10px" }}>
                    <li
                        className="contextMenuItem"
                        onClick={handleRenameColumnCtxMenuOptionSelected}>
                        Rename Column
                    </li>
                </ul>
            </div>
        </>
    );
};

export default SpacePage;
