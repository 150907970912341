import React from "react";

import {
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Card,
    CardContent,
    CardHeader,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import CheckIcon from "@mui/icons-material/Check";

interface UsecaseSelectionProps {
    selectedUsecase: string;
    setSelectedUsecase: (usecase: string) => void;
    onNext: () => void;
    onBack: () => void;
}

const UsecaseSelection: React.FC<UsecaseSelectionProps> = ({
    selectedUsecase,
    setSelectedUsecase,
    onNext,
    onBack,
}) => {
    const handleSelect = () => {
        setSelectedUsecase("healthcare_leads");
        // onNext();  Leaving onNext here would immediately go to the next step.
    };

    return (
        <>
            <DialogContent>
                <DialogContentText>
                    Select a use case that best matches your needs.
                </DialogContentText>
                <div style={{ marginTop: 20 }}>
                    <Card
                        onClick={handleSelect}
                        sx={{
                            cursor: "pointer",
                            border:
                                selectedUsecase === "healthcare_leads"
                                    ? "2px solid green"
                                    : "1px solid #ddd",
                            transition: "all 0.2s",
                            "&:hover": {
                                boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                            },
                        }}>
                        <CardHeader
                            avatar={<MedicalServicesIcon color="primary" fontSize="large" />}
                            title="Healthcare Leads"
                            action={
                                selectedUsecase === "healthcare_leads" && (
                                    <CheckIcon color="success" />
                                )
                            }
                        />
                        <CardContent>
                            <List dense>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckIcon fontSize="small" color="success" />
                                    </ListItemIcon>
                                    <ListItemText primary="1.2M US providers" />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckIcon fontSize="small" color="success" />
                                    </ListItemIcon>
                                    <ListItemText primary="Medicare billing data" />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckIcon fontSize="small" color="success" />
                                    </ListItemIcon>
                                    <ListItemText primary="Can augment with email and other contact info" />
                                </ListItem>
                            </List>
                        </CardContent>
                    </Card>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onBack}>Back</Button>
                <Button
                    onClick={onNext}
                    variant="contained"
                    color="primary"
                    disabled={!selectedUsecase}>
                    Next
                </Button>
            </DialogActions>
        </>
    );
};

export default UsecaseSelection;
