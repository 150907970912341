import React, { useEffect, useRef } from "react";

import { Box } from "@mui/material";
import { RaceBy } from "@uiball/loaders";

import Action from "./Action";
import { FnRun } from "../types/FnRun";

interface ActionFeedProps {
    fnRun: FnRun | null;
    isRunning?: boolean;
}

const ActionFeed: React.FC<ActionFeedProps> = ({ fnRun, isRunning }) => {
    const actionsEndRef = useRef<HTMLDivElement>(null);
    const isFirstRender = useRef(true);
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const scrollToBottom = () => {
        if (scrollContainerRef.current && actionsEndRef.current) {
            scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        if (!isFirstRender.current) {
            scrollToBottom();
        } else {
            isFirstRender.current = false;
        }
    }, [fnRun]);

    return (
        <Box
            className="min-h-full min-w-full md:max-h-[5vh]"
            sx={{
                display: "flex",
                flexDirection: "column",
                borderRadius: 1,
                border: "1px solid",
                padding: "12px 16px",
                textAlign: "top",
                maxHeight: "300px", // Set maxHeight here
                overflowY: "auto", // Set overflowY here
            }}
            ref={scrollContainerRef}>
            <div style={{ flexGrow: 1 }}>
                <div className="flex max-h-full w-full flex-col">
                    {(fnRun ? fnRun.log || [] : []).map((action, index) => (
                        <Action key={index} content={action} />
                    ))}
                    <div ref={actionsEndRef}></div>
                </div>
            </div>
            {isRunning && (
                <div
                    className="p-3"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}>
                    <RaceBy size={80} lineWeight={5} speed={1.4} color="green" />
                </div>
            )}
        </Box>
    );
};

export default ActionFeed;
