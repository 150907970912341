import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";

import { getAuth, onAuthStateChanged } from "firebase/auth";

import app from "../auth/firebase";
import { UserProfile } from "../types/UserProfile";
import { Team } from "../types/Team";
import { fetch_with_auth } from "../components/Util";

interface UserProfileContextType {
    userProfile: UserProfile;
    setUserProfile: React.Dispatch<React.SetStateAction<UserProfile>>;
    loading: boolean;
}

const UserProfileContext = createContext<UserProfileContextType | undefined>(undefined);

export const useUserProfile = () => {
    const context = useContext(UserProfileContext);
    if (!context) {
        throw new Error("useUserProfile must be used within a UserProfileProvider");
    }
    return context;
};

interface UserProfileProviderProps {
    children: ReactNode;
}

export const UserProfileProvider: React.FC<UserProfileProviderProps> = ({ children }) => {
    const [userProfile, setUserProfile] = useState<UserProfile>(new UserProfile({ user_id: "" }));
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const auth = getAuth(app);
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            setLoading(true);
            try {
                await userProfile.init(setUserProfile, currentUser);

                if (currentUser) {
                    // Fetch additional user profile data including team information
                    const idToken = await currentUser.getIdToken();
                    const profileData = await fetch_with_auth(`user_profile`, idToken, "GET");

                    if (profileData && profileData.team) {
                        const team = new Team(profileData.team);
                        setUserProfile(
                            (prev) =>
                                new UserProfile({
                                    ...prev,
                                    team: team,
                                }),
                        );
                    }
                }
            } catch (error) {
                console.error("Error initializing user profile:", error);
            } finally {
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, []);

    const value = {
        userProfile,
        setUserProfile,
        loading,
    };

    return <UserProfileContext.Provider value={value}>{children}</UserProfileContext.Provider>;
};
